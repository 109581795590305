import {
  alpha,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Rating,
  Select,
  Skeleton,
  Stack,
  styled,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import {
  IMG_FORMS_ANALYTICS_ICON,
  IMG_RESPONSE_ANALYTICS_ICON,
  IMG_RESPONSE_TIME_ANALYTICS_ICON
} from '../../utils/imageUrls';
import { Chart } from 'react-google-charts';
import {
  colorLinkGrey,
  colorMidGray,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import { useEffect, useState } from 'react';
import {
  Calendar01Icon,
  ChevronRightIcon,
  Copy05Icon,
  Expand05Icon,
  FileDownload03Icon,
  FilterLinesIcon,
  ShareThreeDotsIcon,
  XClose
} from '../../utils/iconSvg';
import {
  getFlowsAndFormsAnalytics,
  getFlowsAndFormsAnalyticsDetail,
  revertFlowsAndFormsAnalytics,
  revertUserAndRoleAnalytics
} from '../../redux/reducers/analyticsSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  convertBarChartData,
  copyToClipboard,
  createTitle,
  isArrayNotEmpty
} from '../../utils/utils';
import { DragIndicator, East, HighlightOff } from '@mui/icons-material';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  getFlows,
  getFormsListByFlowId,
  updateFormSequence
} from '../../redux/reducers/flowsSlice';
import {
  Legend,
  prepareChartOptionsAndData,
  TileCountComponent
} from './UserAndRoleAnalytics';
import CustomDataTable from '../../components/CustomDataTable';
import moment from 'moment';
import { analyticsTypeHeaderContent } from '../../pages/ProductAnalysis';
import CustomPagination from '../../components/CustomTablePagination';
import _ from 'lodash';
import SnackBarBox from '../../components/SnackBarBox';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center'
  },
  '& .MuiMenuItem-root': {
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1.5)
    },
    '&:active': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity
      )
    }
  }
}));

export const ListView = ({
  name = '',
  flowId = '',
  formId = '',
  responseCount = '',
  onClick
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        height: '40px',
        cursor: 'pointer',
        padding: '4px 12px',
        width: '100%',
        display: 'flex',
        border: '1px solid #E4E7EB',
        borderRadius: '4px',
        alignItems: 'center',
        marginTop: '5px',
        justifyContent: 'space-between',
        wordBreak: 'break-all', // Breaks long words and strings
        whiteSpace: 'normal',
        backgroundColor: isHovered ? colorSecondary : 'transparent'
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between'
        }}
        onClick={() => onClick({ flowId: flowId, formId: formId })}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <DragIndicator
            sx={{
              color: '#E4E7EB',
              marginRight: 1
            }}
          />

          {/* <a
            style={{ cursor: 'pointer' }}
            onClick={() => onClick({ flowId: flowId, formId: formId })}
          > */}
          <Typography fontWeight={'600'} color="#1F2933">
            {name}
          </Typography>
        </div>
        <Typography fontWeight={'600'} color={'#4E565F'}>
          {responseCount} Responses
        </Typography>
      </div>
      {/* </a> */}
    </div>
  );
};

const ChartViewComponent = ({
  sr,
  questionId = null,
  questionText = '',
  responseCount = '',
  chartType = null,
  rating = 0,
  chartData = null
}) => {
  return (
    <Grid
      item
      width={'100%'}
      padding={'8px 8px'}
      mb={2}
      component={Paper}
      elevation={0}
      borderRadius={'8px'}
    >
      <Grid container display={'flex'} justifyContent={'space-between'}>
        <Typography fontWeight={'700'} fontSize="20px">
          <span
            style={{
              padding: '0.5rem 0.5rem 0.5rem 0.0rem',
              display: 'inline-flex',
              alignItems: 'center'
            }}
          >
            {sr + 1}{' '}
            <East
              fontSize="small"
              sx={{
                marginLeft: '4px'
              }}
            />
          </span>{' '}
          {questionText || ''}
        </Typography>

        {rating !== 0 && (
          <Box>
            <Typography variant="subtitle2" color={colorMidGray}>
              Average rating
            </Typography>{' '}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Typography
                sx={{ flex: 1, textAlign: 'center' }}
                fontSize="16px"
                fontWeight={'700'}
              >
                {rating ? parseFloat(rating).toFixed(1) : ''}
              </Typography>
              <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
              <Box sx={{ flex: 2 }}>
                <Rating size="small" precision={0.5} readOnly value={rating} />
              </Box>
            </Box>
          </Box>
        )}
      </Grid>
      <Divider sx={{ marginY: 1 }} />
      {responseCount !== 0 && (
        <Typography fontSize={'16px'} fontWeight={'700'}>
          {responseCount} Responses
        </Typography>
      )}
      <Grid mt={2} width={'100%'}>
        {chartType == 1 && chartData?.analyticsData && (
          <Chart
            chartType="PieChart"
            data={chartData?.analyticsData}
            options={{
              slices: {
                0: { color: '#2F68C4' },
                1: { color: colorPrimary },
                2: { color: '#749E35' }
              }
            }}
          />
        )}
        {(chartType == 2 || chartType == 3) && chartData?.analyticsData && (
          <Chart
            chartType="BarChart"
            data={convertBarChartData(chartData?.analyticsData)}
            options={{
              hAxis: {
                minValue: 0
              },
              legend: { position: 'none' },
              annotations: {
                alwaysOutside: true,
                textStyle: {
                  fontSize: 12,
                  auraColor: 'none',
                  color: '#555'
                }
              }
            }}
          />
        )}
        {!_.includes([1, 2, 3], chartType) && (
          <>
            <Box
              sx={{
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 470px)',
                '&::-webkit-scrollbar': {
                  width: '0.2em'
                },
                '&::-webkit-scrollbar-track': {
                  background: '#E4E7EB'
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'rgba(0, 0, 0, 0.3)'
                },
                paddingX: 1
              }}
            >
              {isArrayNotEmpty(chartData) &&
                chartData.map((cc, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        padding: '4px 12px',
                        width: '100%',
                        display: 'flex',
                        border: '1px solid #E4E7EB',
                        borderRadius: '4px',
                        alignItems: 'center',
                        marginTop: idx != 0 ? '5px' : 0,
                        justifyContent: 'space-between',
                        wordBreak: 'break-all',
                        whiteSpace: 'normal',
                        backgroundColor: '#F5F7FA'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          justifyContent: 'space-between'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Typography fontWeight={'600'} color="#1F2933">
                            {cc}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const styledMenuItem = {
  border: '1px solid #E4E7EB',
  background: 'inherit',
  marginX: 1,
  borderRadius: 3,
  fontWeight: 700,
  marginY: 1,
  justifyContent: 'center'
};

const FlowsAndFormAnalytics = () => {
  const dispatch = useDispatch();
  const [char1, setChart1] = useState(null);
  const [tempDateFilter, setTempDateFilter] = useState({
    dateType: 'Month',
    startDate: null,
    endDate: null
  });
  const [anchorEl, setAnchorEl] = useState({ anchor: null, id: null });
  const [chart, setChart] = useState('');
  const [open, setOpen] = useState(null);
  const [formListPayload, setFormListPayload] = useState({
    tab: 0,
    size: 10,
    page: 0
  });
  const [copySuccess, setCopySuccess] = useState(false);
  const [localFormList, setLocalFormList] = useState([]);
  const [showFormList, setShowFormList] = useState(null);
  const [showFlowList, setShowFlowList] = useState(false);
  const [chart1Payload, setChart1Payload] = useState({
    dateType: 'all',
    startDate: null,
    endDate: null
  });

  const [formResponsesPayload, setFormResponsePayload] = useState(null);
  const [openCalenderModal, setOpenCalendarModal] = useState({
    startDate: false,
    endDate: false
  });
  const { authData } = useSelector(state => state.auth);
  const {
    isFlowsAndFormsAnalyticsLoading,
    flowsAndFormsAnalyticsData,
    flowsAndFormsAnalyticsDetailData
  } = useSelector(state => state.analytics);

  const { isFlowsLoading, flowsData, formList, isFormSequenceUpdateSuccess } =
    useSelector(state => state.flow);

  useEffect(() => {
    if (formList?.list) {
      setLocalFormList(formList.list);
    }
  }, [formList]);

  useEffect(() => {
    createTitle('Flows & Forms Analytics');

    dispatch(
      getFlows({
        type: 'all',
        page: 0,
        size: 0,
        search: ''
      })
    );
    return () => {
      dispatch(revertFlowsAndFormsAnalytics());
    };
  }, []);

  useEffect(() => {
    let payload = {
      dateType: chart1Payload.dateType == 'all' ? null : chart1Payload.dateType,
      startDate: chart1Payload.startDate
        ? dayjs(chart1Payload.startDate).format('YYYY-MM-DD')
        : null,
      endDate: chart1Payload.endDate
        ? dayjs(chart1Payload.endDate).format('YYYY-MM-DD')
        : null
    };
    dispatch(getFlowsAndFormsAnalytics(payload));
  }, [chart1Payload]);

  useEffect(() => {
    if (showFormList && showFormList?.flowId) {
      dispatch(
        getFormsListByFlowId({
          flowId: showFormList?.flowId,
          payload: {
            page: formListPayload.page,
            size: formListPayload.size,
            type: 'all'
          }
        })
      );
    }

    return () => {};
  }, [
    formListPayload.page,
    formListPayload.size,
    showFormList,
    isFormSequenceUpdateSuccess
  ]);

  const handleClickOpen = id => {
    setChart(id);
  };

  const handleClose = () => {
    setChart('');
  };

  const handleOpenCalendar = type => {
    setOpenCalendarModal({ ...openCalenderModal, [type]: true });
  };

  const handleCloseCalendar = type => {
    setOpenCalendarModal({ ...openCalenderModal, [type]: false });
  };

  const handleShareClick = (event, id) => {
    setAnchorEl({ anchor: event.currentTarget, id: id });
  };

  const handleShareClose = () => {
    setAnchorEl({ anchor: null, id: null });
    setTimeout(() => {
      setCopySuccess(false);
    }, 4000);
  };

  const handleCopyLink = id => {
    let ids = {
      1: 'flowsAndFormsChart'
    };
    let chart1dateTime = `&startDate=${
      chart1Payload.startDate
        ? dayjs(chart1Payload.startDate).format('YYYY-MM-DD')
        : null
    }&endDate=${
      chart1Payload.endDate
        ? dayjs(chart1Payload.endDate).format('YYYY-MM-DD')
        : null
    }`;
    let chart1ExtraLink = `&dateType=${chart1Payload.dateType}${chart1Payload.dateType === 'selected_date' ? chart1dateTime : ''}`;

    let shareLink = `${process.env.REACT_APP_PORTAL_URL}share-charts?chartType=${ids[id]}${chart1ExtraLink}`;

    navigator.clipboard.writeText(shareLink);
    copyToClipboard(shareLink);
    setCopySuccess(true);
    handleShareClose();
  };

  const columns = [
    {
      uinqueKey: 'flowId',
      id: 'flowName',
      label: 'Flow Name',
      sortable: true,
      minWidth: '100px',
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.flowName}
          </Typography>
        );
      }
    },
    {
      id: 'createdBy',
      label: 'Created By'
    },

    {
      id: 'createdDate',
      label: 'Created On',
      minWidth: '150px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'}>
                {row.createdDate
                  ? moment(row.createdDate).format('DD/MM/YYYY')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'responses',
      label: 'Responses'
    },
    {
      id: 'status',
      label: 'Status',
      width: '150px',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              fontSize: 12,
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '4px 8px 4px 8px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: row.status ? '#749E35' : '#D62828',
              width: row.status ? '62px' : '70px',
              height: '22px'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    }
  ];

  const options = {
    hAxis: {
      title: 'Date',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      }
    },
    vAxis: {
      title: 'Count',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      },
      viewWindow: {
        min: 0
      },
      ticks: prepareChartOptionsAndData(
        flowsAndFormsAnalyticsData.analyticsData
      ),
      gridlines: {
        count: 10,
        color: '#F5F7FA',
        units: {
          ones: { count: 0 }
        }
      }
    },
    curveType: 'function',
    series: {
      0: { color: colorPrimary, curveType: 'function' },
      1: { color: '#2F68C4', curveType: 'function' },
      2: { color: '#749E35', curveType: 'function' }
    },
    legend: { position: 'none' }
  };

  const handleDownload = ({ id = null }) => {
    if (id) {
      const chartWrapper = id === 1 && char1;
      const imageURI = chartWrapper.getChart().getImageURI();
      const link = document.createElement('a');
      link.href = imageURI;
      link.download = 'chart.png';
      link.click();
    }
  };

  const handleFormClick = cc => {
    // console.log(cc, 'cc');
    setFormResponsePayload(cc);
    dispatch(
      getFlowsAndFormsAnalyticsDetail({
        formId: cc?.formId,
        flowId: cc?.flowId
      })
    );
  };

  const handleOnDragEnd = result => {
    if (!result.destination) return; // If dropped outside the list, do nothing

    // const reorderedItems = Array.from(localFormList);
    // const [movedItem] = reorderedItems.splice(result.source.index, 1);
    // reorderedItems.splice(result.destination.index, 0, movedItem);

    // // Update the local form list state after reordering
    // setLocalFormList(reorderedItems);
    const reorderedItems = Array.from(localFormList);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    const pageSize = formListPayload.size; // Assuming each page shows 10 items
    const currentPage = formListPayload.page; // Starts from 0
    const sequenceOffset = currentPage * pageSize; // Calculate the sequence offset
    // console.log(sequenceOffset, 'sequenceOffset');
    // Update formSequence based on the new order with the offset
    const updatedItems = reorderedItems.map((item, index) => ({
      ...item,
      formSequence: sequenceOffset + index + 1 // Add offset to the index
    }));

    // console.log(updatedItems, 'after update');
    setLocalFormList(updatedItems);

    let payload = {
      flowId: showFormList?.flowId,
      loggedInUserName: authData?.userName,
      sequenceDataRequestList: [...updatedItems].map(cc => {
        return { formId: cc.formId, formSequence: cc.formSequence };
      })
    };
    // console.log(payload, 'payload');
    dispatch(updateFormSequence(payload));
  };
  return (
    <>
      {!showFlowList && (
        <>
          <Typography mb={2} fontSize={'32px'} fontWeight={'700'}>
            {analyticsTypeHeaderContent['flows']}
          </Typography>

          <Grid
            container
            component={Paper}
            elevation={0}
            sx={{
              borderRadius: '8px',
              padding: 2,
              display: 'flex',
              width: '100%'
            }}
          >
            <TileCountComponent
              icon={IMG_FORMS_ANALYTICS_ICON}
              count={flowsAndFormsAnalyticsData.totalForms || 0}
              description={'Total Forms Created'}
            />
            <TileCountComponent
              icon={IMG_RESPONSE_ANALYTICS_ICON}
              count={flowsAndFormsAnalyticsData.totalResponse || 0}
              description={'Total Collected Responses'}
            />
            <TileCountComponent
              icon={IMG_RESPONSE_TIME_ANALYTICS_ICON}
              count={`${flowsAndFormsAnalyticsData.totalAverageResponseTime?.toFixed(2) || 0} hr`}
              description={'Average Response Time'}
            />
          </Grid>
          <Grid
            mt={2}
            container
            display={'flex'}
            width={'100%'}
            flexDirection={'column'}
            sx={{ borderRadius: '8px', padding: 2, bgcolor: 'white' }}
          >
            <Grid
              item
              xs={12}
              display={'flex'}
              justifyContent={'space-between'}
              mb={1}
              width={'100%'}
            >
              <FormControl
                variant="outlined"
                sx={{ width: '296px' }}
                size="small"
              >
                <StyledSelect
                  IconComponent={Calendar01Icon}
                  size="small"
                  open={open == 1}
                  value={
                    open == 1 ? tempDateFilter.dateType : chart1Payload.dateType
                  }
                  onOpen={(e, va) => {
                    setOpen(1);
                    setTempDateFilter({
                      dateType: chart1Payload.dateType,
                      startDate: chart1Payload.startDate,
                      endDate: chart1Payload.endDate
                    });
                  }}
                  onChange={e => {
                    setTempDateFilter({
                      ...tempDateFilter,
                      dateType: e.target.value,
                      startDate: null,
                      endDate: null
                    });
                  }}
                  renderValue={value => {
                    let data = open === 1 ? tempDateFilter : chart1Payload;
                    if (data.dateType === 'all') {
                      return (
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#ABB4BD'
                          }}
                        >
                          <SvgIcon fontSize="small" sx={{ marginRight: 1 }}>
                            <FilterLinesIcon color="#ABB4BD" />
                          </SvgIcon>
                          Select date
                        </Typography>
                      );
                    }
                    switch (data.dateType) {
                      case 'selected_date':
                        return `${data.startDate ? dayjs(data.startDate).format('DD/MM/YY') : ''} - ${data.endDate ? dayjs(data.endDate).format('DD/MM/YY') : ''}`;
                      case 'Week':
                        return 'Last Week';
                      case 'Month':
                        return 'This Month';
                      case 'past_3_months':
                        return 'Past 3 months';
                      case 'Past_1_Year':
                        return 'Past 1 year';
                      default:
                        return 'Select date';
                    }
                  }}
                  // endAdornment={
                  //   chart1Payload.dateType ? (
                  //     <IconButton
                  //       sx={{ marginRight: '10px' }}
                  //       aria-label="clear"
                  //       size="small"
                  //       onClick={() => {
                  //         setChart1Payload({
                  //           ...chart1Payload,
                  //           dateType: 'Month',
                  //           startDate: null,
                  //           endDate: null
                  //         });
                  //         setTempDateFilter({
                  //           startDate: null,
                  //           dateType: 'Month',
                  //           endDate: null
                  //         });
                  //       }}
                  //       edge="end"
                  //     >
                  //       <HighlightOff sx={{ color: '#ABB4BD' }} />
                  //     </IconButton>
                  //   ) : null
                  // }
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '&:hover  .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8
                    }
                  }}
                >
                  <MenuItem
                    sx={{ ...styledMenuItem, paddingX: 0, border: 'none' }}
                    value="selected_date"
                    disableRipple
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box display={'flex'} margin={'0px 6px'}>
                        <FormControl
                          variant="outlined"
                          // fullWidth
                          sx={{ width: '185px' }}
                          size="small"
                        >
                          <Typography
                            sx={{ fontSize: '14px', fontWeight: 600, mb: 0.5 }}
                          >
                            Start Date
                          </Typography>
                          <DatePicker
                            // name="Start-Date"
                            sx={{ marginRight: 1 }}
                            value={dayjs(tempDateFilter.startDate)}
                            onOpen={() => handleOpenCalendar('startDate')}
                            onClose={() => handleCloseCalendar('startDate')}
                            open={openCalenderModal.startDate}
                            format="DD/MM/YYYY"
                            slotProps={{
                              textField: {
                                size: 'small',
                                error: false,
                                InputProps: {
                                  sx: { color: colorTextBlack },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          handleOpenCalendar('startDate')
                                        }
                                      >
                                        <Calendar01Icon />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }
                              }
                            }}
                            onChange={date =>
                              setTempDateFilter({
                                ...tempDateFilter,
                                dateType: 'selected_date',
                                startDate: date,
                                endDate: date
                              })
                            }
                            components={{ OpenPickerIcon: () => null }}
                            // components={{
                            //   OpenPickerIcon: props => (
                            //     <IconButton size="small" {...props}>
                            //       <Calendar01Icon />
                            //     </IconButton>
                            //   )
                            // }}
                          />
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          sx={{ width: '185px' }}
                          // fullWidth
                          size="small"
                        >
                          <Typography
                            sx={{ fontSize: '14px', fontWeight: 600, mb: 0.5 }}
                          >
                            End Date
                          </Typography>
                          <DatePicker
                            minDate={dayjs(tempDateFilter.startDate)}
                            // label="End Date"
                            value={dayjs(tempDateFilter.endDate)}
                            open={openCalenderModal.endDate}
                            onOpen={() => handleOpenCalendar('endDate')}
                            onClose={() => handleCloseCalendar('endDate')}
                            format="DD/MM/YYYY"
                            slotProps={{
                              textField: {
                                size: 'small',
                                error: false,
                                InputProps: {
                                  sx: { color: colorTextBlack },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          handleOpenCalendar('endDate')
                                        }
                                      >
                                        <Calendar01Icon />
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }
                              }
                            }}
                            onChange={date => {
                              setTempDateFilter({
                                ...tempDateFilter,
                                dateType: 'selected_date',
                                endDate: date
                              });
                            }}
                            components={{ OpenPickerIcon: () => null }}
                            // components={{
                            //   OpenPickerIcon: props => (
                            //     <IconButton size="small" {...props}>
                            //       <Calendar01Icon />
                            //     </IconButton>
                            //   )
                            // }}
                          />
                        </FormControl>
                      </Box>
                    </LocalizationProvider>
                  </MenuItem>

                  <MenuItem sx={styledMenuItem} value="Week" disableRipple>
                    Last Week
                  </MenuItem>
                  <MenuItem sx={styledMenuItem} value="Month" disableRipple>
                    Current Month
                  </MenuItem>
                  <MenuItem
                    sx={styledMenuItem}
                    value="past_3_months"
                    disableRipple
                  >
                    Past 3 month
                  </MenuItem>
                  <MenuItem
                    sx={styledMenuItem}
                    value="Past_1_Year"
                    disableRipple
                  >
                    Past 1 year
                  </MenuItem>
                  <MenuItem
                    sx={{
                      border: '1px solid #E4E7EB',
                      backgroundColor: colorPrimary,
                      marginX: 1,
                      borderRadius: 3,
                      fontWeight: 700,
                      justifyContent: 'center',
                      marginY: 1,
                      '&:hover': {
                        background: colorPrimary
                      }
                    }}
                    onClick={() => {
                      if (tempDateFilter.dateType) {
                        setChart1Payload({
                          ...chart1Payload,
                          dateType: tempDateFilter.dateType,
                          startDate: tempDateFilter.startDate,
                          endDate: tempDateFilter.endDate
                        });
                      }
                      setTempDateFilter({
                        dateType: null,
                        endDate: null,
                        startDate: null
                      });
                      setOpen(null);
                    }}
                  >
                    Apply
                  </MenuItem>
                  <MenuItem
                    // disabled={
                    //   !(tempDateFilter.dateType || chart1Payload.dateFilter)
                    // }
                    sx={{
                      border: '1px solid #E4E7EB',
                      backgroundColor: colorPrimary,
                      marginX: 1,
                      borderRadius: 3,
                      fontWeight: 700,
                      justifyContent: 'center',
                      marginY: 1,
                      '&:hover': {
                        background: colorPrimary
                      }
                    }}
                    onClick={() => {
                      setChart1Payload({
                        ...chart1Payload,
                        dateType: 'all',
                        startDate: null,
                        endDate: null
                      });
                      setTempDateFilter({
                        startDate: null,
                        dateType: null,
                        endDate: null
                      });
                      setOpen(null);
                    }}
                  >
                    Close
                  </MenuItem>
                </StyledSelect>
              </FormControl>
              <Grid item sx={{ display: 'flex', alignSelf: 'end' }}>
                <Tooltip title="Resize">
                  <IconButton
                    onClick={() => handleClickOpen(1)}
                    sx={{ mx: 0.5 }}
                  >
                    <SvgIcon sx={{ height: 20, width: 20 }}>
                      <Expand05Icon />
                    </SvgIcon>
                  </IconButton>{' '}
                </Tooltip>
                <Tooltip title="Download image">
                  <IconButton
                    onClick={() => {
                      handleDownload({ id: 1 });
                    }}
                    sx={{ mr: 0.5 }}
                  >
                    <SvgIcon sx={{ height: 20, width: 20 }}>
                      <FileDownload03Icon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Share">
                  <IconButton onClick={e => handleShareClick(e, 1)}>
                    <SvgIcon sx={{ height: 20, width: 20 }}>
                      <ShareThreeDotsIcon />
                    </SvgIcon>
                  </IconButton>{' '}
                </Tooltip>

                <Menu
                  anchorEl={anchorEl.anchor}
                  open={Boolean(anchorEl.id == 1)}
                  onClose={handleShareClose}
                  onClick={handleShareClose}
                >
                  <MenuItem onClick={() => handleCopyLink(1)}>
                    <SvgIcon fontSize="small" sx={{ marginRight: 2 }}>
                      <Copy05Icon />
                    </SvgIcon>{' '}
                    Copy link
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
            <Legend
              series={
                flowsAndFormsAnalyticsData.analyticsData.length > 0
                  ? flowsAndFormsAnalyticsData.analyticsData[0]
                      .slice(1)
                      .map((name, index) => ({
                        name,
                        color: options.series[index].color
                      }))
                  : []
              }
            />
            <Grid item xs={12}>
              {isFlowsAndFormsAnalyticsLoading && (
                <Skeleton height={300} width={'100%'} />
              )}
              {!isFlowsAndFormsAnalyticsLoading && (
                <Chart
                  height={'300px'}
                  width={'100%'}
                  chartType="LineChart"
                  data={flowsAndFormsAnalyticsData.analyticsData}
                  options={options}
                  getChartWrapper={val => {
                    setChart1(val);
                  }}
                />
              )}{' '}
            </Grid>
          </Grid>

          <Grid
            mt={2}
            container
            display={'flex'}
            width={'100%'}
            flexDirection={'column'}
            sx={{ borderRadius: '8px', padding: 2, bgcolor: 'white' }}
          >
            <Box display={'flex'} justifyContent="space-between" mb={2}>
              <Typography fontSize={20} fontWeight="700">
                List of Flows
              </Typography>
              <Button
                onClick={() => {
                  setShowFlowList(true);
                  setShowFormList(null);
                }}
                variant="outlined"
                sx={{
                  borderColor: colorPrimary,
                  borderRadius: '10px',
                  color: 'black',
                  borderWidth: '2px',
                  width: '95px',
                  height: '36px',

                  fontSize: '14px',
                  fontWeight: '700',
                  '&:hover': {
                    borderColor: colorPrimary,
                    backgroundColor: 'transparent',
                    borderRadius: '10px',
                    borderWidth: '2px'
                  }
                }}
              >
                All flows
              </Button>
            </Box>
            {isFlowsLoading && <Skeleton height={33} width={'100%'} />}
            {!isFlowsLoading && (
              <CustomDataTable
                columns={columns}
                allowSelection={false}
                allowPagination={false}
                data={flowsData?.flows.length > 0 ? flowsData.flows : []}
                defaultOrderBy={'createdDate'}
                defaultPage={0}
                defaultRowsPerPage={4}
                totalCount={0}
                hideEmptyRows={true}
                onRowClick={row => {
                  setShowFlowList(true);
                  setShowFormList(row);
                  setFormResponsePayload(null);
                }}
              />
            )}
          </Grid>
        </>
      )}
      {showFlowList && (
        <>
          {!showFormList && (
            <>
              <Typography mb={2} variant="h5" fontWeight={'600'}>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={
                    <SvgIcon sx={{ height: 24, width: 24 }}>
                      <ChevronRightIcon color={colorTextGrey} />
                    </SvgIcon>
                  }
                >
                  <Link
                    color="inherit"
                    href={'Analytics'}
                    onClick={event => {
                      event.preventDefault();

                      setShowFlowList(null);
                    }}
                    sx={{ textDecoration: 'none' }}
                  >
                    <Typography
                      fontWeight={'700'}
                      color={colorLinkGrey}
                      fontSize={'24px'}
                    >
                      Analytics
                    </Typography>
                  </Link>
                  <Typography
                    sx={{
                      fontSize: '32px',
                      fontWeight: '700',
                      color: colorTextBlack
                    }}
                  >
                    List of Flows
                  </Typography>
                </Breadcrumbs>
              </Typography>

              <Grid
                mt={2}
                container
                display={'flex'}
                width={'100%'}
                flexDirection={'column'}
                sx={{ borderRadius: '8px', padding: 2, bgcolor: 'white' }}
              >
                {isFlowsLoading && <Skeleton height={33} width={'100%'} />}
                {!isFlowsLoading && (
                  <CustomDataTable
                    columns={columns}
                    allowSelection={false}
                    allowPagination={false}
                    data={flowsData?.flows.length > 0 ? flowsData.flows : []}
                    defaultOrderBy={'createdDate'}
                    defaultPage={0}
                    defaultRowsPerPage={flowsData.totalCount}
                    totalCount={flowsData.totalCount}
                    hideEmptyRows={true}
                    onRowClick={row => {
                      setShowFlowList(true);
                      setShowFormList(row);
                      setFormResponsePayload(null);
                    }}
                  />
                )}
              </Grid>
            </>
          )}
          {showFormList && (
            <>
              {!(
                formResponsesPayload?.flowId || formResponsesPayload?.formId
              ) && (
                <>
                  <Box display={'flex'}>
                    <Typography
                      variant="h5"
                      sx={{ display: 'flex', alignItems: 'center' }}
                      fontWeight={'600'}
                      mb={2}
                    >
                      <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={
                          <SvgIcon sx={{ height: 24, width: 24 }}>
                            <ChevronRightIcon color={colorTextGrey} />
                          </SvgIcon>
                        }
                      >
                        <Link
                          color="inherit"
                          href={'Analytics'}
                          onClick={event => {
                            event.preventDefault();
                            setShowFormList(null);
                          }}
                          sx={{ textDecoration: 'none' }}
                        >
                          <Typography
                            fontWeight={'700'}
                            color={colorLinkGrey}
                            fontSize={'24px'}
                          >
                            List of Flows
                          </Typography>
                        </Link>
                        <Typography
                          sx={{
                            fontSize: '32px',
                            fontWeight: '700',
                            display: 'flex'
                          }}
                        >
                          {showFormList.flowName || ''}{' '}
                          <span
                            style={{
                              fontSize: '12px',
                              color: 'GrayText',
                              alignContent: 'center',
                              marginLeft: 5
                            }}
                          >
                            <div
                              style={{
                                fontWeight: '600',
                                backgroundColor:
                                  showFormList.status === true
                                    ? '#EBF9D9'
                                    : '#FDF0E3',
                                padding: '3px',
                                borderRadius: '1.25rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minWidth: 90,
                                color:
                                  showFormList.status === true
                                    ? '#749E35'
                                    : '#D62828'
                              }}
                            >
                              <Avatar
                                sx={{
                                  width: 8,
                                  height: 8,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  borderRadius: '50%',
                                  backgroundColor:
                                    showFormList.status === true
                                      ? '#749E35'
                                      : '#D62828',
                                  color: 'white',
                                  fontSize: '12px',
                                  marginRight: '5px'
                                }}
                              >
                                <></>
                              </Avatar>

                              {showFormList.status === true
                                ? 'Active'
                                : 'InActive'}
                            </div>
                          </span>
                        </Typography>
                      </Breadcrumbs>
                    </Typography>
                  </Box>
                  <Grid
                    component={Paper}
                    elevation={0}
                    sx={{
                      borderRadius: '8px',
                      padding: 2,
                      width: '100%'
                    }}
                  >
                    {/* {isArrayNotEmpty(formList?.list) &&
                      formList?.list.map((cc, idx) => (
                        <ListView
                          key={idx}
                          flowId={cc.flowId}
                          formId={cc.formId}
                          responseCount={cc.responses}
                          name={cc.formName}
                          onClick={() => handleFormClick(cc)}
                        />
                      ))} */}

                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="formListed">
                        {provided => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {localFormList.map((cc, idx) => (
                              <Draggable
                                key={cc.flowId}
                                draggableId={`form-${idx}`}
                                index={idx}
                              >
                                {provided => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <ListView
                                      key={idx}
                                      id={`form-${idx}`}
                                      flowId={cc.flowId}
                                      formId={cc.formId}
                                      responseCount={cc.responses}
                                      name={cc.formName}
                                      provided={provided}
                                      onClick={() => handleFormClick(cc)}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                    <CustomPagination
                      count={formList?.totalCount}
                      page={formListPayload.page}
                      rowsPerPage={formListPayload.size}
                      onPageChange={(_, val) =>
                        setFormListPayload({ ...formListPayload, page: val })
                      }
                    />
                  </Grid>
                </>
              )}
              {(formResponsesPayload?.flowId ||
                formResponsesPayload?.formId) && (
                <>
                  <Box display={'flex'}>
                    <Typography
                      variant="h5"
                      mb={2}
                      sx={{ display: 'flex', alignItems: 'center' }}
                      fontWeight={'600'}
                    >
                      <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={
                          <SvgIcon sx={{ height: 24, width: 24 }}>
                            <ChevronRightIcon color={colorTextGrey} />
                          </SvgIcon>
                        }
                      >
                        <Link
                          color="inherit"
                          href={'Analytics'}
                          onClick={event => {
                            event.preventDefault();
                            setShowFormList(
                              showFormList ? { ...showFormList } : null
                            );
                            setFormResponsePayload(null);
                          }}
                          sx={{ textDecoration: 'none' }}
                        >
                          <Typography
                            fontWeight={'700'}
                            color={colorLinkGrey}
                            fontSize={'24px'}
                          >
                            {formResponsesPayload?.flowName}
                          </Typography>
                        </Link>
                        <Typography
                          sx={{ fontSize: '32px', fontWeight: '700' }}
                        >
                          {formResponsesPayload?.formName || ''}
                        </Typography>
                      </Breadcrumbs>
                    </Typography>
                  </Box>
                  <Grid container borderRadius={2}>
                    {isArrayNotEmpty(flowsAndFormsAnalyticsDetailData) &&
                      flowsAndFormsAnalyticsDetailData.map((item, idx) => (
                        <ChartViewComponent
                          key={idx}
                          sr={idx}
                          questionId={item?.questionId}
                          questionText={item?.questionText}
                          chartType={item?.questionOptionId}
                          rating={item?.avgRating}
                          responseCount={item?.responseCount}
                          chartData={item?.responseValue}
                        />
                      ))}
                  </Grid>
                </>
              )}
            </>
          )}
        </>
      )}
      <Dialog
        sx={{ borderRadius: '8px' }}
        open={chart !== ''}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ borderRadius: 10 }}>
          <Box textAlign={'end'}>
            <IconButton size="small" onClick={handleClose}>
              <SvgIcon sx={{ height: 20, width: 20 }}>
                <XClose />
              </SvgIcon>
            </IconButton>
            <Legend
              series={
                flowsAndFormsAnalyticsData.analyticsData.length > 0
                  ? flowsAndFormsAnalyticsData.analyticsData[0]
                      .slice(1)
                      .map((name, index) => ({
                        name,
                        color: options.series[index].color
                      }))
                  : []
              }
            />
          </Box>
          {chart == 1 && (
            <Chart
              height={'60vh'}
              width={'100%'}
              chartType="LineChart"
              data={flowsAndFormsAnalyticsData.analyticsData}
              options={options}
            />
          )}
        </DialogContent>
      </Dialog>{' '}
      {copySuccess && <SnackBarBox message="Link copied!" type={'success'} />}{' '}
    </>
  );
};

export default FlowsAndFormAnalytics;
