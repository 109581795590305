import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api, multipartApi } from '../../config/api';
import { downloadFilePDFPostApi, openFilePDFPostApi } from '../../utils/utils';

const initial = {
  isFlowsLoading: false,
  isFlowsSuccess: false,
  isFlowsFailed: false,
  flowsData: {
    totalCount: 0,
    allCount: 0,
    activeCount: 0,
    inActiveCount: 0,
    flows: []
  },
  flowsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  formListByFlowIdLoading: false,
  formListByFlowIdSuccess: false,
  formListByFlowIdFail: false,
  formList: { totalCount: 0, list: [] },
  formListByFlowIdErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCreateUpdateFlowsLoading: false,
  isCreateUpdateFlowsSuccess: false,
  isCreateUpdateFlowsFail: false,
  createUpdateFlowsResponse: null,
  createUpdateFlowsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAssignFlowLoading: false,
  isAssignFlowSuccess: false,
  isAssignFlowFail: false,
  assignFlowResponse: null,
  assignFlowErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  activateFlowsLoading: false,
  activateFlowsSuccess: false,
  activateFlowsFail: false,
  activateFlowsResponse: null,
  activateFlowsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  deactivateFlowsLoading: false,
  deactivateFlowsSuccess: false,
  deactivateFlowsFail: false,
  deactivateFlowsResponse: null,
  deactivateFlowsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  deleteFlowsLoading: false,
  deleteFlowsSuccess: false,
  deleteFlowsFail: false,
  deleteFlowsResponse: null,
  deleteFlowsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isCreateUpdateFormsLoading: false,
  isCreateUpdateFormsSuccess: false,
  isCreateUpdateFormsFail: false,
  createUpdateFormsResponse: null,
  createUpdateFormsErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isPreviewFormLoading: false,
  isPreviewFormSuccess: false,
  isPreviewFormFailed: false,
  previewForm: null,
  previewFormErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isFormResponseLoading: false,
  isFormResponseSuccess: false,
  isFormResponseFailed: false,
  formResponse: null,
  formResponseErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isUnAssignedFormLoading: false,
  isUnAssignedFormSuccess: false,
  isUnAssignedFormFailed: false,
  unAssignedFormList: [],
  unAssignedFormErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAssignFormToFlowLoading: false,
  isAssignFormToFlowSuccess: false,
  isAssignFormToFlowFailed: false,
  assignFormToFlowResponse: '',
  assignFormToFlowErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAssignedUserAndUserGroupLoading: false,
  isAssignedUserAndUserGroupSuccess: false,
  isAssignedUserAndUserGroupFailed: false,
  assignedUserAndUserGroup: [],
  assignedUserAndUserGroupErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  isDownloadIndividualPDFLoading: false,

  isAssignFormLoading: false,
  isAssignFormSuccess: false,
  isAssignFormFail: false,
  assignFormResponse: '',
  assignFormErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isAcceptFormLoading: false,
  isAcceptFormSuccess: false,
  isAcceptFormFail: false,
  acceptFormResponse: '',
  acceptFormErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  usersAndGroupsFormLoading: false,
  usersAndGroupsFormSuccess: false,
  usersAndGroupsFormFailed: false,
  usersAndGroupsList: [],
  usersAndGroupsFormErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  scheduleReminderDateFormLoading: false,
  scheduleReminderDateFormSuccess: false,
  scheduleReminderDateFormFailed: false,
  scheduleReminderDateForm: '',
  scheduleReminderDateFormErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  scheduleReminderUserFormLoading: false,
  scheduleReminderUserFormSuccess: false,
  scheduleReminderUserFormFailed: false,
  scheduleReminderUserForm: '',
  scheduleReminderUserFormErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  scheduleReminderUserGroupFormLoading: false,
  scheduleReminderUserGroupFormSuccess: false,
  scheduleReminderUserGroupFormFailed: false,
  scheduleReminderUserGroupForm: '',
  scheduleReminderUserGroupFormErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },
  sendScheduleReminderDateFormLoading: false,
  sendScheduleReminderDateFormSuccess: false,
  sendScheduleReminderDateFormFailed: false,
  sendScheduleReminderDateForm: '',
  sendScheduleReminderDateFormErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isQuestionListByIndicatorLoading: false,
  isQuestionListByIndicatorSuccess: false,
  isQuestionListByIndicatorFailed: false,
  questionListByIndicator: [],
  questionListByIndicatorErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  },

  isFormSequenceUpdateLoading: false,
  isFormSequenceUpdateSuccess: false,
  isFormSequenceUpdateFailed: false,
  // questionListByIndicator: [],
  formSequenceUpdateErrorContainer: {
    error: false,
    errorMessage: 'Internal Server Error',
    description: 'Something Went Wrong',
    statusCode: 0
  }
};

export const revertFlowsFormData = createAction('REVERT_FLOWS_FORM_DATA');
export const revertQuestionList = createAction(
  'REVERT_QUESTION_LIST_BY_INDICATOR_DATA'
);
export const revertFlowsPageData = createAction('REVERT_FLOWS_PAGE_DATA');
export const revertFormsFormData = createAction('REVERT_FORMS_FORM_DATA');
export const revertUserAndUserGroupAssignment = createAction(
  'REVERT_USER_AND_USERGROUP_ASSIGNMENT'
);
export const revertFormAssignment = createAction('REVERT_FORM_ASSIGNMENT');
export const revertFormScheduleReminder = createAction(
  'REVERT_FORM_SCHEDULEREMINDER'
);
export const revertFormAcceptanceResponse = createAction(
  'REVERT_FORM_ACCEPTANCE_RESPONSE'
);

export const fetchFormResponseCountById = async ({ formId, flowId }) => {
  try {
    const response = await api.get(`forms/${formId}?flowId=${flowId}`);
    return response.data.payload?.dataList?.individualResponses?.length || 0;
  } catch (error) {
    throw new Error(error.response?.data?.error || 'An error occurred');
  }
};

export const getFlows = createAsyncThunk(
  'flows/getFlows',
  async (flowsPayload, { rejectWithValue, getState }) => {
    try {
      let response;

      if (flowsPayload?.search == '' || flowsPayload?.search == null) {
        response = await api.post(
          `flows/all?loggedInUserName=${getState().auth.authData.userName}&userId=${getState().auth.authData.userId}`,
          flowsPayload
        );
      } else {
        response = await api.post(
          `flows/search?searchCriteria=${flowsPayload?.search}`,
          flowsPayload
        );
      }

      return response.data?.payload || initial.flowsData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const createUpdateFlows = createAsyncThunk(
  'flows/createUpdateFlows',
  async ({ flowId, payload }, { rejectWithValue }) => {
    try {
      let response;
      if (flowId) {
        response = await api.put(`flows/${flowId}`, payload);
      } else {
        response = await api.post(`flows`, payload);
      }

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getFormsListByFlowId = createAsyncThunk(
  'flows/getFormsListByFlowId',
  async ({ flowId, payload, sortByValue }, { rejectWithValue, getState }) => {
    try {
      const response = await api.post(
        `flows/${flowId}?${sortByValue ? `sortBy=${sortByValue}&` : ''}userId=${getState().auth.authData.userId}`,
        payload
      );

      return response.data.payload || [];
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const activateFlows = createAsyncThunk(
  'flows/activateFlows',
  async (payload, { rejectWithValue }) => {
    try {
      let response = await api.put(`flows/activate`, payload);
      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deactivateFlows = createAsyncThunk(
  'flows/deActivateFlows',
  async (payload, { rejectWithValue }) => {
    try {
      let response = await api.put(`flows/activate`, payload);

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const deleteFlows = createAsyncThunk(
  'flows/deleteFlows',
  async (payload, { rejectWithValue }) => {
    try {
      let response = await api.delete(`flows`, { data: payload });

      return response.data?.payload;
    } catch (error) {
      console.log(error, 'reoeor');
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const createUpdateForm = createAsyncThunk(
  'flows/createUpdateForm',
  async ({ formId, forceUpdate, payload }, { rejectWithValue }) => {
    try {
      let response;
      if (formId) {
        response = await multipartApi.put(
          `forms/${formId}?forceUpdate=1`,
          payload
        );
      } else {
        response = await multipartApi.post(`forms`, payload);
      }

      return response.data?.payload;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const previewFormById = createAsyncThunk(
  'flows/previewForm',
  async (formId, { rejectWithValue }) => {
    try {
      const response = await api.get(`forms/previewForm/${formId}`);

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const formResponseById = createAsyncThunk(
  'form/formResponse',
  async ({ formId, flowId }, { rejectWithValue }) => {
    try {
      const response = await api.get(`forms/${formId}?flowId=${flowId}`);

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const assignFlow = createAsyncThunk(
  'flows/assignFlow',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `flows/assignFlowToUsersAndGroups`,
        payload
      );

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getQuestionRatingByIndicatorId = createAsyncThunk(
  'flows/getQuestionRatingByIndicatorId',
  async ({ indicatorId, flowId, formId }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `forms/getQuestionRatingByIndicatorId/${indicatorId}?formId=${formId}&flowId=${flowId}`
      );

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const assignFormToFlow = createAsyncThunk(
  'flows/assignFormToFlow',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`flows/assignFormsToFlow`, payload);

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getUnAssignedForms = createAsyncThunk(
  'flows/getUnAssignedForms',
  async (flowId, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `flows/getAllFormsNotAssignedOnFlow/${flowId}`
      );

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getAssignedUserAndUserGroupOfFlow = createAsyncThunk(
  'form/getAssignedUserAndUserGroupOfFlow',
  async (flowId, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `flows/getPreAssignedUserAndUserGroupsByFlowId/${flowId}`
      );

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const downloadIndividualPDF = createAsyncThunk(
  'flows/downloadIndividualResponseData',
  async ({ fileName, body }, { rejectWithValue }) => {
    try {
      return await openFilePDFPostApi({
        apiPath: '/forms/printIndResponse',
        body: body,
        fileName: fileName
      });
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const downloadQuestionWisePDF = createAsyncThunk(
  'flows/downloadQuestionWiseResponseData',
  async ({ fileName, body }, { rejectWithValue }) => {
    try {
      return await openFilePDFPostApi({
        apiPath: '/forms/printQuestionWiseResponse',
        body: body,
        fileName: fileName
      });
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const assignForm = createAsyncThunk(
  'form/assignForm',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`forms/assignUsersAndGroups`, payload);

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getAssignedUserAndUserGroupOfForm = createAsyncThunk(
  'form/getAssignedUserAndUserGroupOfForm',
  async ({ formId, flowId }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `forms/getUsersAndGroups/${formId}?flowId=${flowId}`
      );

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const acceptResponse = createAsyncThunk(
  'form/acceptResponse',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(`forms/acceptResponse`, payload);

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getUsersAndGroupsForSchedule = createAsyncThunk(
  'flows/getUsersAndGroups',
  async ({ formId, flowId }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `forms/getPendingUserListForSendReminder/${formId}?flowId=${flowId}`
      );

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getScheduleReminderDateForSchedule = createAsyncThunk(
  'flows/getScheduleReminderDate',
  async (flowId, { rejectWithValue }) => {
    try {
      const response = await api.get(`flows/getScheduleReminderDate/${flowId}`);

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const sendReminderUserForSchedule = createAsyncThunk(
  'flows/sendReminderForUser',
  async ({ flowId, userName, formId }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `flows/sendReminder/${formId}?flowId=${flowId}&loggedInUserName=${userName}`
      );
      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const sendReminderUserGroupForSchedule = createAsyncThunk(
  'flows/sendReminderForUserGroup',
  async ({ formId, flowId, userGroupIds, userName }, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `flows/sendFormAssignmentReminder/${formId}?userGroupIds=${userGroupIds}&flowId=${flowId}&loggedInUserName=${userName}`
      );

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const sendScheduleReminderDateForSchedule = createAsyncThunk(
  'flows/sendScheduleReminderDate',
  async ({ flowId, scheduleDate, userName }, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `flows/scheduleReminder?formId=${flowId}&scheduleDate=${scheduleDate}&loggedInUserName=${userName}`
      );

      return response.data.payload || null;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const getFlowsCount = createAsyncThunk(
  'flows/getFlowsCount',
  async (_, { rejectWithValue, getState }) => {
    try {
      let response = await api.post(
        `flows/all?loggedInUserName=${getState().auth.authData.userName}&userId=${getState().auth.authData.userId}`,
        {
          type: 'all',
          page: 0,
          size: 10
        }
      );
      // console.log(response,"response");

      return response.data?.payload || initial.flowsData;
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const updateFormSequence = createAsyncThunk(
  'flows/updateFormSequence',
  async (payload, { rejectWithValue }) => {
    try {
      console.log(payload, 'in api');
      const response = await api.post(`flows/formSequence`, payload);

      return response.data.payload || [];
    } catch (error) {
      return rejectWithValue(error.response.data?.error);
    }
  }
);

export const flowsSlice = createSlice({
  name: 'flows',
  initialState: initial,
  extraReducers: builder => {
    builder
      .addCase(revertFormsFormData, state => {
        state.isPreviewFormLoading = initial.isPreviewFormLoading;
        state.isPreviewFormSuccess = initial.isPreviewFormSuccess;
        state.isPreviewFormFailed = initial.isPreviewFormFailed;
        state.previewForm = initial.previewForm;

        state.isCreateUpdateFormsLoading = false;
        state.isCreateUpdateFormsSuccess = false;
        state.isCreateUpdateFormsFail = false;
        state.createUpdateFormsResponse = null;
        state.createUpdateFormsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(revertFlowsFormData, state => {
        state.isCreateUpdateFlowsLoading = initial.isCreateUpdateFlowsLoading;
        state.isCreateUpdateFlowsSuccess = initial.isCreateUpdateFlowsSuccess;
        state.isCreateUpdateFlowsFail = initial.isCreateUpdateFlowsFail;
        state.createUpdateFlowsErrorContainer =
          initial.createUpdateFlowsErrorContainer;
        state.createUpdateFlowsResponse = initial.createUpdateFlowsResponse;
      })
      .addCase(revertFlowsPageData, state => {
        state.deactivateFlowsLoading = initial.deactivateFlowsLoading;
        state.deactivateFlowsSuccess = initial.deactivateFlowsSuccess;
        state.deactivateFlowsFail = initial.deactivateFlowsFail;
        state.deactivateFlowsResponse = initial.deactivateFlowsResponse;
        state.deactivateFlowsErrorContainer =
          initial.deactivateFlowsErrorContainer;

        state.activateFlowsLoading = initial.activateFlowsLoading;
        state.activateFlowsSuccess = initial.activateFlowsSuccess;
        state.activateFlowsFail = initial.activateFlowsFail;
        state.activateFlowsResponse = initial.activateFlowsResponse;
        state.activateFlowsErrorContainer = initial.activateFlowsErrorContainer;

        state.deleteFlowsLoading = initial.deleteFlowsLoading;
        state.deleteFlowsSuccess = initial.deleteFlowsSuccess;
        state.deleteFlowsFail = initial.deleteFlowsFail;
        state.deleteFlowsResponse = initial.deleteFlowsResponse;
        state.deleteFlowsErrorContainer = initial.deleteFlowsErrorContainer;
      })

      .addCase(getFlows.pending, state => {
        state.isFlowsLoading = true;
        state.isFlowsSuccess = false;
        state.isFlowsFailed = false;
        state.flowsErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getFlows.fulfilled, (state, action) => {
        state.isFlowsLoading = false;
        state.isFlowsSuccess = true;
        state.isFlowsFailed = false;
        state.flowsData = {
          ...state.flowsData,
          flows: action.payload?.dataList || [],
          totalCount: action.payload.totalCount || 0
        };
      })
      .addCase(getFlows.rejected, (state, action) => {
        state.isFlowsLoading = false;
        state.isFlowsSuccess = false;
        state.isFlowsFailed = true;
        state.flowsData = initial.flowsData;
        state.flowsErrorContainer = {
          ...state.flowsErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUpdateFlows.pending, state => {
        state.isCreateUpdateFlowsLoading = true;
        state.isCreateUpdateFlowsSuccess = false;
        state.isCreateUpdateFlowsFail = false;
        state.createUpdateFlowsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUpdateFlows.fulfilled, (state, action) => {
        state.isCreateUpdateFlowsLoading = false;
        state.isCreateUpdateFlowsSuccess = true;
        state.isCreateUpdateFlowsFail = false;
        state.createUpdateFlowsResponse = action.payload?.message;
      })
      .addCase(createUpdateFlows.rejected, (state, action) => {
        state.isCreateUpdateFlowsLoading = false;
        state.isCreateUpdateFlowsSuccess = false;
        state.isCreateUpdateFlowsFail = true;
        state.createUpdateFlowsErrorContainer = {
          ...state.createUpdateFlowsErrorContainer,
          ...action.payload
        };
      })

      .addCase(activateFlows.pending, state => {
        state.activateFlowsLoading = true;
        state.activateFlowsSuccess = false;
        state.activateFlowsFail = false;
        state.activateFlowsResponse = null;
        state.activateFlowsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(activateFlows.fulfilled, (state, action) => {
        state.activateFlowsLoading = false;
        state.activateFlowsSuccess = true;
        state.activateFlowsFail = false;
        state.activateFlowsResponse = action.payload?.message;
      })
      .addCase(activateFlows.rejected, (state, action) => {
        state.activateFlowsLoading = false;
        state.activateFlowsSuccess = false;
        state.activateFlowsFail = true;
        state.activateFlowsResponse = null;
        state.activateFlowsErrorContainer = {
          ...state.activateFlowsErrorContainer,
          ...action.payload
        };
      })

      .addCase(deactivateFlows.pending, state => {
        state.deactivateFlowsLoading = true;
        state.deactivateFlowsSuccess = false;
        state.deactivateFlowsFail = false;
        state.deactivateFlowsResponse = null;
        state.deactivateFlowsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deactivateFlows.fulfilled, (state, action) => {
        state.deactivateFlowsLoading = false;
        state.deactivateFlowsSuccess = true;
        state.deactivateFlowsFail = false;
        state.deactivateFlowsResponse = action.payload?.message;
      })
      .addCase(deactivateFlows.rejected, (state, action) => {
        state.deactivateFlowsLoading = false;
        state.deactivateFlowsSuccess = false;
        state.deactivateFlowsFail = true;
        state.deactivateFlowsErrorContainer = {
          ...state.deactivateFlowsErrorContainer,
          ...action.payload
        };
      })

      .addCase(deleteFlows.pending, state => {
        state.deleteFlowsLoading = true;
        state.deleteFlowsSuccess = false;
        state.deleteFlowsFail = false;
        state.deleteFlowsResponse = null;
        state.deleteFlowsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(deleteFlows.fulfilled, (state, action) => {
        state.deleteFlowsLoading = false;
        state.deleteFlowsSuccess = true;
        state.deleteFlowsFail = false;
        state.deleteFlowsResponse = action.payload?.message;
      })
      .addCase(deleteFlows.rejected, (state, action) => {
        state.deleteFlowsLoading = false;
        state.deleteFlowsSuccess = false;
        state.deleteFlowsFail = true;
        state.deleteFlowsErrorContainer = {
          ...state.deleteFlowsErrorContainer,
          ...action.payload
        };
      })

      .addCase(getFormsListByFlowId.pending, state => {
        state.formListByFlowIdLoading = true;
        state.formListByFlowIdSuccess = false;
        state.formListByFlowIdFail = false;
        state.formList = initial.formList;
        state.formListByFlowIdErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(getFormsListByFlowId.fulfilled, (state, action) => {
        state.formListByFlowIdLoading = false;
        state.formListByFlowIdSuccess = true;
        state.formListByFlowIdFail = false;
        state.formList = action.payload
          ? {
              totalCount: action.payload?.totalCount,
              list: action.payload?.dataList || []
            }
          : initial.formList;
      })
      .addCase(getFormsListByFlowId.rejected, (state, action) => {
        state.formListByFlowIdLoading = false;
        state.formListByFlowIdSuccess = false;
        state.formListByFlowIdFail = true;
        state.formList = initial.formList;
        state.formListByFlowIdErrorContainer = {
          ...state.formListByFlowIdErrorContainer,
          ...action.payload
        };
      })

      .addCase(createUpdateForm.pending, state => {
        state.isCreateUpdateFormsLoading = true;
        state.isCreateUpdateFormsSuccess = false;
        state.isCreateUpdateFormsFail = false;
        state.createUpdateFormsErrorContainer = {
          error: false,
          errorMessage: 'Internal Server Error',
          description: 'Something Went Wrong',
          statusCode: 0
        };
      })
      .addCase(createUpdateForm.fulfilled, (state, action) => {
        state.isCreateUpdateFormsLoading = false;
        state.isCreateUpdateFormsSuccess = true;
        state.isCreateUpdateFormsFail = false;
        state.createUpdateFormsResponse = action.payload?.message;
      })
      .addCase(createUpdateForm.rejected, (state, action) => {
        state.isCreateUpdateFormsLoading = false;
        state.isCreateUpdateFormsSuccess = false;
        state.isCreateUpdateFormsFail = true;
        state.createUpdateFormsErrorContainer = {
          ...state.createUpdateFormsErrorContainer,
          ...action.payload
        };
      })

      .addCase(previewFormById.pending, state => {
        state.isPreviewFormLoading = true;
        state.isPreviewFormSuccess = false;
        state.isPreviewFormFailed = false;
        state.previewForm = null;
        state.previewFormErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(previewFormById.fulfilled, (state, action) => {
        state.isPreviewFormLoading = false;
        state.isPreviewFormSuccess = true;
        state.isPreviewFormFailed = false;
        state.previewForm = action.payload?.dataList || null;
      })
      .addCase(previewFormById.rejected, (state, action) => {
        state.isPreviewFormLoading = false;
        state.isPreviewFormSuccess = false;
        state.isPreviewFormFailed = true;
        state.previewForm = initial.previewForm;
        state.previewFormErrorContainer = {
          ...state.previewFormErrorContainer,
          ...action.payload
        };
      })

      .addCase(formResponseById.pending, state => {
        state.isFormResponseLoading = true;
        state.isFormResponseSuccess = false;
        state.isFormResponseFailed = false;
        state.formResponse = null;
        state.formResponseErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(formResponseById.fulfilled, (state, action) => {
        state.isFormResponseLoading = false;
        state.isFormResponseSuccess = true;
        state.isFormResponseFailed = false;
        state.formResponse = action.payload?.dataList || null;
      })
      .addCase(formResponseById.rejected, (state, action) => {
        state.isFormResponseLoading = false;
        state.isFormResponseSuccess = false;
        state.isFormResponseFailed = true;
        state.formResponse = initial.previewForm;
        state.formResponseErrorContainer = {
          ...state.formResponseErrorContainer,
          ...action.payload
        };
      })
      .addCase(assignFlow.pending, state => {
        state.isAssignFlowLoading = true;
        state.isAssignFlowSuccess = false;
        state.isAssignFlowFail = false;
        state.assignFlowResponse = null;
        state.assignFlowErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(assignFlow.fulfilled, (state, action) => {
        state.isAssignFlowLoading = false;
        state.isAssignFlowSuccess = true;
        state.isAssignFlowFail = false;
        state.assignFlowResponse = action.payload?.message || '';
      })
      .addCase(assignFlow.rejected, (state, action) => {
        state.isAssignFlowLoading = false;
        state.isAssignFlowSuccess = false;
        state.isAssignFlowFail = true;
        state.assignFlowResponse = initial.assignFlowResponse;
        state.assignFlowErrorContainer = {
          ...state.assignFlowErrorContainer,
          ...action.payload
        };
      })

      .addCase(revertUserAndUserGroupAssignment, state => {
        state.isAssignFlowLoading = initial.isAssignFlowLoading;
        state.isAssignFlowSuccess = initial.isAssignFlowSuccess;
        state.isAssignFlowFail = initial.isAssignFlowFail;
        state.assignFlowResponse = initial.assignFlowResponse;
        state.assignFlowErrorContainer = initial.assignFlowErrorContainer;

        state.isAssignFormLoading = initial.isAssignFormLoading;
        state.isAssignFormSuccess = initial.isAssignFormSuccess;
        state.isAssignFormFail = initial.isAssignFormFail;
        state.assignFormResponse = initial.assignFormResponse;
        state.assignFormErrorContainer = initial.assignFormErrorContainer;
      })

      .addCase(revertFormAssignment, state => {
        state.isUnAssignedFormLoading = false;
        state.isUnAssignedFormSuccess = false;
        state.isUnAssignedFormFailed = false;
        state.unAssignedFormList = [];
        state.unAssignedFormErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };

        state.isAssignFormToFlowLoading = false;
        state.isAssignFormToFlowSuccess = false;
        state.isAssignFormToFlowFailed = false;
        state.assignFormToFlowResponse = '';
        state.assignFormToFlowErrorContainer =
          initial.assignFormToFlowErrorContainer;
        // state.
      })
      .addCase(getUnAssignedForms.pending, state => {
        state.isUnAssignedFormLoading = true;
        state.isUnAssignedFormSuccess = false;
        state.isUnAssignedFormFailed = false;
        state.unAssignedFormList = [];
        state.unAssignedFormErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(getUnAssignedForms.fulfilled, (state, action) => {
        state.isUnAssignedFormLoading = false;
        state.isUnAssignedFormSuccess = true;
        state.isUnAssignedFormFailed = false;
        state.unAssignedFormList = action.payload?.dataList || [];
      })
      .addCase(getUnAssignedForms.rejected, (state, action) => {
        state.isUnAssignedFormLoading = false;
        state.isUnAssignedFormSuccess = false;
        state.isUnAssignedFormFailed = true;
        state.unAssignedFormList = initial.unAssignedFormList;
        state.unAssignedFormErrorContainer = {
          ...state.unAssignedFormErrorContainer,
          ...action.payload
        };
      })
      .addCase(assignFormToFlow.pending, state => {
        state.isAssignFormToFlowLoading = true;
        state.isAssignFormToFlowSuccess = false;
        state.isAssignFormToFlowFailed = false;
        state.assignFormToFlowResponse = '';
        state.assignFormToFlowErrorContainer = initial.assignFlowErrorContainer;
      })
      .addCase(assignFormToFlow.fulfilled, (state, action) => {
        state.isAssignFormToFlowLoading = false;
        state.isAssignFormToFlowSuccess = true;
        state.isAssignFormToFlowFailed = false;
        state.assignFormToFlowResponse = action.payload?.message || '';
        state.assignFormToFlowErrorContainer = initial.assignFlowErrorContainer;
      })
      .addCase(assignFormToFlow.rejected, (state, action) => {
        state.isAssignFormToFlowLoading = false;
        state.isAssignFormToFlowSuccess = false;
        state.isAssignFormToFlowFailed = true;
        state.assignFormToFlowResponse = action.payload || '';
        state.assignFormToFlowErrorContainer = {
          ...state.assignFormToFlowErrorContainer,
          ...action.payload
        };
      })
      .addCase(getAssignedUserAndUserGroupOfFlow.pending, state => {
        state.isAssignedUserAndUserGroupLoading = true;
        state.isAssignedUserAndUserGroupSuccess = false;
        state.isAssignedUserAndUserGroupFailed = false;
        state.assignedUserAndUserGroup = null;
        state.assignedUserAndUserGroupErrorContainer =
          initial.assignedUserAndUserGroupErrorContainer;
      })
      .addCase(getAssignedUserAndUserGroupOfFlow.fulfilled, (state, action) => {
        state.isAssignedUserAndUserGroupLoading = false;
        state.isAssignedUserAndUserGroupSuccess = true;
        state.isAssignedUserAndUserGroupFailed = false;
        state.assignedUserAndUserGroup = action.payload?.dataList || '';
        state.assignedUserAndUserGroupErrorContainer =
          initial.assignedUserAndUserGroupErrorContainer;
      })
      .addCase(getAssignedUserAndUserGroupOfFlow.rejected, (state, action) => {
        state.isAssignedUserAndUserGroupLoading = false;
        state.isAssignedUserAndUserGroupSuccess = false;
        state.isAssignedUserAndUserGroupFailed = true;
        state.assignedUserAndUserGroup = null;
        state.assignedUserAndUserGroupErrorContainer = {
          ...state.assignedUserAndUserGroupErrorContainer,
          ...action.payload
        };
      })
      .addCase(downloadIndividualPDF.pending, state => {
        state.isDownloadIndividualPDFLoading = true;
      })
      .addCase(downloadIndividualPDF.fulfilled, state => {
        state.isDownloadIndividualPDFLoading = false;
      })
      .addCase(downloadIndividualPDF.rejected, state => {
        state.isDownloadIndividualPDFLoading = false;
      })
      .addCase(getAssignedUserAndUserGroupOfForm.pending, state => {
        state.isAssignedUserAndUserGroupLoading = true;
        state.isAssignedUserAndUserGroupSuccess = false;
        state.isAssignedUserAndUserGroupFailed = false;
        state.assignedUserAndUserGroup = null;
        state.assignedUserAndUserGroupErrorContainer =
          initial.assignedUserAndUserGroupErrorContainer;
      })
      .addCase(getAssignedUserAndUserGroupOfForm.fulfilled, (state, action) => {
        state.isAssignedUserAndUserGroupLoading = false;
        state.isAssignedUserAndUserGroupSuccess = true;
        state.isAssignedUserAndUserGroupFailed = false;
        state.assignedUserAndUserGroup = action.payload?.dataList || '';
        state.assignedUserAndUserGroupErrorContainer =
          initial.assignedUserAndUserGroupErrorContainer;
      })
      .addCase(getAssignedUserAndUserGroupOfForm.rejected, (state, action) => {
        state.isAssignedUserAndUserGroupLoading = false;
        state.isAssignedUserAndUserGroupSuccess = false;
        state.isAssignedUserAndUserGroupFailed = true;
        state.assignedUserAndUserGroup = null;
        state.assignedUserAndUserGroupErrorContainer = {
          ...state.assignedUserAndUserGroupErrorContainer,
          ...action.payload
        };
      })
      .addCase(assignForm.pending, state => {
        state.isAssignFormLoading = true;
        state.isAssignFormSuccess = false;
        state.assignFormResponse = '';
        state.assignFormErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(assignForm.fulfilled, (state, action) => {
        state.isAssignFormLoading = false;
        state.isAssignFormSuccess = true;
        state.isAssignFormFail = false;
        state.assignFormResponse = action.payload?.message || '';
      })
      .addCase(assignForm.rejected, (state, action) => {
        state.isAssignFormLoading = false;
        state.isAssignFormSuccess = false;
        state.isAssignFormFail = true;
        state.assignFormResponse = initial.assignFormResponse;
        state.assignFormErrorContainer = {
          ...state.assignFormErrorContainer,
          ...action.payload
        };
      })

      .addCase(revertFormAcceptanceResponse, state => {
        state.isAcceptFormLoading = false;
        state.isAcceptFormSuccess = false;
        state.acceptFormResponse = false;
        state.acceptFormResponse = '';
        state.acceptFormErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(acceptResponse.pending, state => {
        state.isAcceptFormLoading = true;
        state.isAcceptFormSuccess = false;
        state.isAcceptFormFail = false;
        state.acceptFormResponse = '';
        state.acceptFormErrorContainer = {
          error: false,
          errorMessage: '',
          description: '',
          statusCode: 0
        };
      })
      .addCase(acceptResponse.fulfilled, (state, action) => {
        state.isAcceptFormLoading = false;
        state.isAcceptFormSuccess = true;
        state.isAcceptFormFail = false;
        state.acceptFormResponse = action.payload?.message || '';
      })
      .addCase(acceptResponse.rejected, (state, action) => {
        state.isAcceptFormLoading = false;
        state.isAcceptFormSuccess = false;
        state.isAssignFormFail = true;
        state.acceptFormResponse = initial.acceptFormResponse;
        state.acceptFormErrorContainer = {
          ...state.acceptFormErrorContainer,
          ...action.payload
        };
      })
      .addCase(getUsersAndGroupsForSchedule.pending, state => {
        state.usersAndGroupsFormLoading = true;
        state.usersAndGroupsFormSuccess = false;
        state.usersAndGroupsFormFailed = false;
        state.usersAndGroupsList = [];
        state.usersAndGroupsFormErrorContainer =
          initial.usersAndGroupsFormErrorContainer;
      })
      .addCase(getUsersAndGroupsForSchedule.fulfilled, (state, action) => {
        state.usersAndGroupsFormLoading = false;
        state.usersAndGroupsFormSuccess = true;
        state.usersAndGroupsFormFailed = false;
        state.usersAndGroupsList = action.payload?.dataList || [];
      })

      .addCase(getUsersAndGroupsForSchedule.rejected, (state, action) => {
        state.usersAndGroupsFormLoading = false;
        state.usersAndGroupsFormSuccess = false;
        state.usersAndGroupsFormFailed = true;
        state.usersAndGroupsList = initial.usersAndGroupsList;
        state.usersAndGroupsFormErrorContainer = {
          ...state.usersAndGroupsFormErrorContainer,
          ...action.payload
        };
      })
      .addCase(getScheduleReminderDateForSchedule.pending, state => {
        state.scheduleReminderDateFormLoading = true;
        state.scheduleReminderDateFormSuccess = false;
        state.scheduleReminderDateFormFailed = false;
        state.scheduleReminderDateForm = '';
        state.scheduleReminderDateFormErrorContainer =
          initial.scheduleReminderDateFormErrorContainer;
      })

      .addCase(
        getScheduleReminderDateForSchedule.fulfilled,
        (state, action) => {
          state.scheduleReminderDateFormLoading = false;
          state.scheduleReminderDateFormSuccess = true;
          state.scheduleReminderDateFormFailed = false;
          state.scheduleReminderDateForm = action.payload || '';
        }
      )

      .addCase(getScheduleReminderDateForSchedule.rejected, (state, action) => {
        state.scheduleReminderDateFormLoading = false;
        state.scheduleReminderDateFormSuccess = false;
        state.scheduleReminderDateFormFailed = true;
        state.scheduleReminderDateForm = initial.scheduleReminderDateForm;
        state.scheduleReminderDateFormErrorContainer = {
          ...state.scheduleReminderDateFormErrorContainer,
          ...action.payload
        };
      })
      .addCase(sendReminderUserForSchedule.pending, state => {
        state.scheduleReminderUserFormLoading = true;
        state.scheduleReminderUserFormSuccess = false;
        state.scheduleReminderUserFormFailed = false;
        state.scheduleReminderUserForm = '';
        state.scheduleReminderUserFormErrorContainer =
          initial.scheduleReminderUserFormErrorContainer;
      })

      .addCase(sendReminderUserForSchedule.fulfilled, (state, action) => {
        state.scheduleReminderUserFormLoading = false;
        state.scheduleReminderUserFormSuccess = true;
        state.scheduleReminderUserFormFailed = false;
        state.scheduleReminderUserForm = action.payload || '';
      })

      .addCase(sendReminderUserForSchedule.rejected, (state, action) => {
        console.log(action, 'action');
        state.scheduleReminderUserFormLoading = false;
        state.scheduleReminderUserFormSuccess = false;
        state.scheduleReminderUserFormFailed = true;
        state.scheduleReminderUserForm = initial.scheduleReminderUserForm;
        state.scheduleReminderUserFormErrorContainer = {
          ...state.scheduleReminderUserFormErrorContainer,
          ...action.payload
        };
      })

      .addCase(sendReminderUserGroupForSchedule.pending, state => {
        state.scheduleReminderUserGroupFormLoading = true;
        state.scheduleReminderUserGroupFormSuccess = false;
        state.scheduleReminderUserGroupFormFailed = false;
        state.scheduleReminderUserGroupForm = '';
        state.scheduleReminderUserGroupFormErrorContainer =
          initial.scheduleReminderUserGroupFormErrorContainer;
      })

      .addCase(sendReminderUserGroupForSchedule.fulfilled, (state, action) => {
        state.scheduleReminderUserGroupFormLoading = false;
        state.scheduleReminderUserGroupFormSuccess = true;
        state.scheduleReminderUserGroupFormFailed = false;
        state.scheduleReminderUserGroupForm = action.payload || '';
      })

      .addCase(sendReminderUserGroupForSchedule.rejected, (state, action) => {
        state.scheduleReminderUserGroupFormLoading = false;
        state.scheduleReminderUserGroupFormSuccess = false;
        state.scheduleReminderUserGroupFormFailed = true;
        state.scheduleReminderUserGroupForm =
          initial.scheduleReminderUserGroupForm;
        state.scheduleReminderUserGroupFormErrorContainer = {
          ...state.scheduleReminderUserGroupFormErrorContainer,
          ...action.payload
        };
      })

      .addCase(sendScheduleReminderDateForSchedule.pending, state => {
        state.sendScheduleReminderDateFormLoading = true;
        state.sendScheduleReminderDateFormSuccess = false;
        state.sendScheduleReminderDateFormFailed = false;
        state.sendScheduleReminderDateForm = '';
        state.sendScheduleReminderDateFormErrorContainer =
          initial.sendScheduleReminderDateFormErrorContainer;
      })

      .addCase(
        sendScheduleReminderDateForSchedule.fulfilled,
        (state, action) => {
          state.sendScheduleReminderDateFormLoading = false;
          state.sendScheduleReminderDateFormSuccess = true;
          state.sendScheduleReminderDateFormFailed = false;
          state.sendScheduleReminderDateForm = action.payload || '';
        }
      )

      .addCase(
        sendScheduleReminderDateForSchedule.rejected,
        (state, action) => {
          state.sendScheduleReminderDateFormLoading = false;
          state.sendScheduleReminderDateFormSuccess = false;
          state.sendScheduleReminderDateFormFailed = true;
          state.sendScheduleReminderDateForm =
            initial.sendScheduleReminderDateForm;
          state.sendScheduleReminderDateFormErrorContainer = {
            ...state.sendScheduleReminderDateFormErrorContainer,
            ...action.payload
          };
        }
      )

      .addCase(revertFormScheduleReminder, state => {
        state.scheduleReminderUserFormLoading =
          initial.scheduleReminderUserFormLoading;
        state.scheduleReminderUserFormSuccess =
          initial.scheduleReminderUserFormSuccess;
        state.scheduleReminderUserFormFailed =
          initial.scheduleReminderUserFormFailed;
        state.scheduleReminderUserForm = initial.scheduleReminderUserForm;
        state.scheduleReminderUserFormErrorContainer =
          initial.scheduleReminderUserFormErrorContainer;

        state.scheduleReminderUserGroupFormLoading =
          initial.scheduleReminderUserGroupFormLoading;
        state.scheduleReminderUserGroupFormSuccess =
          initial.scheduleReminderUserGroupFormSuccess;
        state.scheduleReminderUserGroupFormFailed =
          initial.scheduleReminderUserGroupFormFailed;
        state.scheduleReminderUserGroupForm =
          initial.scheduleReminderUserGroupForm;
        state.scheduleReminderUserGroupFormErrorContainer =
          initial.scheduleReminderUserGroupFormErrorContainer;

        state.sendScheduleReminderDateFormLoading =
          initial.sendScheduleReminderDateFormLoading;
        state.sendScheduleReminderDateFormSuccess =
          initial.sendScheduleReminderDateFormSuccess;
        state.sendScheduleReminderDateFormFailed =
          initial.sendScheduleReminderDateFormFailed;
        state.sendScheduleReminderDateForm =
          initial.sendScheduleReminderDateForm;
        state.sendScheduleReminderDateFormErrorContainer =
          initial.sendScheduleReminderDateFormErrorContainer;
      })
      .addCase(revertQuestionList, state => {
        state.isQuestionListByIndicatorLoading = false;
        state.isQuestionListByIndicatorSuccess = false;
        state.isQuestionListByIndicatorFailed = false;
        state.questionListByIndicator = [];
        state.questionListByIndicatorErrorContainer =
          initial.questionListByIndicatorErrorContainer;
      })
      .addCase(getQuestionRatingByIndicatorId.pending, state => {
        state.isQuestionListByIndicatorLoading = true;
        state.isQuestionListByIndicatorSuccess = false;
        state.isQuestionListByIndicatorFailed = false;
        state.questionListByIndicator = [];
        state.questionListByIndicatorErrorContainer =
          initial.questionListByIndicatorErrorContainer;
      })
      .addCase(getQuestionRatingByIndicatorId.fulfilled, (state, action) => {
        state.isQuestionListByIndicatorLoading = false;
        state.isQuestionListByIndicatorSuccess = true;
        state.isQuestionListByIndicatorFailed = false;
        state.questionListByIndicator =
          action.payload?.dataList?.questionWiseResponses || [];
      })

      .addCase(getQuestionRatingByIndicatorId.rejected, (state, action) => {
        state.isQuestionListByIndicatorLoading = false;
        state.isQuestionListByIndicatorSuccess = false;
        state.isQuestionListByIndicatorFailed = true;
        state.questionListByIndicator = initial.questionListByIndicator;
        state.questionListByIndicatorErrorContainer = {
          ...state.questionListByIndicatorErrorContainer,
          ...action.payload
        };
      })
      .addCase(getFlowsCount.pending, state => {
        state.flowsData.allCount = 0;
        state.flowsData.activeCount = 0;
        state.flowsData.inActiveCount = 0;
      })
      .addCase(getFlowsCount.fulfilled, (state, action) => {
        // console.log(action.payload, 'payload');
        state.flowsData = {
          ...state.flowsData,
          allCount: action.payload?.allCount,
          activeCount: action.payload?.activeCount,
          inActiveCount: action.payload?.inActiveCount
        };
      })
      .addCase(getFlowsCount.rejected, (state, action) => {
        state.flowsData.allCount = 0;
        state.flowsData.activeCount = 0;
        state.flowsData.inActiveCount = 0;
      })
      .addCase(updateFormSequence.pending, state => {
        state.isFormSequenceUpdateLoading = true;
        state.isFormSequenceUpdateSuccess = false;
        state.isFormSequenceUpdateFailed = false;
        state.formSequenceUpdateErrorContainer = {
          ...initial.formSequenceUpdateErrorContainer
        };
      })
      .addCase(updateFormSequence.fulfilled, (state, action) => {
        state.isFormSequenceUpdateLoading = false;
        state.isFormSequenceUpdateSuccess = true;
        state.isFormSequenceUpdateFailed = false;
        state.formSequenceUpdateErrorContainer = {
          ...initial.formSequenceUpdateErrorContainer
        };
      })
      .addCase(updateFormSequence.rejected, (state, action) => {
        state.isFormSequenceUpdateLoading = false;
        state.isFormSequenceUpdateSuccess = false;
        state.isFormSequenceUpdateFailed = true;
        state.formSequenceUpdateErrorContainer = {
          ...state.formSequenceUpdateErrorContainer,
          ...action.payload
        };
      });
  }
});

export default flowsSlice.reducer;
