import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  Link,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomDataTable from '../../components/CustomDataTable';
import { LoadingButton } from '@mui/lab';
import {
  colorPrimary,
  colorMidGray,
  colorLightYellow,
  colorTextGrey,
  colorLinkGrey,
  colorTextBlack,
  colorBorderGrey
} from '../../config/theme';
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  generateImageSrc,
  handleError,
  isArrayNotEmpty
} from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { Add, Close, MoreVert } from '@mui/icons-material';
import {
  deleteUsersOfGroup,
  getUsersOfGroup,
  reverUserGroupFormData,
  revertAllUserGroupsData
} from '../../redux/reducers/userGroupsSlice';
import ConfirmBox from '../../components/ConfirmBox';
import AddUserInGroupForm from './AddUserInGroupForm';
import SnackBarBox from '../../components/SnackBarBox';
import BackButton from '../../components/BackButton';
import CustomBreadcrumb from '../../components/Breadcrumb';
import { ChevronRightIcon, EditIcon, TrashIcon } from '../../utils/iconSvg';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

function AddUserGroups() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [initialValues, setInitalValues] = useState({
    tabValue: 0,
    page: 0,
    size: 10,
    searchFilter: ''
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { authData } = useSelector(state => state.auth);
  const [groupName, setgroupName] = useState('');
  const [selectedUser, setSelectedUsers] = useState([]);
  const [confirmation, setConfimation] = useState(null);
  const [addEditValues, setAddEditValues] = useState(null);
  const {
    isUsersOfGroupsLoading,
    isUsersOfGroupsSuccess,
    usersOfGroupData,
    isDeleteUsersOfGroupsLoading,
    isDeleteUsersOfGroupsSuccess,
    isDeleteUsersOfGroupsFailed,
    deleteUsersOfGroupsErrorContainer
  } = useSelector(state => state.userGroups);
  const [loading, setIsLoading] = useState(true);
  const [showError, setShowError] = useState(null);

  useEffect(() => {
    if (location.state && location.state.userGroupId !== null) {
      setgroupName(location.state.groupName);
      dispatch(
        getUsersOfGroup({
          groupId: location.state.userGroupId,
          payload: {
            page: initialValues.page,
            size: initialValues.size,
            type:
              initialValues.tabValue === 2
                ? true
                : initialValues.tabValue === 1
                  ? false
                  : 'all'
          }
        })
      );
    } else {
      setIsLoading(false);
    }

    return () => {
      dispatch(reverUserGroupFormData());
      dispatch(revertAllUserGroupsData());
    };
  }, [initialValues]);

  useEffect(() => {
    if (isUsersOfGroupsSuccess) {
      setIsLoading(false);
    }
  }, [isUsersOfGroupsSuccess]);

  useEffect(() => {
    if (isDeleteUsersOfGroupsSuccess) {
      return handleReset();
    }
  }, [isDeleteUsersOfGroupsSuccess]);

  const columns = [
    {
      uinqueKey: 'userId',
      id: 'name',
      label: 'Name',
      width: '220px',
      sortable: true,
      render: row => (
        <>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {row.userImageUrl ? (
              <Avatar
                src={row.userImageUrl}
                alt={row.name}
                sx={{ width: 32, height: 32, marginRight: 1 }}
              />
            ) : (
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  backgroundColor: '#c9c9c9',
                  color: 'white',
                  fontSize: '12px',
                  marginRight: '5px'
                }}
              >
                {row ? row.name?.split(' ')[0]?.charAt(0).toUpperCase() : ''}
              </Avatar>
            )}

            <Typography variant="body2" fontWeight={'600'}>
              {row.name}
            </Typography>
          </div>
        </>
      )
    },
    {
      id: 'contactNumber',
      label: 'Phone'
    },
    {
      id: 'email',
      label: 'Email'
    },
    {
      id: 'role',
      label: 'Role',
      sortable: true
    },
    {
      id: 'userType',
      label: 'Type',
      sortable: true
    },
    {
      id: 'status',
      label: 'Status',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              fontSize: 12,
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '4px 8px 4px 8px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: row.status ? '#749E35' : '#D62828',
              width: row.status ? '62px' : '70px',
              height: '22px'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    },
    {
      id: 'state',
      label: 'State'
    },
    {
      id: 'district',
      label: 'District'
    },
    {
      id: 'area',
      label: 'Area'
    },
    {
      id: 'school',
      label: 'School'
    }
  ];

  const debouncedSearch = _.debounce(
    term => setInitalValues({ ...initialValues, searchFilter: term }),
    300
  );

  // const handleSearch = e => {
  //   const term = e.target.value;
  //   if (term.length >= 3 || term.length === 0) {
  //     debouncedSearch(term);
  //   }
  // };

  const handleMenuActions = action => {
    setShowError(null);
    if (action === 'Delete') {
      handleDeleteUserOfGroup();
    }
  };

  const deleteUserFromGroup = () => {
    let payload = {
      userGroupId: location.state.userGroupId,
      groupName: location.state.groupName,
      userIdsToAdd: [],
      userIdsToRemove: [...selectedUser],
      loggedInUserName: authData.userName
    };

    dispatch(deleteUsersOfGroup(payload));
  };

  const handleDeleteUserOfGroup = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(usersOfGroupData?.usersOfGroupList)) {
      if (isArrayNotEmpty(selectedUser)) {
        setConfimation(true);
      } else {
        errorMessage = 'Please select user[s] to delete.';
      }
    }
    setShowError(errorMessage);
  };

  const handleReset = () => {
    setSelectedUsers([]);
    setShowError(null);
    setConfimation(null);
    setInitalValues({ tabValue: 0, page: 0, size: 10, searchFilter: '' });
    setAnchorEl(null);
  };

  return (
    <Box>
      <Grid display={'flex'} justifyContent={'space-between'}>
        <Grid>
          {/* <Typography fontSize={18} fontWeight="medium" marginBottom={1}>
              {location.pathname === '/user-groups/edit-user-group'
                ? 'Edit'
                : 'New'}{' '}
              user group
            </Typography> */}
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={
              <SvgIcon sx={{ height: 24, width: 24 }}>
                <ChevronRightIcon color={colorTextGrey} />
              </SvgIcon>
            }
            sx={{ marginBottom: 2 }}
          >
            <Link
              color="inherit"
              href={'school'}
              onClick={event => {
                event.preventDefault();
                navigate('/user-groups');
              }}
              sx={{ textDecoration: 'none' }}
            >
              <Typography
                fontWeight={'700'}
                color={colorLinkGrey}
                fontSize={'24px'}
              >
                User Groups
              </Typography>
            </Link>
            <Typography
              sx={{
                fontSize: '32px',
                fontWeight: '700',
                color: colorTextBlack
              }}
            >
              {`${location.pathname === '/user-groups/edit-user-group' ? 'Edit' : 'Add new'} user group`}
            </Typography>
          </Breadcrumbs>
          {/* <CustomBreadcrumb path={`${location.pathname}`} /> */}
        </Grid>
        {/* 
        <BackButton text="Back to Listing" path="/user-groups" /> */}
      </Grid>

      {loading && (
        <Skeleton
          variant="rounded"
          width={'100%'}
          height={400}
          animation="wave"
        />
      )}

      {!loading && (
        <>
          <Grid container display={'flex'} flexDirection={'column'}>
            <Box bgcolor={'white'} padding={2} borderRadius={2}>
              <Grid item mb={2}>
                <InputLabel
                  required
                  sx={{ fontWeight: '700', color: colorTextBlack, mb: 0.2 }}
                >
                  User group name
                </InputLabel>
                <TextField
                  sx={{ width: '30%' }}
                  size="small"
                  value={groupName}
                  onChange={e => {
                    setgroupName(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <EditIcon color={colorBorderGrey} />
                      </InputAdornment>
                    )
                  }}
                />

                <Button
                  sx={{
                    height: 40,
                    marginLeft: 1,
                    boxShadow: 'none'
                  }}
                  // variant="outlined"
                  variant="contained"
                  onClick={() => {
                    if (groupName !== '') {
                      if (
                        location.state &&
                        location.state.userGroupId !== null
                      ) {
                        setAddEditValues(location.state);
                      } else {
                        setAddEditValues(true);
                      }
                    } else {
                      setShowError(
                        'Please enter group name before proceeding.'
                      );
                    }
                  }}
                >
                  <SvgIcon
                    fontSize="small"
                    sx={{ marginRight: 0.6, height: '16px', width: '16px' }}
                  >
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.49996 2.66669V13.3334M3.16663 8.00002H13.8333"
                        stroke="#1F2933"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SvgIcon>
                  Add user to group
                </Button>
              </Grid>
            </Box>

            <Grid
              container
              display={'flex'}
              flexDirection={'column'}
              elevation={0}
              component={Paper}
            >
              <Grid
                display={'flex'}
                justifyContent={'flex-end'}
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  paddingX: '10px'
                }}
              >
                {/* <Tabs
                  value={initialValues.tabValue}
                  onChange={(val, newValue) =>
                    setInitalValues({ ...initialValues, tabValue: newValue })
                  }
                  aria-label="user tab selection"
                >
                  <Tab
                    color={
                      initialValues.tabValue === 0 ? colorPrimary : '#ABB4BD'
                    }
                    sx={{
                      fontWeight: initialValues.tabValue === 0? '700' : '600'
                    }}
                    label="All users"
                  />
                  <Tab
                    color={
                      initialValues.tabValue === 1 ? colorPrimary : '#ABB4BD'
                    }
                    sx={{
                      fontWeight: initialValues.tabValue === 1? '700' : '600'
                    }}
                    label="PAF users"
                  />
                  <Tab
                    color={
                      initialValues.tabValue === 2 ? colorPrimary : '#ABB4BD'
                    }
                    sx={{
                      fontWeight: initialValues.tabValue === 2? '700' : '600'
                    }}
                    label="Registered users"
                  />
                </Tabs> */}
                <div style={{ display: 'flex', margin: 'auto 10px' }}>
                  <Tooltip title="More options">
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={event => setAnchorEl(event.currentTarget)}
                    >
                      <MoreVert />
                    </IconButton>
                  </Tooltip>

                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button'
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => {
                      setAnchorEl(null);
                      setShowError(null);
                    }}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        width: '20ch',
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1
                        },
                        '&::before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0
                        }
                      }
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  >
                    {['Delete'].map(option => (
                      <MenuItem
                        key={option}
                        onClick={() => {
                          handleMenuActions(option);
                        }}
                      >
                        <ListItemIcon>
                          {option === 'Delete' ? (
                            <>
                              <SvgIcon sx={{ height: 20, width: 20 }}>
                                <TrashIcon color="red" />
                              </SvgIcon>
                            </>
                          ) : (
                            ''
                          )}
                        </ListItemIcon>
                        {option === 'Delete' ? 'Delete user' : ''}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </Grid>
              <Grid sx={{ padding: '10px 10px' }} width={'100%'}>
                {isUsersOfGroupsLoading && (
                  <Skeleton
                    variant="rounded"
                    width={'100%'}
                    height={400}
                    animation="wave"
                  />
                )}

                {(!isUsersOfGroupsLoading || !isDeleteUsersOfGroupsLoading) && (
                  <CustomDataTable
                    allowSelection={true}
                    columns={columns}
                    data={
                      usersOfGroupData.usersOfGroupList.length > 0
                        ? usersOfGroupData.usersOfGroupList
                        : []
                    }
                    onPageChange={val => {
                      setInitalValues({ ...initialValues, page: val });
                    }}
                    onRowsPerPageChange={val => {
                      setInitalValues({ ...initialValues, size: val });
                    }}
                    defaultPage={initialValues.page}
                    onSelectionChange={val => {
                      setSelectedUsers(val);
                    }}
                    selectedRows={[...selectedUser]}
                    defaultRowsPerPage={initialValues.size}
                    totalCount={usersOfGroupData['totalCount']}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <ConfirmBox
        isOpen={confirmation}
        title="Delete User from this Group!"
        subtitle={`${selectedUser?.length} user[s] from this group are selected, Are you sure you want to delete. `}
        handleClose={() => setConfimation(null)}
        handleSubmit={() => {
          deleteUserFromGroup();
        }}
      />
      {showError && <SnackBarBox type={'error'} message={showError} />}

      {!isDeleteUsersOfGroupsLoading && isDeleteUsersOfGroupsSuccess && (
        <SnackBarBox
          type="success"
          message={'User[s] deleted successfully. '}
        />
      )}

      {!isDeleteUsersOfGroupsLoading && isDeleteUsersOfGroupsFailed && (
        <SnackBarBox
          type="error"
          message={handleError(deleteUsersOfGroupsErrorContainer?.errorMessage)}
        />
      )}

      <BootstrapDialog
        maxWidth="md"
        fullWidth
        open={addEditValues !== null}
        onClose={() => {
          setAddEditValues(null);
        }}
        PaperProps={{
          component: 'form'
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, backgroundColor: 'white' }}
          id="customized-dialog-title"
        >
          {addEditValues && addEditValues == true ? 'Add ' : 'Edit '} User
          Groups
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setAddEditValues(null);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <AddUserInGroupForm
            formValues={addEditValues}
            existingUsers={
              usersOfGroupData.usersOfGroupList
                ? usersOfGroupData.usersOfGroupList.map(cc => cc.userId)
                : []
            }
            groupName={groupName}
            onBackClick={() => {
              setAddEditValues(null);
            }}
          />
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}

export default AddUserGroups;
