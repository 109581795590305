import {
  Add,
  CheckCircleOutline,
  MoreVert,
  NotInterested
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  SvgIcon,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import {
  colorLightYellow,
  colorMidGray,
  colorPrimary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTitle,
  findModuleIdByName,
  handleError,
  isArrayNotEmpty
} from '../../utils/utils';
import {
  activateDeactivateRoles,
  deleteRoles,
  getRoles,
  getRolesCount,
  revertRolePageData
} from '../../redux/reducers/roleSlice';
import { setActiveModule } from '../../redux/reducers/layoutSlice';
import _ from 'lodash';
import { isEmptyArray } from 'formik';
import SearchIcon from '@mui/icons-material/Search';
import CustomDataTable from '../../components/CustomDataTable';
import ConfirmBox from '../../components/ConfirmBox';
import SnackBarBox from '../../components/SnackBarBox';
import moment from 'moment';
import { TrashIcon } from '../../utils/iconSvg';
import CustomTabLabel from '../../custom/CustomTabLabel';

let searchValue = '';
const Container = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'block'
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',

  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: searchValue ? '20ch' : '0px',
    [theme.breakpoints.up('sm')]: {
      '&:focus': {
        width: searchValue ? '20ch' : '20ch'
      }
    }
  }
}));

function RolesAccess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [initialValues, setInitalValues] = useState({
    tabValue: 0,
    page: 0,
    size: 10,
    searchFilter: ''
  });
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [confirmation, setConfimation] = useState(null);
  const [showError, setShowError] = useState(null);

  const { authData } = useSelector(state => state.auth);
  const { modules, isModulesSuccess } = useSelector(state => state.layout);
  const {
    isRolesLoading,
    isRolesSuccess,
    isRolesFailed,
    rolesData,
    rolesErrorContainer,
    isActivateDeactivateRolesSuccess,
    isActivateDeactivateRolesFailed,
    activateDeactivateRolesResponse,
    activateDeactivateRolesErrorContainer,
    isDeleteRolesSuccess,
    isDeleteRolesFailed,
    deleteRolesErrorContainer
  } = useSelector(state => state.roles);

  useEffect(() => {
    document.title = createTitle('Roles & App Access');
    dispatch(setActiveModule('Roles and App Access'));
    dispatch(getRolesCount());
    return () => {
      dispatch(revertRolePageData());
    };
  }, []);

  useEffect(() => {
    // dispatch(revertRolePageData());
    dispatch(
      getRoles({
        type:
          initialValues.tabValue === 1
            ? true
            : initialValues.tabValue === 2
              ? false
              : 'all',
        page: initialValues.page,
        size: initialValues.size,
        search: initialValues.searchFilter
      })
    );
  }, [initialValues]);

  const debouncedSearch = _.debounce(
    term => setInitalValues({ ...initialValues, searchFilter: term }),
    300
  );

  const handleSearch = e => {
    const term = e.target.value;

    if (term.length >= 3 || term.length === 0) {
      debouncedSearch(term);
    }
  };

  const columns = [
    {
      uinqueKey: 'roleId',
      id: 'parentRole',
      label: 'Parent Role',
      sortable: true,
      width: '150px'
    },
    {
      id: 'roleName',
      label: 'Role Name',
      sortable: true,
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.roleName}
          </Typography>
        );
      }
    },
    {
      id: 'roleLevel',
      label: 'Role Level',
      sortable: true
    },
    {
      id: 'users',
      label: 'Users'
    },
    {
      id: 'status',
      label: 'Status',
      width: '150px',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              fontSize: 12,
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '4px 8px 4px 8px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: row.status ? '#749E35' : '#D62828',
              width: row.status ? '62px' : '70px',
              height: '22px'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    },
    {
      id: 'createdBy',
      label: 'Created By'
    },
    {
      id: 'createdDt',
      label: 'Created On',
      width: '150px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'}>
                {row.createdDt
                  ? moment(row.createdDt).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    }
  ];

  const handleMenuActions = action => {
    setShowError(null);
    if (action === 'Delete') {
      handleDeleteRole();
    } else if (action === 'De-Activate') {
      handleDeActivateRole();
    } else if (action === 'Activate') {
      handleActivateRole();
    }
  };

  const handleDeleteRole = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(rolesData?.roles)) {
      if (isArrayNotEmpty(selectedRoles)) {
        setConfimation(true);
      } else {
        errorMessage = 'Please select role[s] to delete.';
      }
    }
    setShowError(errorMessage);
  };

  const handleActivateRole = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(rolesData?.roles)) {
      if (!isArrayNotEmpty(selectedRoles)) {
        errorMessage = 'Please select role[s] to activate.';
      } else {
        const selectedRolesData = rolesData?.roles.filter(user =>
          selectedRoles.includes(user.roleId)
        );
        const allRoleActive = selectedRolesData.every(user => !user.status);
        if (!allRoleActive) {
          errorMessage = 'Please select only inactive role[s] to activate.';
        } else {
          let payload = {
            isActivate: true,
            ids: [...selectedRoles],
            loggedInUserName: authData?.userName
          };

          dispatch(activateDeactivateRoles(payload));
        }
      }
    }
    setShowError(errorMessage);
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(revertRolePageData());
    }, 1300);

    if (isActivateDeactivateRolesSuccess || isDeleteRolesSuccess) {
      handleReset();
    }
  }, [
    isActivateDeactivateRolesSuccess,
    isDeleteRolesSuccess,
    isActivateDeactivateRolesFailed,
    isDeleteRolesFailed
  ]);

  const handleDeActivateRole = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(rolesData?.roles)) {
      if (!isArrayNotEmpty(selectedRoles)) {
        errorMessage = 'Please select role[s] to De-Activate.';
      } else {
        const selectedRolesData = rolesData?.roles.filter(user =>
          selectedRoles.includes(user.roleId)
        );
        const allRoleActive = selectedRolesData.every(user => user.status);
        if (!allRoleActive) {
          errorMessage = 'Please select only active role[s] to De-Activate.';
        } else {
          let payload = {
            isActivate: false,
            ids: [...selectedRoles],
            loggedInUserName: authData?.userName
          };

          dispatch(activateDeactivateRoles(payload));
        }
      }
    }
    setShowError(errorMessage);
  };

  const deleteRole = () => {
    let deletePayload = {
      loggedInUserName: authData?.userName,
      ids: [...selectedRoles]
    };
    dispatch(deleteRoles(deletePayload));
  };

  const handleReset = () => {
    setSelectedRoles([]);
    setShowError(null);
    setConfimation(null);
    setInitalValues({ tabValue: 0, page: 0, size: 10, searchFilter: '' });
    setAnchorEl(null);
    dispatch(getRolesCount());
  };

  const handleOnRowClick = row => {
    return navigate(`edit-role`, {
      state: { roleId: row.roleId }
    });
  };

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} mb={1}>
        <Typography fontSize={32} fontWeight="700" display={'flex'}>
          Roles & App Access{' '}
          {/* <Typography
            ml={1}
            fontSize={20}
            fontWeight="700"
            alignSelf={'center'}
          >
            ({rolesData?.totalCount})
          </Typography> */}
        </Typography>

        <Grid>
          <Button
            sx={{
              width: '140px',
              height: '42px',
              boxShadow: 'none',
              fontWeight: '700',
              fontSize: '14px'
            }}
            variant="contained"
            onClick={() => {
              navigate('new-role');
            }}
          >
            <SvgIcon
              fontSize="small"
              sx={{ marginRight: 0.6, height: '16px', width: '16px' }}
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49996 2.66669V13.3334M3.16663 8.00002H13.8333"
                  stroke="#1F2933"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>{' '}
            Add role
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        component={Paper}
        borderRadius={'8px'}
        sx={{ paddingX: '14px', pt: 1 }}
      >
        <Grid
          display={'flex'}
          justifyContent={'space-between'}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            marginTop: 1
          }}
        >
          <Tabs
            value={initialValues.tabValue}
            onChange={(val, newValue) => {
              setInitalValues({
                ...initialValues,
                tabValue: newValue,
                page: 0
              });
              setSelectedRoles([]);
            }}
            TabIndicatorProps={{
              sx: {
                height: '4px',
                borderRadius: '4px'
              }
            }}
          >
            <Tab
              sx={{
                fontSize: '16px',
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 0 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 0
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="All Roles"
                  count={
                    rolesData?.allCount < 10
                      ? `0${rolesData?.allCount}`
                      : rolesData?.allCount
                  }
                  selected={initialValues.tabValue === 0}
                />
              }
            />
            <Tab
              sx={{
                fontSize: '16px',
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 1 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 1
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="Active Roles"
                  count={
                    rolesData?.activeCount < 10
                      ? `0${rolesData?.activeCount}`
                      : rolesData?.activeCount
                  }
                  selected={initialValues.tabValue === 1}
                />
              }
            />
            <Tab
              sx={{
                fontSize: '16px',
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 2 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 2
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="Inactive Roles"
                  count={
                    rolesData?.inActiveCount < 10
                      ? `0${rolesData?.inActiveCount}`
                      : rolesData?.inActiveCount
                  }
                  selected={initialValues.tabValue === 2}
                />
              }
            />
          </Tabs>

          <div style={{ display: 'flex', margin: 'auto 10px' }}>
            <Search>
              <SearchIconWrapper>
                {/* <SearchIcon sx={{ color: colorMidGray }} /> */}
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 17.5L14.375 13.875M16.3333 9.16667C16.3333 12.8486 13.3486 15.8333 9.66667 15.8333C5.98477 15.8333 3 12.8486 3 9.16667C3 5.48477 5.98477 2.5 9.66667 2.5C13.3486 2.5 16.3333 5.48477 16.3333 9.16667Z"
                    stroke="#ABB4BD"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SearchIconWrapper>
              <StyledInputBase
                onChange={e => {
                  searchValue = e.target.value;
                  handleSearch(e);
                }}
                placeholder="Search roles here…"
              />
            </Search>
            <Tooltip title="More options">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                <MoreVert />
              </IconButton>
            </Tooltip>

            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => {
                setAnchorEl(null);
                setShowError(null);
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  width: '20ch',
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {['Activate', 'De-Activate', 'Delete'].map(option => (
                <MenuItem
                  key={option}
                  disabled={
                    (initialValues.tabValue === 1 && option === 'Activate') ||
                    (initialValues.tabValue === 2 && option === 'De-Activate')
                  }
                  onClick={() => {
                    handleMenuActions(option);
                  }}
                >
                  <ListItemIcon>
                    {option === 'Delete' ? (
                      <>
                        <SvgIcon sx={{ height: 20, width: 20 }}>
                          <TrashIcon color="red" />
                        </SvgIcon>
                      </>
                    ) : option === 'De-Activate' ? (
                      <SvgIcon
                        sx={{ height: 20, width: 20, color: 'orangered' }}
                      >
                        <NotInterested />
                      </SvgIcon>
                    ) : option === 'Activate' ? (
                      <SvgIcon sx={{ height: 20, width: 20, color: 'green' }}>
                        <CheckCircleOutline />
                      </SvgIcon>
                    ) : (
                      ''
                    )}
                  </ListItemIcon>
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Grid>
        <Grid sx={{ padding: '18px 0px' }} width={'100%'}>
          {isRolesLoading && (
            <Skeleton
              variant="rounded"
              width={'100%'}
              height={400}
              animation="wave"
            />
          )}

          {!isRolesLoading &&
            (isActivateDeactivateRolesSuccess ||
              isDeleteRolesSuccess ||
              isRolesSuccess) && (
              <CustomDataTable
                allowSelection={true}
                columns={columns}
                defaultOrderBy={'createdDt'}
                data={rolesData?.roles?.length > 0 ? rolesData?.roles : []}
                onPageChange={val => {
                  setInitalValues({ ...initialValues, page: val });
                }}
                onRowsPerPageChange={val => {
                  setInitalValues({ ...initialValues, size: val });
                }}
                defaultPage={initialValues.page}
                selectedRows={[...selectedRoles]}
                onSelectionChange={val => {
                  setSelectedRoles(val);
                }}
                defaultRowsPerPage={initialValues.size}
                totalCount={rolesData?.totalCount}
                onRowClick={row => handleOnRowClick(row)}
              />
            )}
        </Grid>

        <ConfirmBox
          isOpen={confirmation}
          title="Delete role!"
          subtitle={`${selectedRoles.length} role[s] are selected, Are you sure you want to delete. `}
          handleClose={() => setConfimation(null)}
          handleSubmit={() => {
            deleteRole();
          }}
        />

        {showError && <SnackBarBox type={'error'} message={showError} />}

        {!isRolesLoading && isRolesFailed && (
          <SnackBarBox
            type="error"
            message={handleError(rolesErrorContainer?.errorMessage)}
          />
        )}

        {!isRolesLoading && isDeleteRolesSuccess && (
          <SnackBarBox
            type="success"
            message={'roles[s] deleted successfully. '}
          />
        )}

        {!isRolesLoading && isDeleteRolesFailed && (
          <SnackBarBox
            type="error"
            message={handleError(deleteRolesErrorContainer?.errorMessage)}
          />
        )}

        {!isRolesLoading && isActivateDeactivateRolesSuccess && (
          <SnackBarBox
            type="success"
            message={activateDeactivateRolesResponse}
          />
        )}

        {!isRolesLoading && isActivateDeactivateRolesFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              activateDeactivateRolesErrorContainer?.errorMessage
            )}
          />
        )}
      </Grid>
    </>
  );
}

export default RolesAccess;
