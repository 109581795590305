import {
  Avatar,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  SvgIcon,
  styled,
  InputBase,
  Button,
  Tooltip
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import {
  createTitle,
  findModuleIdByName,
  handleError,
  isArrayNotEmpty,
  mapArrayToTypography
} from '../../utils/utils';
import {
  colorLightYellow,
  colorMidGray,
  colorPrimary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import CustomDataTable from '../../components/CustomDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModule } from '../../redux/reducers/layoutSlice';
import { isEmptyArray } from 'formik';
import {
  Add,
  Download,
  MoreVert,
  PersonOffOutlined,
  PersonOutline,
  Upload
} from '@mui/icons-material';
import SnackBarBox from '../../components/SnackBarBox';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import ConfirmBox from '../../components/ConfirmBox';
import {
  activateUserGroups,
  deactivateUserGroups,
  deleteUserGroups,
  downloadUserGroupsCSV,
  getUserGroupsCount,
  getUserGroupsList,
  revertAllUserGroupsData,
  uploadUserGroupsCSV
} from '../../redux/reducers/userGroupsSlice';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  DownloadIcon,
  DownloadUserIcon,
  TrashIcon,
  UploadIcon,
  UploadUserIcon,
  UserGroupCheckIcon,
  UserGroupIcon,
  UserGroupRemoveIcon
} from '../../utils/iconSvg';
import CustomTabLabel from '../../custom/CustomTabLabel';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

let searchValue = '';
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',

  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: searchValue ? '20ch' : '0px',
    [theme.breakpoints.up('sm')]: {
      '&:focus': {
        width: searchValue ? '20ch' : '20ch'
      }
    }
  }
}));

const UserGroupsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [initialValues, setInitalValues] = useState({
    tabValue: 0,
    page: 0,
    size: 10,
    searchFilter: ''
  });
  const [confirmation, setConfimation] = useState(null);
  const [showError, setShowError] = useState(null);
  const [imageUploadError, setImageUploadError] = useState({
    error: false,
    errorMessage: ''
  });
  const { modules, isModulesSuccess } = useSelector(state => state.layout);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const { authData } = useSelector(state => state.auth);
  const {
    isUserGroupsListLoading,
    isUserGroupsListSuccess,
    userGroupsData,
    isUploadUserGroupsLoading,
    isUploadUserGroupsSuccess,
    isUploadUserGroupsFailed,
    uploadUserGroupsErrorContainer,
    uploadUserGroupsMessage,
    isDownloadUserGroupsLoading,
    isDownloadUserGroupsFailed,
    downloadUserGroupsErrorContainer,
    isActivateUserGroupsSuccess,
    isActivateUserGroupsLoading,
    isActivateUserGroupsFailed,
    activateUserGroupsErrorContainer,
    isDeActivateUserGroupsSuccess,
    isDeActivateUserGroupsLoading,
    isDeActivateUserGroupsFailed,
    deactivateUserGroupsErrorContainer,
    isDeleteUserGroupsSuccess,
    isDeleteUserGroupsLoading,
    isDeleteUserGroupsFailed,
    deleteUserGroupsErrorContainer
  } = useSelector(state => state.userGroups);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  //Add new group

  useEffect(() => {
    document.title = createTitle('User Groups');
    dispatch(setActiveModule('User Groups'));
    dispatch(getUserGroupsCount());
    return () => dispatch(revertAllUserGroupsData());
  }, []);

  useEffect(() => {
    dispatch(
      getUserGroupsList({
        payload: {
          type:
            initialValues.tabValue === 1
              ? true
              : initialValues.tabValue === 2
                ? false
                : 'all',
          page: initialValues.page,
          size: initialValues.size
        },
        search: initialValues.searchFilter
      })
    );
  }, [initialValues]);

  useEffect(() => {
    if (
      isActivateUserGroupsSuccess ||
      isDeleteUserGroupsSuccess ||
      isDeActivateUserGroupsSuccess ||
      isUploadUserGroupsSuccess
    ) {
      return handleReset();
    }
  }, [
    isActivateUserGroupsSuccess,
    isDeleteUserGroupsSuccess,
    isDeActivateUserGroupsSuccess,
    isUploadUserGroupsSuccess
  ]);

  const handleSearch = e => {
    const term = e.target.value;
    if (term.length >= 3 || term.length === 0) {
      debouncedSearch(term);
    }
  };

  const handleMenuActions = action => {
    setShowError(null);
    if (action === 'Delete') {
      handleDeleteUserGroup();
    } else if (action === 'De-Activate') {
      handleDeActivateUserGroup();
    } else if (action === 'Activate') {
      handleActivateUserGroup();
    }
  };

  const handleDeleteUserGroup = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(userGroupsData?.userGroupsList)) {
      if (isArrayNotEmpty(selectedUserGroups)) {
        setConfimation(true);
      } else {
        errorMessage = 'Please select user group[s] to delete.';
      }
    }
    setShowError(errorMessage);
  };

  const handleActivateUserGroup = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(userGroupsData?.userGroupsList)) {
      if (!isArrayNotEmpty(selectedUserGroups)) {
        errorMessage = 'Please select user group[s] to activate.';
      } else {
        const selectedUserGroupsData = userGroupsData?.userGroupsList.filter(
          user => selectedUserGroups.includes(user.userGroupId)
        );
        const allUserGroupsActive = selectedUserGroupsData.every(
          user => !user.status
        );
        if (!allUserGroupsActive) {
          errorMessage =
            'Please select only inactive user group[s] to activate.';
        } else {
          let payload = {
            isActivate: true,
            ids: [...selectedUserGroups],
            loggedInUserName: authData?.userName
          };

          dispatch(activateUserGroups(payload));
        }
      }
    }
    setShowError(errorMessage);
  };

  const handleDeActivateUserGroup = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(userGroupsData?.userGroupsList)) {
      if (!isArrayNotEmpty(selectedUserGroups)) {
        errorMessage = 'Please select user group[s] to De-Activate.';
      } else {
        const selectedUserGroupsData = userGroupsData?.userGroupsList.filter(
          user => selectedUserGroups.includes(user.userGroupId)
        );
        const allUserGroupsActive = selectedUserGroupsData.every(
          user => user.status
        );
        if (!allUserGroupsActive) {
          errorMessage =
            'Please select only active user group[s] to De-Activate.';
        } else {
          let payload = {
            isActivate: false,
            ids: [...selectedUserGroups],
            loggedInUserName: authData?.userName
          };

          dispatch(deactivateUserGroups(payload));
        }
      }
    }
    setShowError(errorMessage);
  };

  const deleteUserGroup = () => {
    let deletePayload = {
      loggedInUserName: authData?.userName,
      ids: [...selectedUserGroups]
    };
    dispatch(deleteUserGroups(deletePayload));
  };

  const handleReset = () => {
    setSelectedUserGroups([]);
    setShowError(null);
    setConfimation(null);
    setInitalValues({ tabValue: 0, page: 0, size: 10, searchFilter: '' });
    setAnchorEl(null);
    dispatch(getUserGroupsCount());
  };

  const handleImageUpload = event => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = 'text/csv';
      const maxSizeMB = 5;

      if (file.type === allowedTypes) {
        if (file.size / 1024 / 1024 <= maxSizeMB) {
          const uploadFormData = new FormData();

          uploadFormData.append('file', file);
          uploadFormData.append('loggedInUser', authData.userName);
          dispatch(uploadUserGroupsCSV(uploadFormData));
          fileInputRef.current.value = null;
        } else {
          setImageUploadError({
            error: true,
            errorMessage: `File size exceeds the maximum allowed (${maxSizeMB} MB).`
          });
        }
      } else {
        setImageUploadError({
          error: true,
          errorMessage: 'Please upload an csv file format.'
        });
      }
    }
  };

  const debouncedSearch = _.debounce(term => {
    setInitalValues({ ...initialValues, searchFilter: term });
  }, 300);

  const columns = [
    {
      uinqueKey: 'userGroupId',
      id: 'groupName',
      label: 'Group Name',
      width: '220px',
      sortable: true,
      render: data => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'}>
                {data.groupName}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'groupUsers',
      label: 'Group Users',
      sortable: true,
      align: 'center'
    },
    {
      id: 'status',
      label: 'Status',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              fontSize: 12,
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '4px 8px 4px 8px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: row.status ? '#749E35' : '#D62828',
              width: row.status ? '62px' : '70px',
              height: '22px'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    },
    {
      id: 'createdBy',
      label: 'Created By'
    },
    {
      id: 'creationDate',
      label: 'Created On',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'}>
                {row.creationDate
                  ? moment(row.creationDate).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    }
  ];

  const handleOnRowClick = row => {
    return navigate('edit-user-group', { state: row });
  };

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} mb={1}>
        <Typography fontSize={32} fontWeight="700" display={'flex'}>
          Users Groups
          {/* <Typography
            ml={1}
            fontSize={20}
            fontWeight="700"
            alignSelf={'center'}
          >
            ({userGroupsData?.totalCount})
          </Typography> */}
        </Typography>
        <Grid>
          <Tooltip title="Upload user groups ">
            <LoadingButton
              // startIcon={}
              loading={isUploadUserGroupsLoading}
              onClick={() => fileInputRef.current.click()}
              variant="outlined"
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: 'black',
                borderWidth: '2px',
                marginRight: 2,
                height: '42px',
                width: '48px',
                padding: '5px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
            >
              <UploadUserIcon />
              <input
                ref={fileInputRef}
                key={'uploadProfile'}
                type="file"
                style={{ display: 'none' }}
                accept={'.csv'}
                onChange={handleImageUpload}
              />
            </LoadingButton>
          </Tooltip>
          <Tooltip title="Download user groups">
            <LoadingButton
              loading={isDownloadUserGroupsLoading}
              variant="outlined"
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: 'black',
                borderWidth: '2px',
                marginRight: 2,
                height: '42px',
                width: '48px',
                padding: '5px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
              onClick={() => {
                dispatch(
                  downloadUserGroupsCSV({
                    fileName: 'User_Groups_Data.csv',

                    body: {
                      ids: [...selectedUserGroups],
                      paginationRequest: {
                        type:
                          initialValues.tabValue === 1
                            ? true
                            : initialValues.tabValue === 2
                              ? false
                              : 'all',
                        page: 0,
                        size: 0
                      }
                    }
                  })
                );
              }}
            >
              <DownloadUserIcon />
            </LoadingButton>
          </Tooltip>

          <Button
            sx={{
              boxShadow: 'none',
              height: '42px',
              fontWeight: '700',
              fontSize: '14px'
            }}
            variant="contained"
            onClick={() => {
              navigate('new-user-group');
            }}
          >
            <SvgIcon
              fontSize="small"
              sx={{ marginRight: 0.6, height: '16px', width: '16px' }}
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49996 2.66669V13.3334M3.16663 8.00002H13.8333"
                  stroke="#1F2933"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>{' '}
            Add user group
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        component={Paper}
        borderRadius={'8px'}
        sx={{ paddingX: '14px', pt: 1 }}
      >
        <Grid
          display={'flex'}
          justifyContent={'space-between'}
          sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}
        >
          <Tabs
            value={initialValues.tabValue}
            onChange={(val, newValue) =>
              setInitalValues({ ...initialValues, tabValue: newValue, page: 0 })
            }
            TabIndicatorProps={{
              sx: {
                height: '4px',
                borderRadius: '4px'
              }
            }}
          >
            <Tab
              sx={{
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontSize: '16px',
                fontWeight: initialValues.tabValue === 0 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 0
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              icon={
                <SvgIcon sx={{ height: '18px', width: '18px' }}>
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 15.75V14.25C17 12.8521 16.0439 11.6775 14.75 11.3445M12.125 2.46807C13.2244 2.91311 14 3.99098 14 5.25C14 6.50902 13.2244 7.58689 12.125 8.03193M13.25 15.75C13.25 14.3522 13.25 13.6533 13.0216 13.1019C12.7172 12.3669 12.1331 11.7828 11.3981 11.4784C10.8467 11.25 10.1478 11.25 8.75 11.25H6.5C5.10218 11.25 4.40326 11.25 3.85195 11.4784C3.11687 11.7828 2.53284 12.3669 2.22836 13.1019C2 13.6533 2 14.3522 2 15.75M10.625 5.25C10.625 6.90685 9.28185 8.25 7.625 8.25C5.96815 8.25 4.625 6.90685 4.625 5.25C4.625 3.59315 5.96815 2.25 7.625 2.25C9.28185 2.25 10.625 3.59315 10.625 5.25Z"
                      stroke={
                        initialValues.tabValue === 0
                          ? colorTextBlack
                          : colorTextGrey
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>
              }
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="All user groups"
                  count={
                    userGroupsData?.allCount < 10
                      ? `0${userGroupsData?.allCount}`
                      : userGroupsData?.allCount
                  }
                  selected={initialValues.tabValue === 0}
                />
              }
            />
            <Tab
              sx={{
                minHeight: 44,
                height: 44,
                fontSize: '16px',
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 1 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 1
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              icon={
                <SvgIcon sx={{ height: '18px', width: '18px' }}>
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.5 13.5L14 15L17 12M9.5 11.25H6.5C5.10218 11.25 4.40326 11.25 3.85195 11.4784C3.11687 11.7828 2.53284 12.3669 2.22836 13.1019C2 13.6533 2 14.3522 2 15.75M12.125 2.46807C13.2244 2.91311 14 3.99098 14 5.25C14 6.50902 13.2244 7.58689 12.125 8.03193M10.625 5.25C10.625 6.90685 9.28185 8.25 7.625 8.25C5.96815 8.25 4.625 6.90685 4.625 5.25C4.625 3.59315 5.96815 2.25 7.625 2.25C9.28185 2.25 10.625 3.59315 10.625 5.25Z"
                      stroke={
                        initialValues.tabValue === 1
                          ? colorTextBlack
                          : colorTextGrey
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>
              }
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="Active user groups"
                  count={
                    userGroupsData?.activeCount < 10
                      ? `0${userGroupsData?.activeCount}`
                      : userGroupsData?.activeCount
                  }
                  selected={initialValues.tabValue === 1}
                />
              }
            />
            <Tab
              sx={{
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontSize: '16px',
                fontWeight: initialValues.tabValue === 2 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 2
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              icon={
                <SvgIcon sx={{ height: '18px', width: '18px' }}>
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.875 12L16.625 15.75M16.625 12L12.875 15.75M12.125 2.46807C13.2244 2.91311 14 3.99098 14 5.25C14 6.50902 13.2244 7.58689 12.125 8.03193M9.5 11.25H6.5C5.10218 11.25 4.40326 11.25 3.85195 11.4784C3.11687 11.7828 2.53284 12.3669 2.22836 13.1019C2 13.6533 2 14.3522 2 15.75M10.625 5.25C10.625 6.90685 9.28185 8.25 7.625 8.25C5.96815 8.25 4.625 6.90685 4.625 5.25C4.625 3.59315 5.96815 2.25 7.625 2.25C9.28185 2.25 10.625 3.59315 10.625 5.25Z"
                      stroke={
                        initialValues.tabValue === 2
                          ? colorTextBlack
                          : colorTextGrey
                      }
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>
              }
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="Inactive user groups"
                  count={
                    userGroupsData?.inActiveCount < 10
                      ? `0${userGroupsData?.inActiveCount}`
                      : userGroupsData?.inActiveCount
                  }
                  selected={initialValues.tabValue === 2}
                />
              }
            />
          </Tabs>

          <div style={{ display: 'flex', margin: 'auto 10px' }}>
            <Search>
              <SearchIconWrapper>
                {/* <SearchIcon sx={{ color: colorMidGray }} /> */}
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 17.5L14.375 13.875M16.3333 9.16667C16.3333 12.8486 13.3486 15.8333 9.66667 15.8333C5.98477 15.8333 3 12.8486 3 9.16667C3 5.48477 5.98477 2.5 9.66667 2.5C13.3486 2.5 16.3333 5.48477 16.3333 9.16667Z"
                    stroke="#ABB4BD"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SearchIconWrapper>
              <StyledInputBase
                onChange={e => {
                  searchValue = e.target.value;
                  handleSearch(e);
                }}
                placeholder="Search user group here…"
              />
            </Search>
            <Tooltip title="More options">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                <MoreVert />
              </IconButton>
            </Tooltip>

            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => {
                setAnchorEl(null);
                setShowError(null);
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  width: '20ch',
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {['Activate', 'De-Activate', 'Delete'].map(option => (
                <MenuItem
                  key={option}
                  disabled={
                    (initialValues.tabValue === 1 && option === 'Activate') ||
                    (initialValues.tabValue === 2 && option === 'De-Activate')
                  }
                  onClick={() => {
                    handleMenuActions(option);
                  }}
                >
                  <ListItemIcon>
                    {option === 'Delete' ? (
                      <>
                        <SvgIcon sx={{ height: 20, width: 20 }}>
                          <TrashIcon color="red" />
                        </SvgIcon>
                      </>
                    ) : option === 'De-Activate' ? (
                      <SvgIcon sx={{ height: 20, width: 20 }}>
                        <PersonOffOutlined />
                      </SvgIcon>
                    ) : option === 'Activate' ? (
                      <SvgIcon sx={{ height: 20, width: 20 }}>
                        <PersonOutline />
                      </SvgIcon>
                    ) : (
                      ''
                    )}
                  </ListItemIcon>
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Grid>
        <Grid sx={{ padding: '18px 0px' }} width={'100%'}>
          {(isUserGroupsListLoading ||
            isActivateUserGroupsLoading ||
            isDeActivateUserGroupsLoading ||
            isDeleteUserGroupsLoading) && (
            <Skeleton
              variant="rounded"
              width={'100%'}
              height={400}
              animation="wave"
            />
          )}

          {((!isUserGroupsListLoading && isUserGroupsListSuccess) ||
            (!isActivateUserGroupsLoading && isActivateUserGroupsSuccess) ||
            (!isDeActivateUserGroupsLoading && isDeActivateUserGroupsSuccess) ||
            (!isDeleteUserGroupsLoading && isDeleteUserGroupsSuccess)) && (
            <CustomDataTable
              allowSelection={true}
              columns={columns}
              defaultOrderBy={'creationDate'}
              data={
                userGroupsData?.userGroupsList?.length > 0
                  ? userGroupsData?.userGroupsList
                  : []
              }
              onPageChange={val => {
                setInitalValues({ ...initialValues, page: val });
              }}
              onRowsPerPageChange={val => {
                setInitalValues({ ...initialValues, size: val });
              }}
              defaultPage={initialValues.page}
              selectedRows={[...selectedUserGroups]}
              onSelectionChange={val => {
                setSelectedUserGroups(val);
              }}
              defaultRowsPerPage={initialValues.size}
              totalCount={userGroupsData?.totalCount}
              onRowClick={row => handleOnRowClick(row)}
            />
          )}
        </Grid>

        <ConfirmBox
          isOpen={confirmation}
          title="Delete User Group!"
          subtitle={`${selectedUserGroups.length} user group[s] are selected, Are you sure you want to delete. `}
          handleClose={() => setConfimation(null)}
          handleSubmit={() => {
            deleteUserGroup();
          }}
        />

        {showError && <SnackBarBox type={'error'} message={showError} />}

        {!isActivateUserGroupsLoading && isActivateUserGroupsSuccess && (
          <SnackBarBox
            type="success"
            message={'User Group[s] activated successfully. '}
          />
        )}

        {!isActivateUserGroupsLoading && isActivateUserGroupsFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              activateUserGroupsErrorContainer?.errorMessage
            )}
          />
        )}
        {!isDeActivateUserGroupsLoading && isDeActivateUserGroupsSuccess && (
          <SnackBarBox
            type="success"
            message={'User Group[s] de-activated successfully. '}
          />
        )}

        {!isDeActivateUserGroupsLoading && isDeActivateUserGroupsFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              deactivateUserGroupsErrorContainer?.errorMessage
            )}
          />
        )}

        {!isDeleteUserGroupsLoading && isDeleteUserGroupsSuccess && (
          <SnackBarBox
            type="success"
            message={'User Group[s] deleted successfully. '}
          />
        )}

        {!isDeleteUserGroupsLoading && isDeleteUserGroupsFailed && (
          <SnackBarBox
            type="error"
            message={handleError(deleteUserGroupsErrorContainer?.errorMessage)}
          />
        )}

        {!isUploadUserGroupsLoading && isUploadUserGroupsFailed && (
          <SnackBarBox
            type="error"
            // message={handleError(uploadUserGroupsErrorContainer?.errorMessage)}
            message={mapArrayToTypography(
              uploadUserGroupsErrorContainer?.errorMessage
                ? uploadUserGroupsErrorContainer?.errorMessage.split('\n')
                : ''
            )}
          />
        )}

        {!isUploadUserGroupsLoading && isUploadUserGroupsSuccess && (
          <SnackBarBox
            type="success"
            message={handleError(uploadUserGroupsMessage)}
          />
        )}

        {imageUploadError.error && (
          <SnackBarBox
            type="error"
            message={handleError(imageUploadError.errorMessage)}
          />
        )}

        {!isDownloadUserGroupsLoading && isDownloadUserGroupsFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              `Error in user groups download: ${downloadUserGroupsErrorContainer?.errorMessage}`
            )}
          />
        )}
      </Grid>
    </>
  );
};

export default UserGroupsPage;
