import { colorAppBackground, lightTheme } from './config/theme';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate
} from 'react-router-dom';
import NotFound from './pages/NotFound';
import Maintenance from './pages/Maintenance';
import Forbidden from './pages/Forbidden';
import Login from './pages/LoginPage';
import ProtectedRoutes from './components/ProtectedRoutes';
import Dashboard from './pages/Dashboard';
import { useSelector } from 'react-redux';
import Layout from './components/Layout';
import PasswordRecovery from './pages/PasswordRecovery';
import UsersPage from './pages/UserManagement/UsersPage';
import MyAccount from './pages/MyAccount';
import UpdatePassword from './pages/UpdatePassword';
import { ComingSoonComponent } from './components/ComingSoon';
import UserGroupsPage from './pages/UserManagement/UserGroupsPage';
import AddUser from './containers/usermanagement/AddUser';
import AddUserGroups from './containers/usermanagement/AddUserGroups';
import StatePage from './pages/LocationManagement/StatePage';
import DistrictPage from './pages/LocationManagement/DistrictPage';
import AddDistrict from './containers/locationmanagement/AddDistrict';
import { useEffect, useState, createContext, useContext } from 'react';
import Loader from './components/Loader';
import AreaPage from './pages/LocationManagement/AreaPage';
import AddArea from './containers/locationmanagement/AddArea';
import SchoolPage from './pages/LocationManagement/SchoolPage';
import AddEditSchool from './containers/locationmanagement/AddEditSchool';
import { getMenuData } from './config/cookie';
import RolesAccess from './pages/UserManagement/RolesAccess';
import AddEditRoleAccess from './containers/usermanagement/AddEditRoleAccess';
import AreaDetails from './containers/locationmanagement/AreaDetails';
import DistrictDetails from './containers/locationmanagement/DistrictDetails';
import SchoolDetails from './containers/locationmanagement/SchoolDetails';
import AddEditRubrics from './containers/teacherEvaluation/AddEditRubrics';
import RubricPage from './pages/TeacherEvaluation/RubricPage';
import RubricDetails from './containers/teacherEvaluation/RubricDetails';
import AddClassroom from './containers/locationmanagement/AddClassroom';
import Flows from './pages/TeacherEvaluation/Flows';
import ClassroomPage from './pages/LocationManagement/ClassroomPage';
import AddEditFlow from './containers/teacherEvaluation/AddEditFlow';
import AddEditForm from './containers/teacherEvaluation/AddEditForm';
import FlowDetails from './containers/teacherEvaluation/FlowDetails';
import AddObservation from './containers/observation/AddObservation';
import ObservationReports from './pages/ObservationReports';
import EvidenceCard from './containers/observation/EvidenceCard';
import ObservationReportCard from './containers/observation/ObservationReportCard';
import Schedules from './pages/Schedules/Schedules';
import SendReminder from './containers/teacherEvaluation/components/SendReminder';
import PreviewForm from './containers/teacherEvaluation/components/PreviewForm';
import Events from './pages/Schedules/Events';
import ProductAnalysis from './pages/ProductAnalysis';
import UserAndRoleAnalytics from './containers/productanalytics/UserAndRoleAnalytics';
import ShareCharts from './pages/ShareCharts';

import Usage from './components/Usage';
import FAQ from './pages/FAQ';
import ResetPassword from './pages/ResetPassword';
import { ref, onValue, off } from 'firebase/database';
import { database } from './utils/firebase';
import { getNotificaitonsList } from './redux/reducers/layoutSlice';
import { useDispatch } from 'react-redux';

export const privateRoute = [
  {
    path: '/dashboard',
    title: '',
    menu_name: 'dashboard',
    component: <Dashboard />,
    caseSensitive: true
  },
  {
    path: '/users',
    title: 'users',
    menu_name: 'Users',
    // component: <ComingSoonComponent />,
    component: <UsersPage />,
    caseSensitive: true
  },
  {
    path: '/my-account',
    title: '',
    menu_name: 'my account',
    component: <MyAccount />,
    caseSensitive: true
  },
  {
    path: '/users/new-user',
    title: '',
    menu_name: 'Users',
    component: <AddUser />,
    caseSensitive: true
  },
  {
    path: '/users/edit-user',
    title: '',
    menu_name: 'Users',
    component: <AddUser />,
    caseSensitive: true
  },
  {
    path: '/user-groups',
    title: 'User Groups',
    menu_name: 'User Groups',
    component: <UserGroupsPage />,
    caseSensitive: true
  },
  {
    path: '/user-groups/new-user-group',
    title: '',
    menu_name: 'User Groups',
    component: <AddUserGroups />,
    caseSensitive: true
  },
  {
    path: '/user-groups/edit-user-group',
    title: '',
    menu_name: 'User Groups',
    component: <AddUserGroups />,
    caseSensitive: true
  },
  {
    path: '/roles',
    title: '',
    menu_name: 'Roles and App Access',
    component: <RolesAccess />,
    caseSensitive: true
  },
  // {
  //   path: '/roles',
  //   title: '',
  //   menu_name: 14,
  //   component: <RolesAccess />,
  //   caseSensitive: true
  // },
  {
    path: '/roles/new-role',
    title: '',
    menu_name: 'Roles and App Access',
    component: <AddEditRoleAccess />,
    caseSensitive: true
  },
  {
    path: '/roles/edit-role',
    title: '',
    menu_name: 'Roles and App Access',
    component: <AddEditRoleAccess />,
    caseSensitive: true
  },
  {
    path: '/state',
    title: '',
    menu_name: 'States',
    component: <StatePage />,
    caseSensitive: true
  },
  {
    path: '/district',
    title: '',
    menu_name: 'Districts',
    component: <DistrictPage />,
    caseSensitive: true
  },
  {
    path: '/district/new-district',
    title: '',
    menu_name: 'Districts',
    component: <AddDistrict />,
    caseSensitive: true
  },
  {
    path: '/district/district-details/edit-district',
    title: '',
    menu_name: 'Districts',
    component: <AddDistrict />,
    caseSensitive: true
  },
  {
    path: '/area',
    title: '',
    menu_name: 'Area',
    component: <AreaPage />,
    caseSensitive: true
  },
  {
    path: '/area/new-area',
    title: '',
    menu_name: 'Area',
    component: <AddArea />,
    caseSensitive: true
  },
  {
    path: '/area/area-details/edit-area',
    title: '',
    menu_name: 'Area',
    component: <AddArea />,
    caseSensitive: true
  },
  {
    path: '/schools',
    title: '',
    menu_name: 'Schools',
    component: <SchoolPage />,
    caseSensitive: true
  },
  {
    path: '/schools/new-school',
    title: '',
    menu_name: 'Schools',
    component: <AddEditSchool />,
    caseSensitive: true
  },
  {
    path: '/schools/school-details/edit-school',
    title: '',
    menu_name: 'Schools',
    component: <AddEditSchool />,
    caseSensitive: true
  },
  {
    path: '/schools/school-details',
    title: '',
    menu_name: 'Schools',
    component: <SchoolDetails />,
    caseSensitive: true
  },

  {
    path: '/schools/school-details/add-new-class',
    title: '',
    menu_name: 'Schools',
    component: <AddClassroom />,
    caseSensitive: true
  },
  {
    path: '/schools/school-details/edit-class',
    title: '',
    menu_name: 'Schools',
    component: <AddClassroom />,
    caseSensitive: true
  },

  {
    path: '/usage-analytics',
    title: '',
    menu_name: 'Usage Analytics',
    component: <Usage />,
    caseSensitive: true
  },

  {
    path: '/app-analytics',
    title: '',
    menu_name: 'App Analytics',
    component: <ComingSoonComponent />,
    caseSensitive: true
  },
  {
    path: '/add-new-event',
    title: '',
    menu_name: 'Events',
    component: <ComingSoonComponent />,
    caseSensitive: true
  },
  {
    path: '/share-calendar',
    title: '',
    menu_name: 'Share Calendar',
    component: <Schedules type="all" />,
    caseSensitive: true
  },
  {
    path: '/area/area-details',
    title: '',
    menu_name: 'Area',
    component: <AreaDetails />,
    caseSensitive: true
  },
  {
    path: '/district/district-details',
    title: '',
    menu_name: 'Districts',
    component: <DistrictDetails />,
    caseSensitive: true
  },
  {
    path: '/evaluation-rubrics',
    title: '',
    menu_name: 'Evaluation Rubrics',
    component: <RubricPage />,
    caseSensitive: true
  },
  {
    path: '/evaluation-rubrics/rubric-details',
    title: '',
    menu_name: 'Evaluation Rubrics',
    component: <RubricDetails />,
    caseSensitive: true
  },
  {
    path: '/evaluation-rubrics/new-rubric',
    title: '',
    menu_name: 'Evaluation Rubrics',
    component: <AddEditRubrics />,
    caseSensitive: true
  },
  {
    path: '/evaluation-rubrics/rubric-details/edit-rubric',
    title: '',
    menu_name: 'Evaluation Rubrics',
    component: <AddEditRubrics />,
    caseSensitive: true
  },
  {
    path: '/flows',
    title: '',
    menu_name: 'Evaluation Flows',
    component: <Flows />,
    caseSensitive: true
  },
  {
    path: '/flows/flows-details',
    title: '',
    menu_name: 'Evaluation Flows',
    component: <FlowDetails />,
    caseSensitive: true
  },
  {
    path: '/flows/create-new-flow',
    title: '',
    menu_name: 'Evaluation Flows',
    component: <AddEditFlow />,
    caseSensitive: true
  },
  {
    path: '/flows/edit-flow',
    title: '',
    menu_name: 'Evaluation Flows',
    component: <AddEditFlow />,
    caseSensitive: true
  },
  {
    path: '/flows/flows-details/create-new-form',
    title: '',
    menu_name: 'Evaluation Flows',
    component: <AddEditForm />,
    caseSensitive: true
  },
  {
    path: '/flows/flows-details/edit-form',
    title: '',
    menu_name: 'Evaluation Flows',
    component: <AddEditForm />,
    caseSensitive: true
  },
  {
    path: '/classroom',
    title: '',
    menu_name: 'Classroom',
    component: <ClassroomPage />,
    caseSensitive: true
  },
  {
    path: '/classroom/add-new-class',
    title: '',
    menu_name: 'Classroom',
    component: <AddClassroom />,
    caseSensitive: true
  },
  {
    path: '/classroom/classroom-details/edit-classroom',
    title: '',
    menu_name: 'Classroom',
    component: <AddEditSchool />,
    caseSensitive: true
  },
  {
    path: '/classroom/classroom-details',
    title: '',
    menu_name: 'Classroom',
    component: <SchoolDetails />,
    caseSensitive: true
  },
  {
    path: '/schedules',
    title: '',
    menu_name: 'Schedules',
    component: <Schedules type="all" />,
    caseSensitive: true
  },
  {
    path: '/observations',
    title: '',
    menu_name: 'Classroom Observations',
    component: <ObservationReports />,
    caseSensitive: true
  },
  {
    path: '/observations/report',
    title: '',
    menu_name: 'Classroom Observations',
    component: <ObservationReports />,
    caseSensitive: true
  },
  {
    path: '/observations/new-observation',
    title: '',
    menu_name: 'Classroom Observations',
    component: <AddObservation />,
    caseSensitive: true
  },
  {
    path: '/observations/edit-observation',
    title: '',
    menu_name: 'Classroom Observations',
    component: <AddObservation />,
    caseSensitive: true
  },
  {
    path: '/observations/new-observation/evidence-card',
    title: '',
    menu_name: 'Classroom Observations',
    component: <EvidenceCard type="edit" />,
    caseSensitive: true
  },
  {
    path: '/observations/edit-observation/evidence-card',
    title: '',
    menu_name: 'Classroom Observations',
    component: <EvidenceCard type="edit" />,
    caseSensitive: true
  },
  {
    path: '/observations/observation-report',
    title: '',
    menu_name: 'Classroom Observations',
    component: <ObservationReportCard />,
    caseSensitive: true
  },
  {
    path: '/observations/observation-report/evidence-card',
    title: '',
    menu_name: 'Classroom Observations',
    component: <EvidenceCard type="view" />,
    caseSensitive: true
  },
  {
    path: '/courses',
    title: '',
    menu_name: 'Resources',
    component: <ComingSoonComponent />,
    caseSensitive: true
  },
  {
    path: '/session-schedules',
    title: '',
    menu_name: 'Session Schedules',
    component: <ComingSoonComponent />,
    caseSensitive: true
  },
  {
    path: '/give-feedback',
    title: '',
    menu_name: 'Give Feedback',
    component: <ComingSoonComponent />,
    caseSensitive: true
  },
  {
    path: '/flows/flows-details/preview',
    title: '',
    menu_name: 'Evaluation Flows',
    component: <PreviewForm />,
    caseSensitive: true
  },
  {
    path: '/observations/observation-report/create-evidence-card',
    title: '',
    menu_name: 'Classroom Observations',
    component: <EvidenceCard type="edit" />,
    caseSensitive: true
  },
  {
    path: '/schedules/events',
    title: '',
    menu_name: 'Schedules',
    component: <Events />,
    caseSensitive: true
  },
  {
    path: '/product-analytics/user-and-role-analytics',
    title: '',
    menu_name: 'User & Role Analytics',
    component: <ProductAnalysis type="user" />,
    caseSensitive: true
  },
  {
    path: '/product-analytics/location-analytics',
    title: '',
    menu_name: 'Location Analytics',
    component: <ProductAnalysis type="location" />,
    caseSensitive: true
  },
  {
    path: '/product-analytics/teacher-observation-analytics',
    title: '',
    menu_name: 'Teacher Observation Analytics',
    component: <ProductAnalysis type="observation" />,
    caseSensitive: true
  },
  {
    path: '/product-analytics/flows-forms-analytics',
    title: '',
    menu_name: 'Flows & Form Analytics',
    component: <ProductAnalysis type="flows" />,
    caseSensitive: true
  }
];

const publicRoute = [
  {
    path: '/maintenance',
    title: '',
    component: <Maintenance />,
    caseSensitive: true
  },
  {
    path: '/forbidden',
    title: '',
    component: <Forbidden />,
    caseSensitive: true
  },
  {
    path: '/password-recovery',
    title: '',
    component: <PasswordRecovery />,
    caseSensitive: true
  },
  {
    path: '/update-password',
    title: '',
    component: <UpdatePassword />,
    caseSensitive: true
  },
  {
    path: '/reset-password',
    title: '',
    component: <ResetPassword />,
    caseSensitive: true
  },
  { path: '/share-charts', component: <ShareCharts /> },
  { path: '/frequently-asked-questions', component: <FAQ /> }
];

export const CaseContext = createContext();
export const WebFlagContext = createContext();

export function WebFlagProvider({ children }) {
  const [webFlag, setWebFlag] = useState(false);

  return (
    <WebFlagContext.Provider value={{ webFlag, setWebFlag }}>
      {children}
    </WebFlagContext.Provider>
  );
}

export function App() {
  console.log(process.env.REACT_APP_ENV_TYPE, 'REACT_APP_ENV_TYPE');
  const dispatch = useDispatch();
  const { authData } = useSelector(state => state.auth);
  const { isModuleLoading } = useSelector(state => state.layout);
  const [isLoading, setIsLoading] = useState(false);
  const [basicDetailsContext, setBasicDetailsContext] = useState({
    userImage: '',
    name: ''
  });
  const { setWebFlag } = useContext(WebFlagContext);
  const menuData = getMenuData();

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 600);
    return () => clearTimeout(timer);
  }, [window.location.pathname]);

  useEffect(() => {
    const userId = authData?.userId;

    const userRef = ref(database, `PAF-USER/${userId}`);

    const checkWebFlag = snapshot => {
      const data = snapshot.val();
      if (data && data.web) {
        setWebFlag(true);
        dispatch(getNotificaitonsList());
      } else {
        setWebFlag(false);
      }
    };

    onValue(userRef, checkWebFlag, error => {
      console.error('Error with Firebase listener:', error);
    });

    return () => {
      off(userRef, 'value', checkWebFlag);
    };
  }, [authData?.userId]);

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <CaseContext.Provider
          value={{
            setBasicDetailsContext,
            basicDetailsContext
          }}
        >
          <Box bgcolor={colorAppBackground}>
            <>
              {isLoading && (
                <div>
                  <Loader />
                </div>
              )}
              {!isModuleLoading && !isLoading && (
                <Router>
                  <Routes>
                    <Route path="*" element={<NotFound />} />
                    <Route path="login" Component={Login} />
                    <Route exact path="/" element={<Navigate to="/login" />} />

                    {publicRoute.map((route, i) => (
                      <Route
                        key={i}
                        caseSensitive={route.caseSensitive}
                        path={route.path}
                        element={<>{route.component}</>}
                      />
                    ))}

                    {menuData && (
                      <Route
                        element={<ProtectedRoutes routesData={privateRoute} />}
                      >
                        {privateRoute.map((route, i) => (
                          <Route
                            key={i}
                            caseSensitive={route.caseSensitive}
                            path={route.path}
                            element={
                              <Layout title={route.title ? route.title : null}>
                                {route.component}
                              </Layout>
                            }
                          />
                        ))}
                      </Route>
                    )}
                  </Routes>
                </Router>
              )}
            </>
          </Box>
        </CaseContext.Provider>
      </ThemeProvider>
    </>
  );
}
