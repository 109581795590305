import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { FormContent } from '../utils/styledLayout';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../carousal.css';
import { Typography } from '@mui/material';

const CustomCarousel = ({ slides }) => {
  return (
    <Carousel
      showStatus={false}
      showArrows={false}
      showThumbs={false}
      transitionTime={1000}
      showIndicators={true}
      autoPlay={true}
      infiniteLoop={true}
      stopOnHover={true}
      width="504px"
      style={{
        width: '504px'
      }}
    >
      {slides.map((slide, index) => (
        <div key={index} style={{}}>
          <Typography
            style={{
              display: 'flex',
              fontSize: '20px',
              fontWeight: '700',
              marginBottom: '10px'
            }}
          >
            {slide.title}
          </Typography>
          <img
            style={{ width: 504, height: 355 }}
            src={slide.image}
            alt={`Slide ${index}`}
          />

          <Typography
            style={{
              display: 'flex',
              fontSize: '14px',
              fontWeight: '500',
              marginTop: '10px',
              lineHeight: '18px',
              textAlign: 'left'
            }}
          >
            {slide.description}
          </Typography>
          <div style={{ display: 'flex', marginTop: '40px' }}></div>
        </div>
      ))}
    </Carousel>
  );
};
CustomCarousel.propTypes = {
  slides: PropTypes.array
};
export default CustomCarousel;
