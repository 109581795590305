import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getLocationAnalytics,
  getMetaDataArea,
  getMetaDataDistrict,
  getMetaDataSchool,
  getMetaDataState,
  getUserAnalytics,
  getUserAndRoleAnalytics,
  revertLocationAnalytics
} from '../../redux/reducers/analyticsSlice';
import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Expand05Icon,
  FileDownload03Icon,
  FilterLinesIcon,
  User01Icon,
  XClose
} from '../../utils/iconSvg';
import { isArrayNotEmpty } from '../../utils/utils';
import { useSelector } from 'react-redux';
import { colorPrimary, colorSecondary, colorWhite } from '../../config/theme';
import {
  Legend,
  prepareChartOptionsAndData,
  styledMenuItem,
  StyledSelect
} from '../productanalytics/UserAndRoleAnalytics';
import dayjs from 'dayjs';
import { HighlightOff } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Chart from 'react-google-charts';
import { useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';

const SelectStyled = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CBD2D9'
  },
  '&:hover  .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CBD2D9'
  },
  '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CBD2D9'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 8
  }
};
const ShareLocationAnalytics = () => {
  const dispatch = useDispatch();
  const chartRef = useRef();
  const location = useLocation();
  let chartType = new URLSearchParams(location.search).get('chartType');

  const [chart1Payload, setChart1Payload] = useState({
    stateId: 'all',
    districtId: 'all',
    area: 'all',
    school: 'all'
  });
  const [chart, setChart] = useState('');

  const {
    isLocationAnalyticsLoading,
    locationAnalyticsData,
    metaDataState,
    metaDataArea,
    metaDataDistrict,
    metaDataSchool,
    isMetaDataStateSuccess,
    isMetaDataDistrictSuccess,
    isMetaDataAreaSuccess
  } = useSelector(state => state.analytics);

  useEffect(() => {
    let mastersPayload = {
      type: 'all',
      page: 0,
      size: 0,
      search: ''
    };
    dispatch(getMetaDataState(mastersPayload));
    dispatch(getMetaDataDistrict(mastersPayload));
    dispatch(getMetaDataArea(mastersPayload));
    dispatch(getMetaDataSchool(mastersPayload));

    return () => {
      dispatch(revertLocationAnalytics());
    };
  }, []);

  useEffect(() => {
    if (chartType == 'locationChart') {
      let payload = {
        stateId: chart1Payload.stateId == 'all' ? null : chart1Payload.stateId,
        districtId:
          chart1Payload.districtId == 'all' ? null : chart1Payload.districtId,
        area: chart1Payload.area == 'all' ? null : chart1Payload.area,
        school: chart1Payload.school == 'all' ? null : chart1Payload.school
      };
      dispatch(getLocationAnalytics(payload));
    }
  }, [chart1Payload]);

  useEffect(() => {
    if (
      isMetaDataStateSuccess &&
      isMetaDataDistrictSuccess &&
      isMetaDataAreaSuccess
    ) {
      let params = new URLSearchParams(location.search);
      if (chartType == 'locationChart') {
        let payloadForChart1 = {
          stateId: params.get('state'),
          districtId: params.get('district'),
          area: params.get('area'),
          school: params.get('school')
        };

        setChart1Payload({
          ...chart1Payload,
          stateId:
            payloadForChart1.stateId != 'all'
              ? +payloadForChart1.stateId
              : 'all',
          districtId:
            payloadForChart1.districtId != 'all'
              ? +payloadForChart1.districtId
              : 'all',
          area: payloadForChart1.area != 'all' ? +payloadForChart1.area : 'all',
          school:
            payloadForChart1.school != 'all' ? +payloadForChart1.school : 'all'
        });
      }
    }
    return () => {
      // second;
    };
  }, [
    isMetaDataStateSuccess,
    isMetaDataDistrictSuccess,
    isMetaDataAreaSuccess
  ]);

  const handleClickOpen = id => {
    setChart(id);
  };

  const handleClose = () => {
    setChart('');
  };

  const handleDownload = async ({ id = null }) => {
    const canvas = await html2canvas(chartRef.current);
    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = 'geo_chart.png';
    link.click();
  };

  return (
    <Grid item>
      <Typography mb={2} variant="h5" fontWeight={'600'}>
        Location Analytics
      </Typography>
      <Grid
        mt={2}
        container
        display={'flex'}
        width={'100%'}
        flexDirection={'column'}
        sx={{ borderRadius: '8px', padding: 2, bgcolor: 'white' }}
      >
        <Grid item display={'flex'} mb={1}>
          <Grid container display={'flex'} mb={1}>
            <Grid xs={12} lg={2.9} sm={12} md={6} mr={1} mb={1}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={chart1Payload.stateId}
                  onChange={e => {
                    setChart1Payload({
                      ...chart1Payload,
                      stateId: e.target.value,
                      districtId: 'all',
                      area: 'all',
                      school: 'all'
                    });
                  }}
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '&:hover  .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8
                    }
                  }}
                  renderValue={(val, val1) => {
                    let value =
                      isArrayNotEmpty(metaDataState) &&
                      metaDataState.find((row, key) => row.stateId === val);

                    return val === 'all' ? (
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#ABB4BD'
                        }}
                      >
                        Select state
                      </Typography>
                    ) : (
                      value?.stateName
                    );
                  }}
                >
                  {isArrayNotEmpty(metaDataState) &&
                    metaDataState.map((row, key) => {
                      return (
                        <MenuItem
                          key={key}
                          value={row.stateId}
                          sx={{
                            ':hover': {
                              backgroundColor: colorSecondary
                            }
                          }}
                        >
                          {row?.stateName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} lg={2.9} sm={12} md={6} mr={1} mb={1}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={chart1Payload.districtId}
                  onChange={e =>
                    setChart1Payload({
                      ...chart1Payload,
                      districtId: e.target.value,
                      area: 'all',
                      school: 'all'
                    })
                  }
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '&:hover  .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8
                    }
                  }}
                  renderValue={val => {
                    let value =
                      isArrayNotEmpty(metaDataDistrict) &&
                      metaDataDistrict.find(
                        (row, key) => row.districtId === val
                      );

                    return val === 'all' ? (
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#ABB4BD'
                        }}
                      >
                        Select district
                      </Typography>
                    ) : (
                      value?.districtName
                    );
                  }}
                >
                  {isArrayNotEmpty(metaDataDistrict) &&
                    metaDataDistrict
                      .filter(cc => cc.stateId === chart1Payload.stateId)
                      .map((row, key) => {
                        return (
                          <MenuItem
                            key={key}
                            value={row?.districtId}
                            sx={{
                              ':hover': {
                                backgroundColor: colorSecondary
                              }
                            }}
                          >
                            {row?.districtName}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} lg={2.9} sm={12} md={6} mr={1} mb={1}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={chart1Payload.area}
                  onChange={e =>
                    setChart1Payload({
                      ...chart1Payload,
                      area: e.target.value,
                      school: 'all'
                    })
                  }
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '&:hover  .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8
                    }
                  }}
                  renderValue={val => {
                    let value =
                      isArrayNotEmpty(metaDataArea) &&
                      metaDataArea.find((row, key) => row.pinId === val);

                    return val == 'all' ? (
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#ABB4BD'
                        }}
                      >
                        Select area
                      </Typography>
                    ) : (
                      value?.area
                    );
                  }}
                >
                  {isArrayNotEmpty(metaDataArea) &&
                    metaDataArea
                      .filter(
                        cc =>
                          cc.stateId === chart1Payload.stateId &&
                          cc.districtId === chart1Payload.districtId
                      )
                      .map((row, key) => {
                        return (
                          <MenuItem
                            key={key}
                            value={row?.pinId}
                            sx={{
                              ':hover': {
                                backgroundColor: colorSecondary
                              }
                            }}
                          >
                            {row?.area}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </Grid>
            <Grid xs={12} lg={2.9} sm={12} md={6} mr={1} mb={1}>
              <FormControl fullWidth>
                <Select
                  size="small"
                  value={chart1Payload.school}
                  onChange={e =>
                    setChart1Payload({
                      ...chart1Payload,
                      school: e.target.value
                    })
                  }
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '&:hover  .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#CBD2D9'
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 8
                    }
                  }}
                  renderValue={val => {
                    let value =
                      isArrayNotEmpty(metaDataSchool) &&
                      metaDataSchool.find((row, key) => row.schoolId === val);

                    return val == 'all' ? (
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#ABB4BD'
                        }}
                      >
                        Select school
                      </Typography>
                    ) : (
                      value?.schoolName
                    );
                  }}
                >
                  {isArrayNotEmpty(metaDataSchool) &&
                    metaDataSchool
                      .filter(
                        cc =>
                          cc.stateId === chart1Payload.stateId &&
                          cc.districtId === chart1Payload.districtId &&
                          cc.pinId === chart1Payload.area
                      )
                      .map((row, key) => {
                        return (
                          <MenuItem
                            key={key}
                            value={row?.schoolId}
                            sx={{
                              ':hover': {
                                backgroundColor: colorSecondary
                              }
                            }}
                          >
                            {row?.schoolName}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ display: 'flex', alignSelf: 'end' }}>
          <Tooltip title="Resize">
            <IconButton onClick={() => handleClickOpen(1)} sx={{ mx: 0.5 }}>
              <SvgIcon sx={{ height: 18, width: 18 }}>
                <Expand05Icon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
          <Tooltip title="Download image">
            <IconButton
              onClick={() => {
                handleDownload({ id: 1 });
              }}
              sx={{ mr: 0.5 }}
            >
              <SvgIcon sx={{ height: 18, width: 18 }}>
                <FileDownload03Icon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={12} mb={2}>
          {isLocationAnalyticsLoading && (
            <Skeleton height={300} width={'100%'} />
          )}
          {!isLocationAnalyticsLoading && (
            <Box sx={{ bgcolor: 'transparent' }} ref={chartRef}>
              <Chart
                chartType="GeoChart"
                width="100%"
                height="400px"
                data={locationAnalyticsData?.analyticsData}
                options={{
                  region: 'IN',
                  displayMode: 'regions',
                  resolution: 'provinces',
                  colorAxis: { colors: [colorSecondary, colorSecondary] },
                  sizeAxis: { minValue: 0, maxValue: 100 },
                  legend: 'none',
                  backgroundColor: colorWhite,
                  datalessRegionColor: colorWhite,
                  defaultColor: colorWhite,
                  magnifyingGlass: { enable: true, zoomFactor: 10.5 }
                }}
                mapsApiKey={process.env.REACT_APP_GOOGLE_CALENDAR_API}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Dialog
        sx={{ borderRadius: '8px' }}
        open={chart !== ''}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogContent sx={{ borderRadius: 10 }}>
          <Box textAlign={'end'}>
            <IconButton size="small" onClick={handleClose}>
              <SvgIcon sx={{ height: 18, width: 18 }}>
                <XClose />
              </SvgIcon>
            </IconButton>
          </Box>
          {chart == 1 && (
            <Chart
              chartType="GeoChart"
              width="100%"
              height="400px"
              data={locationAnalyticsData?.analyticsData}
              options={{
                region: 'IN',
                displayMode: 'regions',
                resolution: 'provinces',
                colorAxis: { colors: [colorSecondary, colorSecondary] },
                sizeAxis: { minValue: 0, maxValue: 100 },
                legend: 'none',
                backgroundColor: colorWhite,
                datalessRegionColor: colorWhite,
                defaultColor: colorWhite,
                magnifyingGlass: { enable: true, zoomFactor: 10.5 }
              }}
              mapsApiKey={process.env.REACT_APP_GOOGLE_CALENDAR_API}
            />
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ShareLocationAnalytics;
