import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Link,
  Skeleton,
  SvgIcon,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import CustomBreadcrumb from '../../components/Breadcrumb';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import {
  Add,
  Close,
  Delete,
  LocalOfferOutlined,
  Search
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  createUpdateRubric,
  getAllDomains,
  getAllTags,
  revertRubricFormData,
  rubricsDataById
} from '../../redux/reducers/rubricSlice';
import SnackBarBox from '../../components/SnackBarBox';
import { api } from '../../config/api';
import { AlertCircleIcon, ChevronRightIcon, XIcon } from '../../utils/iconSvg';

const AddEditRubrics = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lastIndicatorRef = useRef(null);
  const initialValues = {
    rubricName: '',
    listOfIndicators: []
  };
  const [showHelper, setShowHelper] = useState(true);
  const [indicatorsLoading, setIndicatorsLoading] = useState(false);
  const [showError, setShowError] = useState(null);
  const [showIndicatorAddedMessage, setShowIndicatorAddedMessage] =
    useState(null);
  const [options, setOptions] = useState([]);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [additionalTags, setAdditionalTags] = useState([]);
  const {
    domains,
    tags,
    isCreateUpdateRubricLoading,
    isCreateUpdateRubricSuccess,
    isCreateUpdateRubricFail,
    createUpdateRubricResponse,
    createUpdateRubricErrorContainer,
    rubricsByIdSuccess,
    rubricsByIdData
  } = useSelector(state => state.rubric);
  const { authData } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const [isHover, setIshover] = useState(false);

  useEffect(() => {
    document.title = createTitle(
      `${location.pathname === '/evaluation-rubrics/rubric-details/edit-rubric' ? 'Edit' : 'Add'} Rubric`
    );

    dispatch(getAllDomains());
    dispatch(getAllTags());
    fetchOptions('');
    if (
      location.pathname === '/evaluation-rubrics/rubric-details/edit-rubric' &&
      location.state
    ) {
      dispatch(rubricsDataById(location.state?.rubricId));
    }

    return () => dispatch(revertRubricFormData());
  }, []);

  function getOptionLabel(option) {
    if (!option) {
      return '';
    }

    if (option.label !== null) {
      return option.label;
    }

    return '';
  }

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      rubricName: Yup.string().required('Rubric Name is required'),
      listOfIndicators: Yup.array().of(
        Yup.object().shape({
          domain: Yup.object().nullable().required('Please select a domain'),
          tags: Yup.array(),
          indicatorName: Yup.string().required('Indicator Name is required'),
          indicatorDescription: Yup.string().nullable()
        })
      )
    }),
    onSubmit: values => {
      let errorMessage = '';
      let tempListOfIndicators = [];

      if (isArrayNotEmpty(values.listOfIndicators)) {
        setLoading(true);
        let arr = values.listOfIndicators;
        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];

          let tmpTags = isArrayNotEmpty(element.tags)
            ? element.tags.map(cc => cc.label || cc?.tagName)
            : [];

          tempListOfIndicators.push({
            indicatorId: element.indicatorId,
            domainId: element.domain ? element.domain.id : null,
            tagNames: tmpTags,
            indicatorName: element.indicatorName,
            indicatorDescription: element.indicatorDescription,
            loggedInUserName: authData.userName
          });
        }

        let submitPayload = {
          rubricName: values.rubricName,
          indicatorRequestList: tempListOfIndicators,
          loggedInUserName: authData.userName
        };
        dispatch(
          createUpdateRubric({
            rubricId: location.state ? location.state.rubricId : null,
            payload: submitPayload
          })
        );
      } else {
        errorMessage = 'One indicator should be available.';
      }

      setShowError(errorMessage);
    }
  });

  useEffect(() => {
    if (isCreateUpdateRubricSuccess) {
      setTimeout(() => {
        navigate('/evaluation-rubrics');
        dispatch(revertRubricFormData());
      }, 1800);
    }

    if (isCreateUpdateRubricFail) {
      setLoading(false);
    }
  }, [isCreateUpdateRubricSuccess, isCreateUpdateRubricFail]);

  const handleAddIndicator = () => {
    formik.values.listOfIndicators.push({
      domain: null,
      tags: [],
      indicatorName: '',
      indicatorDescription: ''
    });
    formik.setValues({ ...formik.values });

    // Make sure the last indicator element scrolls into view
    setTimeout(() => {
      const indicatorElement = document.getElementById(
        `ind-${formik.values.listOfIndicators.length - 1}`
      );

      if (indicatorElement) {
        indicatorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0);
  };

  const handleRemoveIndicator = index => {
    formik.values.listOfIndicators.splice(index, 1);
    formik.setValues({ ...formik.values });
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (rubricsByIdData?.rubricId) {
        let tempIndicators = [];
        if (
          rubricsByIdData.indicators &&
          isArrayNotEmpty(rubricsByIdData.indicators)
        ) {
          let arry = rubricsByIdData.indicators;

          for (let index = 0; index < arry.length; index++) {
            const element = arry[index];

            tempIndicators.push({
              indicatorId: element.indicatorId ? element.indicatorId : null,
              domain: { id: element.domainId, label: element.domainName },
              tags:
                element.tags && isArrayNotEmpty(element.tags)
                  ? element.tags.map(cc => {
                      return { id: cc.tagId, label: cc.tagName };
                    })
                  : [],
              indicatorName: element.indicatorName,
              indicatorDescription: element.indicatorDescription
            });
          }
        } else {
          tempIndicators = [];
        }
        formik.setValues({
          rubricName: rubricsByIdData.rubricName,
          listOfIndicators: tempIndicators
        });

        setLoading(false);
      } else {
        setLoading(false);
      }
    }, 1200);
  }, [rubricsByIdSuccess]);

  useEffect(() => {
    if (location.state && location.state.indicatorId) {
      let index = formik.values.listOfIndicators.findIndex(
        cc => cc.indicatorId === location.state.indicatorId
      );
      const indicatorElement = document.getElementById(`ind-${index}`);
      if (indicatorElement) {
        indicatorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [rubricsByIdData?.rubricId && !loading]);

  const fetchOptions = async searchText => {
    try {
      setIndicatorsLoading(true);
      const response = await api.get(
        `rubrics/indicatorsByNameSearch?searchCriteria=${searchText}`
      );
      let indicatorList =
        response.data && response.data.payload
          ? response.data.payload.dataList
          : [] || [];
      setOptions(indicatorList);
      setIndicatorsLoading(false);
    } catch (error) {
      setIndicatorsLoading(false);
    }
  };

  const handleInputChange = (event, value) => {
    if (value && (value !== null || value !== '')) {
      fetchOptions(value);
    } else fetchOptions('');
  };

  const handleApplyIndicator = () => {
    let addedIndicatorList = [...formik.values.listOfIndicators];

    let tempIndicator = options.find(
      cc => cc.indicatorId === selectedIndicator?.id
    );

    if (tempIndicator) {
      addedIndicatorList.push({
        indicatorId: tempIndicator.indicatorId,
        domain: { id: tempIndicator.domainId, label: tempIndicator.domainName },
        indicatorName: tempIndicator.indicatorName,
        indicatorDescription: tempIndicator.indicatorDescription,
        tags:
          tempIndicator.tags && isArrayNotEmpty(tempIndicator.tags)
            ? tempIndicator.tags.map(c => {
                return { id: c.tagId, label: c.tagName };
              })
            : []
      });

      formik.setFieldValue('listOfIndicators', addedIndicatorList);
      // setOptions([]);
      setSelectedIndicator(null);
      setShowIndicatorAddedMessage('Indicator added successfully...');
    } else {
      setShowError('Select Indicator');
    }

    setTimeout(() => {
      setShowIndicatorAddedMessage(null);
      setShowError(null);
    }, 1000);
  };

  return (
    <Box
      sx={{
        width: '100%'
      }}
    >
      <Typography fontSize={18} fontWeight="medium" marginBottom={1}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={
            <SvgIcon sx={{ height: 24, width: 24 }}>
              <ChevronRightIcon color={colorTextGrey} />
            </SvgIcon>
          }
          sx={{ marginBottom: 2 }}
        >
          <Link
            color="inherit"
            href={'rubric'}
            onClick={event => {
              event.preventDefault();
              navigate(
                location.pathname ===
                  '/evaluation-rubrics/rubric-details/edit-rubric'
                  ? '/evaluation-rubrics/rubric-details/'
                  : '/evaluation-rubrics',
                { state: { ...location.state } }
              );
            }}
            sx={{ textDecoration: 'none' }}
          >
            <Typography
              fontWeight={'700'}
              color={colorLinkGrey}
              fontSize={'24px'}
            >
              {rubricsByIdData?.rubricName || 'Evaluation Rubrics'}
            </Typography>
          </Link>
          <Typography
            sx={{ fontSize: '32px', fontWeight: '700', color: colorTextBlack }}
          >
            {`${location.pathname === '/evaluation-rubrics/rubric-details/edit-rubric' ? 'Edit' : 'New'} Rubric`}
          </Typography>
        </Breadcrumbs>
      </Typography>

      {loading && (
        <Skeleton
          variant="rounded"
          width={'100%'}
          height={400}
          animation="wave"
        />
      )}
      {!loading && (
        <>
          {showHelper &&
            location.pathname !==
              '/evaluation-rubrics/rubric-details/edit-rubric' && (
              <Grid
                sx={{
                  display: 'flex',
                  padding: '12px',
                  backgroundColor: '#EAF1FE',
                  border: '1px solid #2F68C4',
                  borderRadius: '8px',
                  marginBottom: 2
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#2F68C4',
                    height: '32px',
                    width: '32px',
                    padding: '3px',
                    borderRadius: '8px',
                    marginRight: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 6.66675V10.0001M10.5 13.3334H10.5083M18.8333 10.0001C18.8333 14.6025 15.1023 18.3334 10.5 18.3334C5.89759 18.3334 2.16663 14.6025 2.16663 10.0001C2.16663 5.39771 5.89759 1.66675 10.5 1.66675C15.1023 1.66675 18.8333 5.39771 18.8333 10.0001Z"
                      stroke="#EAF1FE"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Box>
                <Box width={'100%'} display={'flex'} flexDirection={'column'}>
                  <Typography
                    fontWeight={'700'}
                    color={'#2F68C4'}
                    fontSize={'16px'}
                  >
                    Instruction to creating a rubric
                  </Typography>
                  <Typography
                    fontWeight={'600'}
                    fontSize={'14px'}
                    color={'#2F68C4'}
                  >
                    1. Add indicators that you want to use to evaluate the
                    target audience
                  </Typography>
                  <Typography
                    fontWeight={'600'}
                    fontSize={'14px'}
                    color={'#2F68C4'}
                  >
                    2. Group Indicators based on domains they belong to and add
                    tags for easy access
                  </Typography>
                  <Typography
                    fontWeight={'600'}
                    fontSize={'14px'}
                    color={'#2F68C4'}
                  >
                    3. Assign evaluation rubric to specific user groups, roles
                    or categories of users
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setShowHelper(false);
                    }}
                  >
                    <XIcon />
                  </IconButton>
                </Box>
              </Grid>
            )}
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Box
              bgcolor={'white'}
              padding={2}
              borderRadius={2}
              marginBottom={2}
            >
              <Grid container spacing={3}>
                <Grid item sx={{ width: '340px' }}>
                  <InputLabel
                    required
                    sx={{
                      mb: 0.2,
                      fontWeight: '700',
                      color: colorTextBlack,
                      fontSize: '16px'
                    }}
                  >
                    Rubric Name
                  </InputLabel>
                  <TextField
                    name="rubricName"
                    sx={{ width: '340px' }}
                    size="small"
                    inputProps={{ maxLength: 64 }}
                    value={formik.values.rubricName}
                    onChange={formik.handleChange}
                    InputLabelProps={{
                      shrink: formik.values.rubricName !== ''
                    }}
                    error={
                      formik.touched.rubricName &&
                      Boolean(formik.errors.rubricName)
                    }
                    helperText={
                      formik.touched.rubricName && formik.errors.rubricName
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              display={'flex'}
              marginBottom={2}
              justifyContent={'space-between'}
            >
              {/* <Tooltip placement="top" title="Click to add new indicator">
                <Button
                  sx={{
                    height: 30,
                    color: isHover ? colorPrimary : colorTextBlack,
                    borderBottom: `1px solid ${isHover ? colorPrimary : colorTextBlack}`,
                    borderRadius: 0
                    // ':hover': {
                    //   color: colorPrimary,
                    //   borderBottom: `1px solid ${colorPrimary} `
                    // }
                  }}
                  onMouseEnter={() => setIshover(true)}
                  onMouseLeave={() => setIshover(false)}
                  variant="text"
                  onClick={() => {
                    handleAddIndicator();
                  }}
                  size="small"
                  // startIcon={<Add />}
                >
                  <SvgIcon
                    fontSize="small"
                    sx={{ marginRight: 0.6, height: '16px', width: '16px' }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.49996 2.66669V13.3334M3.16663 8.00002H13.8333"
                        stroke={isHover ? colorPrimary : colorTextBlack}
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </SvgIcon>{' '}
                  Add indicator
                </Button>
              </Tooltip> */}

              <Box display="none" sx={{}}>
                <Autocomplete
                  sx={{ minWidth: 250, marginRight: 2 }}
                  size="small"
                  loading={indicatorsLoading}
                  value={selectedIndicator}
                  onChange={(_, value) => {
                    setSelectedIndicator(value);
                  }}
                  options={
                    isArrayNotEmpty(options)
                      ? options.map(c => {
                          return {
                            label: c.indicatorName,
                            id: c.indicatorId
                          };
                        })
                      : []
                  }
                  onInputChange={handleInputChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label=""
                      sx={{ background: 'white', borderRadius: '8px' }}
                      placeholder="Search indicator"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {indicatorsLoading ? 'Loading...' : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                  ListboxProps={{
                    sx: {
                      '& .MuiAutocomplete-option:hover': {
                        backgroundColor: colorSecondary
                      }
                    }
                  }}
                />
                <Tooltip placement="top" title="Click to apply indicator">
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: colorPrimary,
                      borderRadius: '10px',
                      color: colorTextBlack,
                      borderWidth: '2px',
                      marginRight: 2,
                      width: 130,
                      '&:hover': {
                        borderColor: colorPrimary,
                        backgroundColor: 'transparent',
                        borderRadius: '10px',
                        borderWidth: '2px'
                      }
                    }}
                    onClick={() => {
                      handleApplyIndicator();
                    }}
                    size="small"
                    // startIcon={<ArrowBackIcon />}
                  >
                    Apply Indicator
                  </Button>
                </Tooltip>
              </Box>
            </Box>
            {formik.values.listOfIndicators.map((indicator, index) => {
              return (
                <Box
                  ref={
                    index === formik.values.listOfIndicators.length - 1
                      ? lastIndicatorRef
                      : null
                  }
                  id={`ind-${index}`}
                  key={index}
                  bgcolor={'white'}
                  padding={2}
                  borderRadius={2}
                  marginBottom={2}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mb: 2
                    }}
                  >
                    <Typography
                      display={'flex'}
                      alignItems={'center'}
                      fontWeight={'700'}
                      fontSize="20px"
                      m={0}
                      gutterBottom
                    >
                      Indicator {index + 1}
                    </Typography>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          handleRemoveIndicator(index);
                        }}
                        aria-label="expand accordion"
                      >
                        <Delete color="error" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  {/* <Divider sx={{ marginBottom: 4 }} orientation="horizontal" /> */}

                  <Grid item sx={{ width: '395px' }} mb={2}>
                    <InputLabel
                      required
                      sx={{
                        fontWeight: '700',
                        color: colorTextBlack,
                        fontSize: '16px',
                        mb: 0.6
                      }}
                    >
                      Add Domain
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Autocomplete
                        size="small"
                        value={indicator.domain}
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            `listOfIndicators.${index}.domain`,
                            newValue
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === (value ? value.id : null)
                        }
                        getOptionLabel={getOptionLabel}
                        options={
                          isArrayNotEmpty(domains)
                            ? domains.map(c => {
                                return {
                                  label: c.domainName,
                                  id: c.domainId
                                };
                              })
                            : []
                        }
                        renderInput={params => (
                          <TextField
                            name={`listOfIndicators.${index}.domain`}
                            {...params}
                            sx={{
                              width: '395px',
                              '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall':
                                {
                                  paddingLeft: '12px'
                                }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  <svg
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14.5 14L12.1667 11.6667M13.8333 7.66667C13.8333 10.7963 11.2963 13.3333 8.16667 13.3333C5.03705 13.3333 2.5 10.7963 2.5 7.66667C2.5 4.53705 5.03705 2 8.16667 2C11.2963 2 13.8333 4.53705 13.8333 7.66667Z"
                                      stroke={colorTextGrey}
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>

                                  {params.InputProps.startAdornment}
                                </>
                              )
                            }}
                            error={
                              formik.touched.listOfIndicators?.[index]
                                ?.domain &&
                              Boolean(
                                formik.errors.listOfIndicators?.[index]?.domain
                              )
                            }
                            helperText={
                              formik.touched.listOfIndicators?.[index]
                                ?.domain &&
                              formik.errors.listOfIndicators?.[index]?.domain
                            }
                          />
                        )}
                        ListboxProps={{
                          sx: {
                            '& .MuiAutocomplete-option:hover': {
                              backgroundColor: colorSecondary
                            }
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ width: '395px' }} mb={2}>
                    <InputLabel
                      sx={{
                        fontWeight: '700',
                        color: colorTextBlack,
                        fontSize: '16px',
                        mb: 0.6
                      }}
                    >
                      Tags
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Autocomplete
                        size="small"
                        multiple
                        id={`listOfIndicators[${index}].tags`}
                        value={formik.values.listOfIndicators[index].tags}
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            `listOfIndicators.${index}.tags`,
                            [...newValue]
                          );
                        }}
                        options={
                          isArrayNotEmpty(tags)
                            ? [...tags, ...additionalTags].map(c => {
                                return {
                                  label: c.tagName,
                                  id: c.tagId
                                };
                              })
                            : []
                        }
                        isOptionEqualToValue={(option, value) => {
                          return option.id === (value.id || value.tagId);
                        }}
                        getOptionLabel={option =>
                          option.label || option.tagName
                        }
                        // ChipProps={{ style: { backgroundColor: 'none' } }}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              size="small"
                              color="secondary"
                              key={index}
                              label={option.label || option.tagName}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        ListboxProps={{
                          sx: {
                            '& .MuiAutocomplete-option:hover': {
                              backgroundColor: colorSecondary
                            }
                          }
                        }}
                        limitTags={2}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder={`${
                              isArrayNotEmpty(
                                formik.values.listOfIndicators[index].tags
                              )
                                ? formik.values.listOfIndicators[index].tags
                                    .length > 0
                                  ? ''
                                  : 'Add a tag ...'
                                : 'Add a tag ...'
                            }`}
                            onKeyDown={event => {
                              console.log(event.key, 'as');
                              if (
                                event.key === 'Tab' ||
                                event.key === 'Enter'
                              ) {
                                event.preventDefault();

                                const currentInputValue =
                                  params.inputProps.value;

                                const existingOption = [
                                  ...tags,
                                  ...additionalTags
                                ]
                                  .map(tag => ({
                                    label: tag.tagName,
                                    id: tag.tagId
                                  }))
                                  .find(
                                    option => option.label === currentInputValue
                                  );

                                if (!existingOption && currentInputValue) {
                                  const newOption = {
                                    tagName: currentInputValue,
                                    tagId: Math.random()
                                  };

                                  setAdditionalTags([
                                    ...additionalTags,
                                    newOption
                                  ]);

                                  formik.setFieldValue(
                                    `listOfIndicators.${index}.tags`,
                                    [
                                      ...formik.values.listOfIndicators[index]
                                        .tags,
                                      newOption
                                    ]
                                  );

                                  event.target.value = '';
                                }
                              }
                            }}
                            sx={{
                              '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall':
                                {
                                  paddingLeft: '12px'
                                }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  <svg
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M5.83334 5.3335H5.84001M1.83334 3.46683L1.83334 6.44984C1.83334 6.77596 1.83334 6.93902 1.87018 7.09247C1.90285 7.22852 1.95672 7.35858 2.02982 7.47787C2.11228 7.61243 2.22758 7.72773 2.45818 7.95833L7.5706 13.0708C8.36264 13.8628 8.75865 14.2588 9.21531 14.4072C9.617 14.5377 10.0497 14.5377 10.4514 14.4072C10.908 14.2588 11.3041 13.8628 12.0961 13.0708L13.5706 11.5962C14.3626 10.8042 14.7587 10.4082 14.907 9.95153C15.0375 9.54984 15.0375 9.11715 14.907 8.71546C14.7587 8.2588 14.3626 7.86279 13.5706 7.07075L8.45818 1.95833C8.22758 1.72773 8.11228 1.61243 7.97772 1.52998C7.85843 1.45687 7.72837 1.403 7.59232 1.37034C7.43887 1.3335 7.27581 1.3335 6.94969 1.3335L3.96668 1.3335C3.21994 1.3335 2.84657 1.3335 2.56136 1.47882C2.31047 1.60665 2.1065 1.81063 1.97867 2.06151C1.83334 2.34672 1.83334 2.72009 1.83334 3.46683ZM6.16668 5.3335C6.16668 5.51759 6.01744 5.66683 5.83334 5.66683C5.64925 5.66683 5.50001 5.51759 5.50001 5.3335C5.50001 5.1494 5.64925 5.00016 5.83334 5.00016C6.01744 5.00016 6.16668 5.1494 6.16668 5.3335Z"
                                      stroke={colorTextGrey}
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>

                                  {params.InputProps.startAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                      />
                      <Box display={'flex'}>
                        {isArrayNotEmpty(
                          formik.values.listOfIndicators[index].tags
                        ) &&
                          formik.values.listOfIndicators[index].tags.map(
                            (cc, indiIdx) => {
                              return (
                                <Typography
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mt: 1,
                                    mr: 1,
                                    fontWeight: '600',
                                    padding: '2px 6px',
                                    background: colorSecondary,
                                    borderRadius: '6px',
                                    border: `1px solid ${colorPrimary}`
                                  }}
                                  key={indiIdx}
                                  variant="caption"
                                >
                                  {cc?.label || cc?.tagName}
                                  <IconButton
                                    onClick={() => {
                                      const newTags = [
                                        ...formik.values.listOfIndicators[index]
                                          .tags
                                      ];
                                      newTags.splice(indiIdx, 1);
                                      formik.setFieldValue(
                                        `listOfIndicators.${index}.tags`,
                                        newTags
                                      );
                                    }}
                                    sx={{
                                      padding: 0,
                                      ml: 2
                                    }}
                                  >
                                    <Close fontSize="small" color="action" />
                                  </IconButton>
                                </Typography>
                              );
                            }
                          )}
                      </Box>
                    </FormControl>
                  </Grid>

                  <Grid item sx={{ width: '395px' }} mb={2}>
                    <InputLabel
                      required
                      sx={{
                        fontWeight: '700',
                        color: colorTextBlack,
                        fontSize: '16px',
                        mb: 0.6
                      }}
                    >
                      Enter indicator
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        maxRows={4}
                        name={`listOfIndicators.${index}.indicatorName`}
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 200 }}
                        value={
                          formik.values.listOfIndicators[index].indicatorName
                        }
                        onChange={formik.handleChange}
                        error={
                          formik.touched.listOfIndicators?.[index]
                            ?.indicatorName &&
                          Boolean(
                            formik.errors.listOfIndicators?.[index]
                              ?.indicatorName
                          )
                        }
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <FormHelperText id="listOfIndicators.${index}.indicatorName">
                          {formik.touched.listOfIndicators?.[index]
                            ?.indicatorName &&
                            formik.errors.listOfIndicators?.[index]
                              ?.indicatorName}
                        </FormHelperText>
                        <Typography variant="body2" color="textSecondary">
                          {
                            formik.values.listOfIndicators[index].indicatorName
                              .length
                          }
                          /200
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ width: '395px' }}>
                    <InputLabel
                      // required
                      sx={{
                        fontWeight: '700',
                        color: colorTextBlack,
                        fontSize: '16px',
                        mb: 0.6
                      }}
                    >
                      Enter indicator description
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        name={`listOfIndicators.${index}.indicatorDescription`}
                        variant="outlined"
                        size="small"
                        maxRows={3}
                        sx={{
                          '& textarea': {
                            '&::-webkit-scrollbar': {
                              width: '0.2em'
                            },
                            '&::-webkit-scrollbar-track': {
                              background: '#f5f5f5',
                              width: '3px',
                              borderRadius: '10px'
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: '#4E565F',
                              borderRadius: '10px',
                              border: '2px solid #4E565F'
                            }
                          }
                        }}
                        inputProps={{
                          maxLength: 200
                        }}
                        multiline
                        value={
                          formik.values.listOfIndicators[index]
                            .indicatorDescription
                        }
                        error={
                          formik.touched.listOfIndicators?.[index]
                            ?.indicatorDescription &&
                          Boolean(
                            formik.errors.listOfIndicators?.[index]
                              ?.indicatorDescription
                          )
                        }
                        onChange={formik.handleChange}
                      />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <FormHelperText id="listOfIndicators.${index}.indicatorDescription">
                          {formik.touched.listOfIndicators?.[index]
                            ?.indicatorDescription &&
                            formik.errors.listOfIndicators?.[index]
                              ?.indicatorDescription}
                        </FormHelperText>
                        <Typography variant="body2" color="textSecondary">
                          {
                            formik.values.listOfIndicators[index]
                              .indicatorDescription.length
                          }
                          /200
                        </Typography>
                      </div>
                    </FormControl>
                  </Grid>
                </Box>
              );
            })}
            <Tooltip placement="top" title="Click to add new indicator">
              <Button
                sx={{
                  height: 30,
                  color: isHover ? colorPrimary : colorTextBlack,
                  borderBottom: `1px solid ${isHover ? colorPrimary : colorTextBlack}`,
                  borderRadius: 0
                }}
                onMouseEnter={() => setIshover(true)}
                onMouseLeave={() => setIshover(false)}
                variant="text"
                onClick={() => {
                  handleAddIndicator();
                }}
                size="small"
              >
                <SvgIcon
                  fontSize="small"
                  sx={{ marginRight: 0.6, height: '16px', width: '16px' }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.49996 2.66669V13.3334M3.16663 8.00002H13.8333"
                      stroke={isHover ? colorPrimary : colorTextBlack}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>
                Add indicator
              </Button>
            </Tooltip>

            <Box bgcolor={'white'} padding={2} borderRadius={2} marginTop={2}>
              <Grid display={'flex'} gap={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    if (rubricsByIdData?.rubricId) {
                      navigate('/evaluation-rubrics/rubric-details', {
                        state: { ...location.state }
                      });
                    } else {
                      navigate('/evaluation-rubrics');
                    }
                  }}
                  // style={{ marginRight: 24 }}
                  sx={{
                    borderColor: colorPrimary,
                    borderRadius: '10px',
                    color: colorTextBlack,
                    borderWidth: '2px',
                    height: '42px',
                    width: '106px',

                    '&:hover': {
                      borderColor: colorPrimary,
                      backgroundColor: 'transparent',
                      borderRadius: '10px',
                      borderWidth: '2px'
                    }
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  disabled={!formik.values.rubricName || !formik.isValid}
                  onClick={() => formik.handleSubmit()}
                  sx={{ width: '94px', height: '42px' }}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Box>
          </form>
        </>
      )}

      {showError && <SnackBarBox type={'error'} message={showError} />}

      {showIndicatorAddedMessage && (
        <SnackBarBox type={'success'} message={showIndicatorAddedMessage} />
      )}

      {!isCreateUpdateRubricLoading && isCreateUpdateRubricFail && (
        <SnackBarBox
          type="error"
          message={handleError(createUpdateRubricErrorContainer?.errorMessage)}
        />
      )}

      {!isCreateUpdateRubricLoading && isCreateUpdateRubricSuccess && (
        <SnackBarBox
          type="success"
          message={
            createUpdateRubricResponse || 'Action performed successfully'
          }
        />
      )}
    </Box>
  );
};

export default AddEditRubrics;
