import {
  alpha,
  Autocomplete,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Menu,
  MenuItem,
  Paper,
  Rating,
  Select,
  Skeleton,
  styled,
  SvgIcon,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  MIC_ICON,
  VIDEO_ICON,
  PDF_ICON,
  sceneroy_icon,
  IMG_AVERAGE_SCORE_ANALYTICS_ICON,
  IMG_TOTAL_INDICATORS_ANALYTICS_ICON,
  IMG_TOTAL_OBSERVATIONS_ANALYTICS_ICON
} from '../../utils/imageUrls';
import { Chart } from 'react-google-charts';
import {
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import { useEffect, useState } from 'react';
import {
  Calendar01Icon,
  ChevronRightIcon,
  Copy05Icon,
  Expand05Icon,
  FileDownload03Icon,
  FilterLinesIcon,
  ShareThreeDotsIcon,
  XClose
} from '../../utils/iconSvg';
import {
  getAnalyticsObservations,
  getObservationByUserId,
  getObservationsAnalyticsDetail,
  getTeacherObservationsCount,
  getTeacherObservationsRubric,
  getTeacherObservationsUserAnalytics,
  revertTeacherObservationsAnalytics
} from '../../redux/reducers/analyticsSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  copyToClipboard,
  createTitle,
  isArrayNotEmpty
} from '../../utils/utils';
import { CopyAll, HighlightOff } from '@mui/icons-material';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CustomDataTable from '../../components/CustomDataTable';
import moment from 'moment';
import { analyticsTypeHeaderContent } from '../../pages/ProductAnalysis';
import { getObservations } from '../../redux/reducers/observationSlice';
import { api, apiNoAuth } from '../../config/api';
import SnackBarBox from '../../components/SnackBarBox';
import {
  Legend,
  prepareChartOptionsAndData,
  TileCountComponent
} from './UserAndRoleAnalytics';

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center'
  },
  '& .MuiMenuItem-root': {
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1.5)
    },
    '&:active': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity
      )
    }
  }
}));

const styledMenuItem = {
  border: '1px solid #E4E7EB',
  background: 'inherit',
  marginX: 1,
  borderRadius: 3,
  fontWeight: 700,
  marginY: 1,
  justifyContent: 'center'
};

export const AutocompleteStyled = {
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CBD2D9'
  },
  '&:hover  .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CBD2D9'
  },
  '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
    borderColor: '#CBD2D9'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#CBD2D9'
    },
    '&:hover fieldset': {
      borderColor: '#CBD2D9'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#CBD2D9'
    }
  }
};

const TeacherObservationAnalytics = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState({ anchor: null, id: null });
  const [char1, setChart1] = useState(null);
  const [chart2, setChart2] = useState(null);
  const [chart3, setChart3] = useState(null);
  const [tempDateFilter, setTempDateFilter] = useState({
    dateType: null,
    startDate: null,
    endDate: null
  });
  const [open, setOpen] = useState(null);
  const [indicatorsLoading, setIndicatorsLoading] = useState(false);
  const [usersLoading, setUsersLoading] = useState(false);
  const [showAllObservationList, setShowAllObservationList] = useState(false);
  const [chart, setChart] = useState('');
  const [options, setOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [rubricDateOpen, setRubricDateOpen] = useState(null);
  const [userDateOpen, setUserDateOpen] = useState(null);
  const [chart1Payload, setChart1Payload] = useState({
    indicatorId: null,
    dateType: 'all',
    startDate: null,
    endDate: null
  });
  const [showObservationCard, setShowObservationCard] = useState(null);
  const [chart2Payload, setChart2Payload] = useState({
    userId: null,
    districtId: 'all',
    schoolId: 'all',
    dateType: 'all',
    startDate: null,
    endDate: null
  });
  const [observationCardPayload, setObservationCardPayload] = useState({
    userId: null,
    dateType: 'all',
    startDate: null,
    endDate: null
  });
  const [copySuccess, setCopySuccess] = useState(false);
  const [openCalenderModal, setOpenCalendarModal] = useState({
    startDate: false,
    endDate: false
  });

  const {
    isTeacherObservationsUserAnalyticsLoading,
    teacherObservationsAnalyticsData,
    isTeacherObservationsRubricAnalyticsLoading,
    metaDataDistrict,
    metaDataSchool,
    observationDetail,
    isObservationsLoading,
    observationCardData,
    observationData
  } = useSelector(state => state.analytics);

  useEffect(() => {
    document.title = createTitle('Teacher Observation Analytics');

    let payload = {
      paginationRequest: {
        page: 0,
        size: 0,
        type: 'all',
        search: ''
      }
    };

    fetchOptions('');
    fetchUsersOptions('');
    dispatch(getAnalyticsObservations({ payload: payload }));
    dispatch(getTeacherObservationsCount());

    return () => {
      dispatch(revertTeacherObservationsAnalytics());
    };
  }, []);

  useEffect(() => {
    let payload = {
      indicatorId:
        chart1Payload.indicatorId == 'all'
          ? null
          : chart1Payload.indicatorId?.id,
      dateType: chart1Payload.dateType == 'all' ? null : chart1Payload.dateType,
      startDate: chart1Payload.startDate
        ? dayjs(chart1Payload.startDate).format('YYYY-MM-DD')
        : null,
      endDate: chart1Payload.endDate
        ? dayjs(chart1Payload.endDate).format('YYYY-MM-DD')
        : null
    };
    dispatch(getTeacherObservationsRubric(payload));
  }, [chart1Payload]);

  useEffect(() => {
    let payload = {
      userId: chart2Payload.userId ? chart2Payload.userId?.id : null,
      stateId: null,
      districtId:
        chart2Payload.districtId == 'all' ? null : chart2Payload.districtId,
      schoolId: chart2Payload.schoolId == 'all' ? null : chart2Payload.schoolId,
      dateType: chart2Payload.dateType == 'all' ? null : chart2Payload.dateType,
      startDate: chart2Payload.startDate
        ? dayjs(chart2Payload.startDate).format('YYYY-MM-DD')
        : null,
      endDate: chart2Payload.endDate
        ? dayjs(chart2Payload.endDate).format('YYYY-MM-DD')
        : null
    };

    dispatch(getTeacherObservationsUserAnalytics(payload));
  }, [chart2Payload]);

  useEffect(() => {
    let payload = null;

    if (
      observationCardPayload.userId !== null ||
      observationCardPayload.dateType !== 'all'
    ) {
      payload = {
        userId: observationCardPayload?.userId?.id,
        dateType: observationCardPayload?.dateType,
        startDate: observationCardPayload.startDate
          ? dayjs(observationCardPayload.startDate).format('YYYY-MM-DD')
          : null,
        endDate: observationCardPayload.endDate
          ? dayjs(observationCardPayload.endDate).format('YYYY-MM-DD')
          : null
      };
      dispatch(getObservationByUserId(payload));
    } else if (observationDetail) {
      payload = {
        userId: observationDetail?.userId,
        dateType: null,
        startDate: null,
        endDate: null
      };
      dispatch(getObservationByUserId(payload));
    }
  }, [observationDetail, observationCardPayload]);

  const handleClickOpen = id => {
    setChart(id);
  };

  const handleClose = () => {
    setChart('');
  };

  const char1Options = {
    hAxis: {
      title: 'Date',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      }
    },
    vAxis: {
      title: 'Count',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      },
      viewWindow: {
        min: 0.0 || 0
      },
      ticks: prepareChartOptionsAndData(
        teacherObservationsAnalyticsData.rubricAnalyticsData
      ),
      gridlines: {
        count: 10,
        color: '#F5F7FA',
        units: {
          ones: { count: 0 }
        }
      }
    },
    curveType: 'function',
    series: {
      0: { color: colorPrimary, curveType: 'function' },
      1: { color: '#2F68C4', curveType: 'function' },
      2: { color: '#749E35', curveType: 'function' }
    },
    legend: { position: 'none' }
  };

  const char2Options = {
    hAxis: {
      title: 'Date',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      }
    },
    vAxis: {
      title: 'Count',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      },
      viewWindow: {
        min: 0
      },
      ticks: prepareChartOptionsAndData(
        teacherObservationsAnalyticsData?.userAnalyticsData
      ),
      gridlines: {
        count: 10,
        color: '#F5F7FA',
        units: {
          ones: { count: 0 }
        }
      }
    },
    curveType: 'function',
    series: {
      0: { color: colorPrimary, curveType: 'function' },
      1: { color: '#2F68C4', curveType: 'function' },
      2: { color: '#749E35', curveType: 'function' }
    },
    legend: { position: 'none' }
  };

  const char3Options = {
    hAxis: {
      title: 'Date',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      }
    },
    vAxis: {
      title: 'Count',
      titleTextStyle: {
        fontSize: 14,
        color: '#ABB4BD',
        italic: false,
        bold: true
      },
      viewWindow: {
        min: 0
      },
      // ticks: prepareChartOptionsAndData(observationCardData),
      gridlines: {
        count: 10,
        color: '#F5F7FA',
        units: {
          ones: { count: 0 }
        }
      }
    },
    curveType: 'function',
    series: {
      0: { color: colorPrimary, curveType: 'function' },
      1: { color: '#2F68C4', curveType: 'function' },
      2: { color: '#749E35', curveType: 'function' }
    },
    legend: { position: 'none' }
  };

  const handleShareClick = (event, id) => {
    setAnchorEl({ anchor: event.currentTarget, id: id });
  };

  const handleShareClose = () => {
    setAnchorEl({ anchor: null, id: null });
    setTimeout(() => {
      setCopySuccess(false);
    }, 4000);
  };

  const handleCopyLink = id => {
    let chart1dateTime = `&startDate=${
      chart1Payload.startDate
        ? dayjs(chart1Payload.startDate).format('YYYY-MM-DD')
        : null
    }&endDate=${
      chart1Payload.endDate
        ? dayjs(chart1Payload.endDate).format('YYYY-MM-DD')
        : null
    }`;

    let chart2dateTime = `&startDate=${
      chart2Payload.startDate
        ? dayjs(chart2Payload.startDate).format('YYYY-MM-DD')
        : null
    }&endDate=${
      chart2Payload.endDate
        ? dayjs(chart2Payload.endDate).format('YYYY-MM-DD')
        : null
    }`;

    let chart3dateTime = `&startDate=${
      observationCardPayload.startDate
        ? dayjs(observationCardPayload.startDate).format('YYYY-MM-DD')
        : null
    }&endDate=${
      observationCardPayload.endDate
        ? dayjs(observationCardPayload.endDate).format('YYYY-MM-DD')
        : null
    }`;
    // console.log(observationCardPayload, 'observationCardPayload');
    let chart1ExtraLink = `&indicator=${chart1Payload.indicatorId?.id || null}&name=${chart1Payload.indicatorId?.label || null}&dateType=${chart1Payload.dateType}${chart1Payload.dateType === 'selected_date' ? chart1dateTime : ''}`;
    let chart2ExtraLink = `&name=${chart2Payload.userId?.label || null}&user=${chart2Payload.userId?.id || null}&district=${chart2Payload.districtId}&school=${chart2Payload.schoolId}&dateType=${chart2Payload.dateType}${chart2Payload.dateType === 'selected_date' ? chart2dateTime : ''}`;
    let chart3Extralink = `&userId=${observationDetail?.userId}&searchedId=${observationCardPayload.userId?.id || null}&searchedName=${observationCardPayload.userId?.label || null}&dateType=${observationCardPayload.dateType}${observationCardPayload.dateType === 'selected_date' ? chart3dateTime : ''}`;

    let ids = {
      1: 'rubricChart',
      2: 'observationChart',
      3: 'usersObservationChart'
    };

    let sharableLink = {
      1: chart1ExtraLink.replace(/ /g, '%20'),
      2: chart2ExtraLink.replace(/ /g, '%20'),
      3: chart3Extralink.replace(/ /g, '%20')
    };

    let shareLink = `${process.env.REACT_APP_PORTAL_URL}share-charts?chartType=${ids[id]}${sharableLink[id]}`;
    // let shareLink = `http://localhost:3000/share-charts?chartType=${ids[id]}${sharableLink[id]}`;
    // console.log(shareLink, 'share link');
    navigator.clipboard.writeText(shareLink);
    copyToClipboard(shareLink);
    setCopySuccess(true);
    handleShareClose();
  };

  const fetchUsersOptions = async searchText => {
    try {
      setUsersLoading(true);
      const response = await apiNoAuth.get(
        `/analytics/metadata/search${!searchText ? '' : `?searchCriteria=${searchText}`}`
      );
      if (
        response.data &&
        response.data.payload &&
        response.data.payload.dataList
      ) {
        setUserOptions([...response.data.payload.dataList]);
      } else {
        setUserOptions([]);
      }

      setUsersLoading(false);
    } catch (error) {
      setUsersLoading(false);
    }
  };

  const handleUserInputChange = (event, value) => {
    if (value && (value !== null || value !== '')) {
      fetchUsersOptions(value);
    } else fetchUsersOptions('');
  };

  const fetchOptions = async searchText => {
    try {
      setIndicatorsLoading(true);
      const response = await api.get(
        `rubrics/indicatorsByNameSearch?searchCriteria=${searchText}`
      );
      let indicatorList =
        response.data && response.data.payload
          ? response.data.payload.dataList
          : [] || [];
      setOptions(indicatorList);
      setIndicatorsLoading(false);
    } catch (error) {
      setIndicatorsLoading(false);
    }
  };

  const handleInputChange = (event, value) => {
    if (value && (value !== null || value !== '')) {
      fetchOptions(value);
    } else fetchOptions('');
  };

  const handleDownload = ({ id = null }) => {
    if (id) {
      const chartWrapper =
        (id === 1 && char1) || (id === 2 && chart2) || (id === 3 && chart3);
      const imageURI = chartWrapper.getChart().getImageURI();
      const link = document.createElement('a');
      link.href = imageURI;
      link.download = 'chart.png';
      link.click();
    }
  };
  const handleOpenCalendar = type => {
    setOpenCalendarModal({ ...openCalenderModal, [type]: true });
  };

  const handleCloseCalendar = type => {
    setOpenCalendarModal({ ...openCalenderModal, [type]: false });
  };

  const columns = [
    {
      uinqueKey: 'observationId',
      id: 'userAssessed',
      label: 'User Assessed',
      width: '220px',
      sortable: true,
      render: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {row.userImage ? (
            <Avatar
              src={row.userImage}
              alt={row.name}
              sx={{ width: 32, height: 32, marginRight: 1 }}
            />
          ) : (
            <Avatar
              sx={{
                width: 32,
                height: 32,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: '#c9c9c9',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              {row
                ? row.userAssessed?.split(' ')[0]?.charAt(0).toUpperCase()
                : ''}
            </Avatar>
          )}

          <Typography variant="body2" fontWeight={'600'}>
            {row.userAssessed}
          </Typography>
        </div>
      )
    },
    {
      id: 'reportedBy',
      label: 'Reported By',
      render: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {row.reportedByImage ? (
            <Avatar
              src={row.reportedByImage}
              alt={row.reportedByImage}
              sx={{ width: 32, height: 32, marginRight: 1 }}
            />
          ) : (
            <Avatar
              sx={{
                width: 32,
                height: 32,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: '#c9c9c9',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              {row
                ? row.reportedBy?.split(' ')[0]?.charAt(0).toUpperCase()
                : ''}
            </Avatar>
          )}

          <Typography variant="body2" fontWeight={'600'}>
            {row.reportedBy}
          </Typography>
        </div>
      )
    },
    {
      id: 'ratings',
      label: 'Ratings',
      render: row => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="body1"
            fontSize={'14px'}
            fontWeight={'600'}
            sx={{ marginLeft: '3px' }}
          >
            {row?.ratings?.toFixed(1) || '0.0'}
          </Typography>{' '}
          <Divider orientation="vertical" sx={{ marginX: '5px' }} flexItem />
          <Rating
            size="small"
            name="simple-controlled"
            precision={0.5}
            readOnly
            value={row?.ratings}
          />
        </Box>
      )
    },
    {
      id: 'status',
      label: 'Status',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              fontSize: 12,
              backgroundColor:
                row.observationStatus == 'Completed' ? '#EBF9D9' : '#FDF0E3',
              padding: '4px 8px 4px 8px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: row.observationStatus ? '80px' : '80px',
              height: '22px',
              color:
                row.observationStatus == 'Completed' ? '#749E35' : '#D62828'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor:
                  row.observationStatus == 'Completed' ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.observationStatus}
          </div>
        </>
      )
    },
    {
      id: 'attachments',
      label: 'Attachments',
      render: row => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px'
            }}
          >
            {sceneroy_icon()}{' '}
            <Typography sx={{ marginLeft: '3px' }}>
              {row.imageCount || 0}
            </Typography>
            <Divider orientation="vertical" flexItem sx={{ margin: '6px' }} />
            {MIC_ICON()}{' '}
            <Typography sx={{ marginLeft: '3px' }}>
              {row.audioCount || 0}
            </Typography>
            <Divider orientation="vertical" flexItem sx={{ margin: '6px' }} />
            {VIDEO_ICON()}{' '}
            <Typography sx={{ marginLeft: '3px' }}>
              {row.videoCount || 0}
            </Typography>
            <Divider orientation="vertical" flexItem sx={{ margin: '6px' }} />
            {PDF_ICON()}{' '}
            <Typography sx={{ marginLeft: '3px' }}>
              {row.documentCount || 0}
            </Typography>
          </Box>
        );
      }
    },
    {
      id: 'timeline',
      label: 'Timeline',
      render: row => {
        return (
          <>
            {row.createdDate
              ? moment(row.createdDate).format('DD-MM-YYYY, hh:mm A')
              : ''}
          </>
        );
      }
    }
  ];

  return (
    <>
      {!showAllObservationList && (
        <>
          <Typography mb={2} fontSize={'32px'} fontWeight={'700'}>
            {analyticsTypeHeaderContent['observation']}
          </Typography>
          {/* Cards total counts */}
          <Grid
            container
            component={Paper}
            elevation={0}
            sx={{
              borderRadius: '8px',
              padding: 2,
              display: 'flex',
              width: '100%'
            }}
          >
            <TileCountComponent
              icon={IMG_TOTAL_OBSERVATIONS_ANALYTICS_ICON}
              count={teacherObservationsAnalyticsData.totalObservationCount}
              description={'Total Observations'}
            />
            <TileCountComponent
              icon={IMG_TOTAL_INDICATORS_ANALYTICS_ICON}
              count={teacherObservationsAnalyticsData.totalIndicatorCount}
              description={'Total Indicators'}
            />
            <TileCountComponent
              icon={IMG_AVERAGE_SCORE_ANALYTICS_ICON}
              count={teacherObservationsAnalyticsData.totalObservationAverage}
              description={'Average Score'}
            />
          </Grid>
          {/* Charts */}
          <Grid
            container
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Grid
              sx={{
                borderRadius: '8px',
                padding: '16px',
                backgroundColor: 'white',
                marginRight: 1,
                marginTop: '16px'
              }}
              item
              xs={12}
              sm={12}
              md={12}
              lg={5.9}
              mb={2}
            >
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px'
                }}
              >
                <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
                  Rubric Analytics
                </Typography>
                <Box>
                  <Tooltip title="Resize">
                    <IconButton
                      onClick={() => handleClickOpen(1)}
                      sx={{ mx: 0.5 }}
                    >
                      <SvgIcon sx={{ height: 20, width: 20 }}>
                        <Expand05Icon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download image">
                    <IconButton
                      onClick={() => {
                        handleDownload({ id: 1 });
                      }}
                      sx={{ mr: 0.5 }}
                    >
                      <SvgIcon sx={{ height: 20, width: 20 }}>
                        <FileDownload03Icon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Share">
                    <IconButton onClick={e => handleShareClick(e, 1)}>
                      <SvgIcon sx={{ height: 20, width: 20 }}>
                        <ShareThreeDotsIcon />
                      </SvgIcon>
                    </IconButton>{' '}
                  </Tooltip>

                  <Menu
                    anchorEl={anchorEl.anchor}
                    open={Boolean(anchorEl.id == 1)}
                    onClose={handleShareClose}
                    onClick={handleShareClose}
                  >
                    <MenuItem onClick={() => handleCopyLink(1)}>
                      <SvgIcon fontSize="small" sx={{ marginRight: 2 }}>
                        <Copy05Icon />
                      </SvgIcon>{' '}
                      Copy link
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '28px'
                }}
              >
                <FormControl fullWidth sx={{ marginRight: 1 }}>
                  <Autocomplete
                    size="small"
                    sx={{
                      ...AutocompleteStyled
                    }} // freeSolo
                    loading={indicatorsLoading}
                    value={chart1Payload.indicatorId}
                    onChange={(_, value) => {
                      setChart1Payload({
                        ...chart1Payload,
                        indicatorId: value
                      });
                    }}
                    options={
                      isArrayNotEmpty(options)
                        ? options.map(c => {
                            return {
                              label: c.indicatorName,
                              id: c.indicatorId
                            };
                          })
                        : []
                    }
                    onInputChange={handleInputChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label=""
                        sx={{ ...AutocompleteStyled }}
                        placeholder="Select indicator"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {indicatorsLoading ? 'Loading...' : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                    ListboxProps={{
                      sx: {
                        '& .MuiAutocomplete-option:hover': {
                          backgroundColor: colorSecondary
                        }
                      }
                    }}
                  />
                </FormControl>
                <FormControl variant="outlined" fullWidth size="small">
                  <StyledSelect
                    IconComponent={() => (
                      <SvgIcon sx={{ width: '16px', height: '16px' }}>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14 6.66659H2M10.6667 1.33325V3.99992M5.33333 1.33325V3.99992M5.2 14.6666H10.8C11.9201 14.6666 12.4802 14.6666 12.908 14.4486C13.2843 14.2569 13.5903 13.9509 13.782 13.5746C14 13.1467 14 12.5867 14 11.4666V5.86659C14 4.74648 14 4.18643 13.782 3.7586C13.5903 3.38228 13.2843 3.07632 12.908 2.88457C12.4802 2.66659 11.9201 2.66659 10.8 2.66659H5.2C4.0799 2.66659 3.51984 2.66659 3.09202 2.88457C2.71569 3.07632 2.40973 3.38228 2.21799 3.7586C2 4.18643 2 4.74648 2 5.86659V11.4666C2 12.5867 2 13.1467 2.21799 13.5746C2.40973 13.9509 2.71569 14.2569 3.09202 14.4486C3.51984 14.6666 4.0799 14.6666 5.2 14.6666Z"
                            stroke="#1F2933"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </SvgIcon>
                    )}
                    size="small"
                    open={rubricDateOpen == 1}
                    value={
                      rubricDateOpen == 1
                        ? tempDateFilter.dateType
                        : chart1Payload.dateType
                    }
                    onOpen={(e, va) => {
                      setRubricDateOpen(1);
                      setTempDateFilter({
                        dateType: chart1Payload.dateType,
                        startDate: chart1Payload.startDate,
                        endDate: chart1Payload.endDate
                      });
                    }}
                    onChange={e => {
                      setTempDateFilter({
                        ...tempDateFilter,
                        dateType: e.target.value,
                        startDate: null,
                        endDate: null
                      });
                    }}
                    renderValue={value => {
                      let data =
                        rubricDateOpen === 1
                          ? { ...tempDateFilter }
                          : { ...chart1Payload };

                      if (data.dateType == 'all') {
                        return (
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: '#ABB4BD'
                            }}
                          >
                            <SvgIcon fontSize="small" sx={{ marginRight: 1 }}>
                              <FilterLinesIcon color="#ABB4BD" />
                            </SvgIcon>{' '}
                            Select date
                          </Typography>
                        );
                      }
                      switch (data.dateType) {
                        case 'selected_date':
                          return `${data.startDate ? dayjs(data.startDate).format('DD/MM/YY') : ''} - ${data.endDate ? dayjs(data.endDate).format('DD/MM/YY') : ''}`;
                        case 'Week':
                          return 'Last Week';
                        case 'Month':
                          return 'This Month';
                        case 'past_3_months':
                          return 'Past 3 months';
                        case 'Past_1_Year':
                          return 'Past 1 year';
                        default:
                      }
                    }}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&:hover  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 8
                      }
                    }}
                  >
                    <MenuItem
                      sx={{ ...styledMenuItem, paddingX: 0, border: 'none' }}
                      value="selected_date"
                      disableRipple
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box display={'flex'} margin={'0px 6px'}>
                          <FormControl
                            variant="outlined"
                            // fullWidth
                            size="small"
                            sx={{ width: '185px' }}
                          >
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                mb: 0.5
                              }}
                            >
                              Start Date
                            </Typography>
                            <DatePicker
                              // name="Start-Date"
                              sx={{ marginRight: 1 }}
                              value={dayjs(tempDateFilter.startDate)}
                              onOpen={() => handleOpenCalendar('startDate')}
                              onClose={() => handleCloseCalendar('startDate')}
                              open={openCalenderModal.startDate}
                              format="DD/MM/YYYY"
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  error: false,
                                  InputProps: {
                                    sx: { color: colorTextBlack },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleOpenCalendar('startDate')
                                          }
                                        >
                                          <Calendar01Icon />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }
                                }
                              }}
                              onChange={date =>
                                setTempDateFilter({
                                  ...tempDateFilter,
                                  dateType: 'selected_date',
                                  startDate: date,
                                  endDate: date
                                })
                              }
                              components={{ OpenPickerIcon: () => null }}
                              // components={{
                              //   OpenPickerIcon: props => (
                              //     <IconButton size="small" {...props}>
                              //       <Calendar01Icon />
                              //     </IconButton>
                              //   )
                              // }}
                            />
                          </FormControl>
                          <FormControl
                            variant="outlined"
                            // fullWidth
                            size="small"
                            sx={{ width: '185px' }}
                          >
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                mb: 0.5
                              }}
                            >
                              End Date
                            </Typography>
                            <DatePicker
                              minDate={dayjs(tempDateFilter.startDate)}
                              // label="End Date"
                              value={dayjs(tempDateFilter.endDate)}
                              open={openCalenderModal.endDate}
                              onOpen={() => handleOpenCalendar('endDate')}
                              onClose={() => handleCloseCalendar('endDate')}
                              format="DD/MM/YYYY"
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  error: false,
                                  InputProps: {
                                    sx: { color: colorTextBlack },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleOpenCalendar('endDate')
                                          }
                                        >
                                          <Calendar01Icon />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }
                                }
                              }}
                              onChange={date => {
                                setTempDateFilter({
                                  ...tempDateFilter,
                                  dateType: 'selected_date',
                                  endDate: date
                                });
                              }}
                              components={{ OpenPickerIcon: () => null }}
                              // components={{
                              //   OpenPickerIcon: props => (
                              //     <IconButton size="small" {...props}>
                              //       <Calendar01Icon />
                              //     </IconButton>
                              //   )
                              // }}
                            />
                          </FormControl>
                        </Box>
                      </LocalizationProvider>
                    </MenuItem>

                    <MenuItem sx={styledMenuItem} value="Week" disableRipple>
                      Last Week
                    </MenuItem>
                    <MenuItem sx={styledMenuItem} value="Month" disableRipple>
                      This Month
                    </MenuItem>
                    <MenuItem
                      sx={styledMenuItem}
                      value="past_3_months"
                      disableRipple
                    >
                      Past 3 month
                    </MenuItem>
                    <MenuItem
                      sx={styledMenuItem}
                      value="Past_1_Year"
                      disableRipple
                    >
                      Past 1 year
                    </MenuItem>
                    <MenuItem
                      sx={{
                        border: '1px solid #E4E7EB',
                        backgroundColor: colorPrimary,
                        marginX: 1,
                        borderRadius: 3,
                        fontWeight: 700,
                        justifyContent: 'center',
                        marginY: 1,
                        '&:hover': {
                          background: colorPrimary
                        }
                      }}
                      onClick={() => {
                        if (tempDateFilter.dateType) {
                          setChart1Payload({
                            ...chart1Payload,
                            dateType: tempDateFilter.dateType,
                            startDate: tempDateFilter.startDate,
                            endDate: tempDateFilter.endDate
                          });
                        }
                        setTempDateFilter({
                          dateType: null,
                          endDate: null,
                          startDate: null
                        });
                        setRubricDateOpen(null);
                      }}
                    >
                      Apply
                    </MenuItem>
                    <MenuItem
                      sx={{
                        border: '1px solid #E4E7EB',
                        backgroundColor: colorPrimary,
                        marginX: 1,
                        borderRadius: 3,
                        fontWeight: 700,
                        justifyContent: 'center',
                        marginY: 1,
                        '&:hover': {
                          background: colorPrimary
                        }
                      }}
                      onClick={() => {
                        setChart1Payload({
                          ...chart1Payload,
                          dateType: 'all',
                          startDate: null,
                          endDate: null
                        });
                        setTempDateFilter({
                          startDate: null,
                          dateType: null,
                          endDate: null
                        });
                        setRubricDateOpen(null);
                      }}
                    >
                      Close
                    </MenuItem>
                  </StyledSelect>
                </FormControl>
              </Grid>
              <Grid>
                {isTeacherObservationsRubricAnalyticsLoading && (
                  <Skeleton height={300} width={'100%'} />
                )}
                {!isTeacherObservationsRubricAnalyticsLoading && (
                  <Chart
                    height={'300px'}
                    width={'100%'}
                    chartType="LineChart"
                    data={teacherObservationsAnalyticsData.rubricAnalyticsData}
                    options={char1Options}
                    getChartWrapper={val => {
                      setChart1(val);
                    }}
                  />
                )}
              </Grid>
            </Grid>

            <Grid
              sx={{
                borderRadius: '8px',
                padding: '16px',
                backgroundColor: 'white',
                marginTop: '16px'
              }}
              item
              xs={12}
              sm={12}
              md={12}
              lg={5.9}
              mb={2}
            >
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px'
                }}
              >
                <Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>
                  Users Analytics
                </Typography>
                <div>
                  <Tooltip title="Resize">
                    <IconButton
                      onClick={() => handleClickOpen(2)}
                      sx={{ mx: 0.5 }}
                    >
                      <SvgIcon sx={{ height: 20, width: 20 }}>
                        <Expand05Icon />
                      </SvgIcon>
                    </IconButton>{' '}
                  </Tooltip>
                  <Tooltip title="Download image">
                    <IconButton
                      onClick={() => {
                        handleDownload({ id: 2 });
                      }}
                      sx={{ mr: 0.5 }}
                    >
                      <SvgIcon sx={{ height: 20, width: 20 }}>
                        <FileDownload03Icon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Share">
                    {' '}
                    <IconButton onClick={e => handleShareClick(e, 2)}>
                      <SvgIcon sx={{ height: 20, width: 20 }}>
                        <ShareThreeDotsIcon />
                      </SvgIcon>
                    </IconButton>{' '}
                  </Tooltip>

                  <Menu
                    anchorEl={anchorEl.anchor}
                    open={Boolean(anchorEl.id == 2)}
                    onClose={handleShareClose}
                    onClick={handleShareClose}
                  >
                    <MenuItem onClick={() => handleCopyLink(2)}>
                      <SvgIcon fontSize="small" sx={{ marginRight: 2 }}>
                        <Copy05Icon />
                      </SvgIcon>{' '}
                      Copy link
                    </MenuItem>
                  </Menu>
                </div>
              </Grid>
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px'
                }}
              >
                <FormControl fullWidth sx={{ marginRight: 1 }}>
                  <Autocomplete
                    size="small"
                    sx={{
                      ...AutocompleteStyled
                    }}
                    loading={usersLoading}
                    value={chart2Payload.userId}
                    onChange={(_, value) => {
                      setChart2Payload({
                        ...chart2Payload,
                        userId: value
                      });
                    }}
                    options={
                      isArrayNotEmpty(userOptions)
                        ? [...userOptions].map(c => {
                            return {
                              label: c.name,
                              id: c.userId
                            };
                          })
                        : []
                    }
                    onInputChange={handleUserInputChange}
                    renderInput={params => (
                      <TextField
                        sx={{ ...AutocompleteStyled }}
                        {...params}
                        label=""
                        placeholder="Users"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {usersLoading ? 'Loading...' : null}
                              {params.InputProps.endAdornment}
                            </>
                          )
                        }}
                      />
                    )}
                    ListboxProps={{
                      sx: {
                        '& .MuiAutocomplete-option:hover': {
                          backgroundColor: colorSecondary
                        }
                      }
                    }}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ marginRight: 1 }}>
                  <Select
                    placeholder="Select district"
                    size="small"
                    value={chart2Payload.districtId}
                    onChange={e =>
                      setChart2Payload({
                        ...chart2Payload,
                        districtId: e.target.value,
                        schoolId: 'all'
                      })
                    }
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&:hover  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 8
                      }
                    }}
                    renderValue={val => {
                      let value =
                        isArrayNotEmpty(metaDataDistrict) &&
                        metaDataDistrict.find(
                          (row, key) => row.districtId === val
                        );
                      return val === 'all' ? (
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#ABB4BD'
                          }}
                        >
                          Select district
                        </Typography>
                      ) : (
                        value?.districtName
                      );
                    }}
                  >
                    {isArrayNotEmpty(metaDataDistrict) &&
                      metaDataDistrict.map((row, key) => {
                        return (
                          <MenuItem
                            key={key}
                            value={row?.districtId}
                            sx={{
                              ':hover': {
                                backgroundColor: colorSecondary
                              }
                            }}
                          >
                            {row?.districtName}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ marginRight: 1 }}>
                  <Select
                    size="small"
                    value={chart2Payload.schoolId}
                    onChange={e =>
                      setChart2Payload({
                        ...chart2Payload,
                        schoolId: e.target.value
                      })
                    }
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&:hover  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 8
                      }
                    }}
                    renderValue={val => {
                      let value =
                        isArrayNotEmpty(metaDataSchool) &&
                        metaDataSchool.find((row, key) => row.schoolId === val);

                      return val === 'all' ? (
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#ABB4BD'
                          }}
                        >
                          Select school
                        </Typography>
                      ) : (
                        value?.schoolName
                      );
                    }}
                  >
                    {isArrayNotEmpty(metaDataSchool) &&
                      metaDataSchool
                        .filter(
                          cc => cc.districtId === chart2Payload.districtId
                        )
                        .map((row, key) => {
                          return (
                            <MenuItem
                              key={key}
                              value={row?.schoolId}
                              sx={{
                                ':hover': {
                                  backgroundColor: colorSecondary
                                }
                              }}
                            >
                              {row?.schoolName}
                            </MenuItem>
                          );
                        })}
                    {isArrayNotEmpty(metaDataSchool) &&
                      !metaDataSchool.filter(
                        cc => cc.districtId === chart2Payload.districtId
                      ).length > 0 && <MenuItem value={'all'}>None</MenuItem>}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth size="small">
                  <StyledSelect
                    IconComponent={Calendar01Icon}
                    size="small"
                    open={userDateOpen == 2}
                    value={
                      userDateOpen == 2
                        ? tempDateFilter.dateType
                        : chart2Payload.dateType
                    }
                    onOpen={(e, va) => {
                      setUserDateOpen(2);
                      setTempDateFilter({
                        dateType: chart2Payload.dateType,
                        startDate: chart2Payload.startDate,
                        endDate: chart2Payload.endDate
                      });
                    }}
                    onChange={e => {
                      setTempDateFilter({
                        ...tempDateFilter,
                        dateType: e.target.value,
                        startDate: null,
                        endDate: null
                      });
                    }}
                    renderValue={value => {
                      let data =
                        userDateOpen === 2 ? tempDateFilter : chart2Payload;
                      if (data.dateType === 'all') {
                        return (
                          <Typography
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              color: '#ABB4BD'
                            }}
                          >
                            <SvgIcon fontSize="small" sx={{ marginRight: 1 }}>
                              <FilterLinesIcon color="#ABB4BD" />
                            </SvgIcon>
                          </Typography>
                        );
                      }
                      switch (data.dateType) {
                        case 'selected_date':
                          return `${data.startDate ? dayjs(data.startDate).format('DD/MM/YY') : ''} - ${data.endDate ? dayjs(data.endDate).format('DD/MM/YY') : ''}`;
                        case 'Week':
                          return 'Last Week';
                        case 'Month':
                          return 'This Month';
                        case 'past_3_months':
                          return 'Past 3 months';
                        case 'Past_1_Year':
                          return 'Past 1 year';
                        default:
                          return;
                      }
                    }}
                    // endAdornment={
                    //   chart2Payload.dateType ? (
                    //     <IconButton
                    //       sx={{ marginRight: '10px' }}
                    //       aria-label="clear"
                    //       size="small"
                    //       onClick={() => {
                    //         setChart2Payload({
                    //           ...chart2Payload,
                    //           dateType: 'all',
                    //           startDate: null,
                    //           endDate: null
                    //         });
                    //         setTempDateFilter({
                    //           startDate: null,
                    //           dateType: null,
                    //           endDate: null
                    //         });
                    //       }}
                    //       edge="end"
                    //     >
                    //       <HighlightOff sx={{ color: '#ABB4BD' }} />
                    //     </IconButton>
                    //   ) : null
                    // }
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&:hover  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#CBD2D9'
                      },
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 8
                      }
                    }}
                  >
                    <MenuItem
                      sx={{ ...styledMenuItem, paddingX: 0, border: 'none' }}
                      value="selected_date"
                      disableRipple
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box display={'flex'} margin={'0px 6px'}>
                          <FormControl
                            variant="outlined"
                            // fullWidth
                            size="small"
                            sx={{ width: '185px' }}
                          >
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                mb: 0.5
                              }}
                            >
                              Start Date
                            </Typography>
                            <DatePicker
                              // name="Start-Date"
                              sx={{ marginRight: 1 }}
                              value={dayjs(tempDateFilter.startDate)}
                              onOpen={() => handleOpenCalendar('startDate')}
                              onClose={() => handleCloseCalendar('startDate')}
                              open={openCalenderModal.startDate}
                              format="DD/MM/YYYY"
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  error: false,
                                  InputProps: {
                                    sx: { color: colorTextBlack },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleOpenCalendar('startDate')
                                          }
                                        >
                                          <Calendar01Icon />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }
                                }
                              }}
                              onChange={date =>
                                setTempDateFilter({
                                  ...tempDateFilter,
                                  dateType: 'selected_date',
                                  startDate: date,
                                  endDate: date
                                })
                              }
                              components={{ OpenPickerIcon: () => null }}
                              // components={{
                              //   OpenPickerIcon: props => (
                              //     <IconButton size="small" {...props}>
                              //       <Calendar01Icon />
                              //     </IconButton>
                              //   )
                              // }}
                            />
                          </FormControl>
                          <FormControl
                            variant="outlined"
                            // fullWidth
                            size="small"
                            sx={{ width: '185px' }}
                          >
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                mb: 0.5
                              }}
                            >
                              End Date
                            </Typography>
                            <DatePicker
                              minDate={dayjs(tempDateFilter.startDate)}
                              // label="End Date"
                              value={dayjs(tempDateFilter.endDate)}
                              open={openCalenderModal.endDate}
                              onOpen={() => handleOpenCalendar('endDate')}
                              onClose={() => handleCloseCalendar('endDate')}
                              format="DD/MM/YYYY"
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  error: false,
                                  InputProps: {
                                    sx: { color: colorTextBlack },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton
                                          size="small"
                                          onClick={() =>
                                            handleOpenCalendar('endDate')
                                          }
                                        >
                                          <Calendar01Icon />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }
                                }
                              }}
                              onChange={date => {
                                setTempDateFilter({
                                  ...tempDateFilter,
                                  dateType: 'selected_date',
                                  endDate: date
                                });
                              }}
                              components={{ OpenPickerIcon: () => null }}
                              // components={{
                              //   OpenPickerIcon: props => (
                              //     <IconButton size="small" {...props}>
                              //       <Calendar01Icon />
                              //     </IconButton>
                              //   )
                              // }}
                            />
                          </FormControl>
                        </Box>
                      </LocalizationProvider>
                    </MenuItem>

                    <MenuItem sx={styledMenuItem} value="Week" disableRipple>
                      Last Week
                    </MenuItem>
                    <MenuItem sx={styledMenuItem} value="Month" disableRipple>
                      This Month
                    </MenuItem>
                    <MenuItem
                      sx={styledMenuItem}
                      value="past_3_months"
                      disableRipple
                    >
                      Past 3 month
                    </MenuItem>
                    <MenuItem
                      sx={styledMenuItem}
                      value="Past_1_Year"
                      disableRipple
                    >
                      Past 1 year
                    </MenuItem>
                    <MenuItem
                      sx={{
                        border: '1px solid #E4E7EB',
                        backgroundColor: colorPrimary,
                        marginX: 1,
                        borderRadius: 3,
                        fontWeight: 700,
                        justifyContent: 'center',
                        marginY: 1,
                        '&:hover': {
                          background: colorPrimary
                        }
                      }}
                      onClick={() => {
                        if (tempDateFilter.dateType) {
                          setChart2Payload({
                            ...chart2Payload,
                            dateType: tempDateFilter.dateType,
                            startDate: tempDateFilter.startDate,
                            endDate: tempDateFilter.endDate
                          });
                        }
                        setTempDateFilter({
                          dateType: null,
                          endDate: null,
                          startDate: null
                        });
                        setUserDateOpen(null);
                      }}
                    >
                      Apply
                    </MenuItem>
                    <MenuItem
                      sx={{
                        border: '1px solid #E4E7EB',
                        backgroundColor: colorPrimary,
                        marginX: 1,
                        borderRadius: 3,
                        fontWeight: 700,
                        justifyContent: 'center',
                        marginY: 1,
                        '&:hover': {
                          background: colorPrimary
                        }
                      }}
                      onClick={() => {
                        setChart2Payload({
                          ...chart2Payload,
                          dateType: 'all',
                          startDate: null,
                          endDate: null
                        });
                        setTempDateFilter({
                          startDate: null,
                          dateType: null,
                          endDate: null
                        });
                        setUserDateOpen(null);
                      }}
                    >
                      Close
                    </MenuItem>
                  </StyledSelect>
                </FormControl>
              </Grid>
              <Grid
                item
                sx={{ display: 'flex', alignSelf: 'end', width: '100%' }}
              >
                <Legend
                  series={
                    teacherObservationsAnalyticsData.userAnalyticsData?.length >
                    0
                      ? teacherObservationsAnalyticsData.userAnalyticsData[0]
                          .slice(1)
                          .map((name, index) => {
                            return {
                              name,
                              color: char2Options.series[index].color
                            };
                          })
                      : []
                  }
                />
              </Grid>
              <Grid>
                {isTeacherObservationsUserAnalyticsLoading && (
                  <Skeleton height={300} width={'100%'} />
                )}
                {!isTeacherObservationsUserAnalyticsLoading && (
                  <Chart
                    height={'300px'}
                    width={'100%'}
                    chartType="LineChart"
                    data={teacherObservationsAnalyticsData?.userAnalyticsData}
                    options={char2Options}
                    getChartWrapper={val => {
                      setChart2(val);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* List of observations */}
          <Grid
            container
            display={'flex'}
            width={'100%'}
            flexDirection={'column'}
            sx={{ borderRadius: '8px', padding: 2, bgcolor: 'white' }}
          >
            <Box display={'flex'} justifyContent="space-between" mb={2}>
              <Typography fontSize={20} fontWeight="700">
                List of Observations
              </Typography>
              <Button
                onClick={() => {
                  setShowAllObservationList(true);
                }}
                variant="outlined"
                sx={{
                  borderColor: colorPrimary,
                  borderRadius: '10px',
                  color: 'black',
                  borderWidth: '2px',
                  marginRight: 1,
                  fontSize: '12px',
                  fontWeight: '700',
                  width: '134px',
                  height: '36px',
                  '&:hover': {
                    borderColor: colorPrimary,
                    backgroundColor: 'transparent',
                    borderRadius: '10px',
                    borderWidth: '2px'
                  }
                }}
              >
                All observations
              </Button>
            </Box>
            {isObservationsLoading && <Skeleton height={33} width={'100%'} />}
            {!isObservationsLoading && (
              <CustomDataTable
                columns={columns}
                allowSelection={false}
                allowPagination={false}
                data={
                  observationData?.observation.length > 0
                    ? observationData.observation
                    : []
                }
                defaultOrderBy={'createdDate'}
                defaultPage={0}
                defaultRowsPerPage={4}
                totalCount={0}
                hideEmptyRows={true}
                onRowClick={row => {
                  setShowAllObservationList(true);
                  setShowObservationCard(row);
                  dispatch(getObservationsAnalyticsDetail(row?.observationId));
                }}
              />
            )}
          </Grid>
        </>
      )}
      {showAllObservationList && (
        <>
          {!showObservationCard && (
            <>
              <Typography fontSize={20} fontWeight="700">
                {/* List of Observations */}
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={
                    <SvgIcon sx={{ height: 24, width: 24 }}>
                      <ChevronRightIcon color={colorTextGrey} />
                    </SvgIcon>
                  }
                >
                  <Link
                    color="inherit"
                    href={'Analytics'}
                    onClick={event => {
                      event.preventDefault();
                      // navigate('/district');
                      setShowAllObservationList(null);
                      setShowObservationCard(null);
                    }}
                    sx={{ textDecoration: 'none' }}
                  >
                    <Typography
                      fontWeight={'700'}
                      color={colorLinkGrey}
                      fontSize={'24px'}
                    >
                      Analytics
                    </Typography>
                  </Link>
                  <Typography
                    sx={{
                      fontSize: '32px',
                      fontWeight: '700',
                      color: colorTextBlack
                    }}
                  >
                    List of Observations
                  </Typography>
                </Breadcrumbs>
              </Typography>

              <Grid
                mt={2}
                container
                display={'flex'}
                width={'100%'}
                flexDirection={'column'}
                sx={{ borderRadius: '8px', padding: 2, bgcolor: 'white' }}
              >
                {isObservationsLoading && (
                  <Skeleton height={33} width={'100%'} />
                )}
                {!isObservationsLoading && (
                  <CustomDataTable
                    columns={columns}
                    allowSelection={false}
                    allowPagination={false}
                    data={
                      observationData?.observation.length > 0
                        ? observationData.observation
                        : []
                    }
                    defaultOrderBy={'createdDate'}
                    defaultPage={0}
                    defaultRowsPerPage={observationData.totalCount}
                    totalCount={observationData.totalCount}
                    hideEmptyRows={true}
                    onRowClick={row => {
                      setShowAllObservationList(true);
                      setShowObservationCard(row);
                      dispatch(
                        getObservationsAnalyticsDetail(row?.observationId)
                      );
                    }}
                  />
                )}
              </Grid>
            </>
          )}
          {showObservationCard && (
            <>
              <Typography mb={2} variant="h5" fontWeight={'600'}>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={
                    <SvgIcon sx={{ height: 24, width: 24 }}>
                      <ChevronRightIcon color={colorTextGrey} />
                    </SvgIcon>
                  }
                >
                  <Link
                    color="inherit"
                    href={'Analytics'}
                    onClick={event => {
                      event.preventDefault();
                      // navigate('/district');
                      // setShowAllObservationList(null);
                      setShowObservationCard(null);
                    }}
                    sx={{ textDecoration: 'none' }}
                  >
                    <Typography
                      fontWeight={'700'}
                      color={colorLinkGrey}
                      fontSize={'24px'}
                    >
                      List of Observations
                    </Typography>
                  </Link>
                  <Typography
                    sx={{
                      fontSize: '32px',
                      fontWeight: '700',
                      color: colorTextBlack
                    }}
                  >
                    Observation
                  </Typography>
                </Breadcrumbs>
              </Typography>
              <Grid container borderRadius={2} padding={2} bgcolor={'white'}>
                <Grid item display={'flex'} lg={12} alignItems={'center'}>
                  <Grid marginRight={2}>
                    {observationDetail?.userImage ? (
                      <Avatar
                        src={observationDetail?.userImage}
                        alt={observationDetail?.userName}
                        sx={{ width: 60, height: 60, marginRight: 1 }}
                      />
                    ) : (
                      <Avatar
                        sx={{
                          width: 60,
                          height: 60,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '50%',
                          backgroundColor: '#c9c9c9',
                          color: 'white',

                          marginRight: '5px'
                        }}
                      >
                        {observationDetail
                          ? observationDetail.userName
                              ?.split(' ')
                              .map(word => word[0])
                              .join('')
                              .toUpperCase()
                          : ''}
                      </Avatar>
                    )}
                  </Grid>
                  <Grid display={'flex'}>
                    <p>
                      <span style={{ display: 'flex' }}>
                        <Typography fontWeight={'700'} fontSize="24px">
                          {observationDetail?.userName} &nbsp;
                        </Typography>
                        <Typography
                          fontWeight={'700'}
                          fontSize="24px"
                          color={colorTextGrey}
                        >
                          ( {observationDetail?.userGroup} )
                        </Typography>
                      </span>

                      <Box
                        style={{
                          fontWeight: 700,
                          fontSize: '32px',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        sx={{ marginY: 1 }}
                      >
                        <Rating
                          name="simple-controlled"
                          precision={0.5}
                          value={
                            observationDetail?.observationAvgRatings
                              ? observationDetail.observationAvgRatings
                              : 0
                          }
                          readOnly
                        />
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ margin: '3px' }}
                        />
                        <Typography
                          fontWeight={'700'}
                          fontSize="24px"
                          color={colorLinkGrey}
                        >
                          {observationDetail?.observationAvgRatings
                            ? `${observationDetail.observationAvgRatings}/5`
                            : 0}
                        </Typography>
                      </Box>
                    </p>
                  </Grid>
                </Grid>
                <Grid
                  item
                  display={'flex'}
                  justifyContent={'space-between'}
                  lg={12}
                  mb={1}
                >
                  <Grid item display={'flex'}>
                    <FormControl
                      sx={{ minWidth: 300, marginRight: 1 }}
                      variant="outlined"
                      size="small"
                    >
                      <StyledSelect
                        size="small"
                        IconComponent={Calendar01Icon}
                        open={open === 2}
                        value={
                          open === 2
                            ? tempDateFilter.dateType
                            : observationCardPayload.dateType
                        }
                        onOpen={(e, va) => {
                          setOpen(2);
                          setTempDateFilter({
                            dateType: observationCardPayload.dateType,
                            startDate: observationCardPayload.startDate,
                            endDate: observationCardPayload.endDate
                          });
                        }}
                        onChange={e => {
                          setTempDateFilter({
                            ...tempDateFilter,
                            dateType: e.target.value,
                            startDate: null,
                            endDate: null
                          });
                        }}
                        renderValue={value => {
                          let data =
                            open === 2
                              ? tempDateFilter
                              : observationCardPayload;

                          if (data.dateType == 'all') {
                            return (
                              <Typography
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  color: '#ABB4BD'
                                }}
                              >
                                <SvgIcon
                                  fontSize="small"
                                  sx={{ marginRight: 1 }}
                                >
                                  <FilterLinesIcon color="#ABB4BD" />
                                </SvgIcon>
                                Select date
                              </Typography>
                            );
                          }
                          switch (data.dateType) {
                            case 'selected_date':
                              return `${data.startDate ? dayjs(data.startDate).format('DD/MM/YY') : ''} - ${data.endDate ? dayjs(data.endDate).format('DD/MM/YY') : ''}`;
                            case 'Week':
                              return 'Last Week';
                            case 'Month':
                              return 'This Month';
                            case 'past_3_months':
                              return 'Past 3 months';
                            case 'Past_1_Year':
                              return 'Past 1 year';
                            default:
                              return;
                          }
                        }}
                        endAdornment={
                          observationCardPayload.dateType ? (
                            <IconButton
                              sx={{ marginRight: '10px' }}
                              aria-label="clear"
                              size="small"
                              onClick={() => {
                                setObservationCardPayload({
                                  ...observationCardPayload,
                                  dateType: 'all',
                                  startDate: null,
                                  endDate: null
                                });
                                setTempDateFilter({
                                  startDate: null,
                                  dateType: null,
                                  endDate: null
                                });
                              }}
                              edge="end"
                            >
                              <HighlightOff sx={{ color: '#ABB4BD' }} />
                            </IconButton>
                          ) : null
                        }
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#CBD2D9'
                          },
                          '&:hover  .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#CBD2D9'
                          },
                          '&.Mui-focused  .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#CBD2D9'
                          },
                          '& .MuiOutlinedInput-root': {
                            borderRadius: 8
                          }
                        }}
                      >
                        <MenuItem
                          sx={{
                            ...styledMenuItem,
                            paddingX: 0,
                            border: 'none'
                          }}
                          value="selected_date"
                          disableRipple
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box display={'flex'} margin={'0px 6px'}>
                              <FormControl
                                variant="outlined"
                                // fullWidth
                                size="small"
                                sx={{ width: '185px' }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    mb: 0.5
                                  }}
                                >
                                  Start Date
                                </Typography>
                                <DatePicker
                                  // name="Start-Date"
                                  sx={{ marginRight: 1 }}
                                  value={dayjs(tempDateFilter.startDate)}
                                  onOpen={() => handleOpenCalendar('startDate')}
                                  onClose={() =>
                                    handleCloseCalendar('startDate')
                                  }
                                  open={openCalenderModal.startDate}
                                  format="DD/MM/YYYY"
                                  slotProps={{
                                    textField: {
                                      size: 'small',
                                      error: false,
                                      InputProps: {
                                        sx: { color: colorTextBlack },
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <IconButton
                                              size="small"
                                              onClick={() =>
                                                handleOpenCalendar('startDate')
                                              }
                                            >
                                              <Calendar01Icon />
                                            </IconButton>
                                          </InputAdornment>
                                        )
                                      }
                                    }
                                  }}
                                  onChange={date =>
                                    setTempDateFilter({
                                      ...tempDateFilter,
                                      dateType: 'selected_date',
                                      startDate: date,
                                      endDate: date
                                    })
                                  }
                                  components={{ OpenPickerIcon: () => null }}
                                  // components={{
                                  //   OpenPickerIcon: props => (
                                  //     <IconButton size="small" {...props}>
                                  //       <Calendar01Icon />
                                  //     </IconButton>
                                  //   )
                                  // }}
                                />
                              </FormControl>
                              <FormControl
                                variant="outlined"
                                // fullWidth
                                size="small"
                                sx={{ width: '185px' }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    mb: 0.5
                                  }}
                                >
                                  End Date
                                </Typography>
                                <DatePicker
                                  minDate={dayjs(tempDateFilter.startDate)}
                                  // label="End Date"
                                  value={dayjs(tempDateFilter.endDate)}
                                  open={openCalenderModal.endDate}
                                  onOpen={() => handleOpenCalendar('endDate')}
                                  onClose={() => handleCloseCalendar('endDate')}
                                  format="DD/MM/YYYY"
                                  slotProps={{
                                    textField: {
                                      size: 'small',
                                      error: false,
                                      InputProps: {
                                        sx: { color: colorTextBlack },
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <IconButton
                                              size="small"
                                              onClick={() =>
                                                handleOpenCalendar('endDate')
                                              }
                                            >
                                              <Calendar01Icon />
                                            </IconButton>
                                          </InputAdornment>
                                        )
                                      }
                                    }
                                  }}
                                  onChange={date => {
                                    setTempDateFilter({
                                      ...tempDateFilter,
                                      dateType: 'selected_date',
                                      endDate: date
                                    });
                                  }}
                                  components={{ OpenPickerIcon: () => null }}
                                  // components={{
                                  //   OpenPickerIcon: props => (
                                  //     <IconButton size="small" {...props}>
                                  //       <Calendar01Icon />
                                  //     </IconButton>
                                  //   )
                                  // }}
                                />
                              </FormControl>
                            </Box>
                          </LocalizationProvider>
                        </MenuItem>

                        <MenuItem
                          sx={styledMenuItem}
                          value="Week"
                          disableRipple
                        >
                          Last Week
                        </MenuItem>
                        <MenuItem
                          sx={styledMenuItem}
                          value="Month"
                          disableRipple
                        >
                          This Month
                        </MenuItem>
                        <MenuItem
                          sx={styledMenuItem}
                          value="past_3_months"
                          disableRipple
                        >
                          Past 3 month
                        </MenuItem>
                        <MenuItem
                          sx={styledMenuItem}
                          value="Past_1_Year"
                          disableRipple
                        >
                          Past 1 year
                        </MenuItem>
                        <MenuItem
                          sx={{
                            border: '1px solid #E4E7EB',
                            backgroundColor: colorPrimary,
                            marginX: 1,
                            borderRadius: 3,
                            fontWeight: 700,
                            justifyContent: 'center',
                            marginY: 1,
                            '&:hover': {
                              background: colorPrimary
                            }
                          }}
                          onClick={() => {
                            if (tempDateFilter.dateType) {
                              setObservationCardPayload({
                                ...observationCardPayload,
                                dateType: tempDateFilter.dateType,
                                startDate: tempDateFilter.startDate,
                                endDate: tempDateFilter.endDate
                              });
                            }
                            setTempDateFilter({
                              dateType: null,
                              endDate: null,
                              startDate: null
                            });
                            setOpen(null);
                          }}
                        >
                          Apply
                        </MenuItem>
                        <MenuItem
                          sx={{
                            border: '1px solid #E4E7EB',
                            backgroundColor: colorPrimary,
                            marginX: 1,
                            borderRadius: 3,
                            fontWeight: 700,
                            justifyContent: 'center',
                            marginY: 1,
                            '&:hover': {
                              background: colorPrimary
                            }
                          }}
                          onClick={() => {
                            setObservationCardPayload({
                              ...observationCardPayload,
                              dateType: 'all',
                              startDate: null,
                              endDate: null
                            });
                            setTempDateFilter({
                              startDate: null,
                              dateType: null,
                              endDate: null
                            });
                            setOpen(null);
                          }}
                        >
                          Close
                        </MenuItem>
                      </StyledSelect>
                    </FormControl>
                    <FormControl sx={{ minWidth: 250 }}>
                      <Autocomplete
                        size="small"
                        sx={{
                          ...AutocompleteStyled
                        }}
                        loading={usersLoading}
                        value={observationCardPayload.userId}
                        onChange={(_, value) => {
                          setObservationCardPayload({
                            ...observationCardPayload,
                            userId: value
                          });
                        }}
                        options={
                          isArrayNotEmpty(userOptions)
                            ? userOptions.map(c => {
                                return {
                                  label: c.name,
                                  id: c.userId
                                };
                              })
                            : []
                        }
                        onInputChange={handleUserInputChange}
                        renderInput={params => (
                          <TextField
                            sx={{
                              ...AutocompleteStyled
                            }}
                            {...params}
                            label=""
                            placeholder="Users"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {usersLoading ? 'Loading...' : null}
                                  {params.InputProps.endAdornment}
                                </>
                              )
                            }}
                          />
                        )}
                        ListboxProps={{
                          sx: {
                            '& .MuiAutocomplete-option:hover': {
                              backgroundColor: colorSecondary
                            }
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item sx={{ display: 'flex', alignSelf: 'end' }}>
                    <Tooltip title="Resize">
                      <IconButton
                        onClick={() => handleClickOpen(3)}
                        sx={{ mx: 0.5 }}
                      >
                        <SvgIcon sx={{ height: 20, width: 20 }}>
                          <Expand05Icon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Download image">
                      <IconButton
                        onClick={() => {
                          handleDownload({ id: 3 });
                        }}
                        sx={{ mr: 0.5 }}
                      >
                        <SvgIcon sx={{ height: 20, width: 20 }}>
                          <FileDownload03Icon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Share">
                      <IconButton onClick={e => handleShareClick(e, 3)}>
                        <SvgIcon sx={{ height: 20, width: 20 }}>
                          <ShareThreeDotsIcon />
                        </SvgIcon>
                      </IconButton>{' '}
                    </Tooltip>

                    <Menu
                      anchorEl={anchorEl.anchor}
                      open={Boolean(anchorEl.id == 3)}
                      onClose={handleShareClose}
                      onClick={handleShareClose}
                    >
                      <MenuItem onClick={() => handleCopyLink(3)}>
                        <SvgIcon fontSize="small" sx={{ marginRight: 2 }}>
                          <Copy05Icon />
                        </SvgIcon>{' '}
                        Copy link
                      </MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Legend
                    series={
                      isArrayNotEmpty(observationCardData)
                        ? observationCardData[0]
                            .slice(1)
                            .map((name, index) => ({
                              name,
                              color: char3Options.series[index].color
                            }))
                        : []
                    }
                  />
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Chart
                    height={'300px'}
                    width={'100%'}
                    chartType="LineChart"
                    data={observationCardData}
                    options={char3Options}
                    getChartWrapper={val => {
                      setChart3(val);
                    }}
                  />{' '}
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
      <Dialog
        sx={{ borderRadius: '8px' }}
        open={chart !== ''}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ borderRadius: 10 }}>
          <Box textAlign={'end'}>
            <IconButton size="small" onClick={handleClose}>
              <SvgIcon sx={{ height: 20, width: 20 }}>
                <XClose />
              </SvgIcon>
            </IconButton>
            {(chart == 2 || chart == 3) && (
              <Legend
                series={
                  chart === 3
                    ? observationCardData.length > 0
                      ? observationCardData[0].slice(1).map((name, index) => ({
                          name,
                          color: char3Options.series[index].color
                        }))
                      : []
                    : teacherObservationsAnalyticsData.userAnalyticsData
                          .length > 0
                      ? teacherObservationsAnalyticsData.userAnalyticsData[0]
                          .slice(1)
                          .map((name, index) => ({
                            name,
                            color: char2Options.series[index].color
                          }))
                      : []
                }
              />
            )}
          </Box>
          {chart == 1 && (
            <Chart
              height={'60vh'}
              width={'100%'}
              chartType="LineChart"
              data={teacherObservationsAnalyticsData.rubricAnalyticsData}
              options={char1Options}
            />
          )}
          {chart == 2 && (
            <Chart
              height={'60vh'}
              width={'100%'}
              chartType="LineChart"
              data={teacherObservationsAnalyticsData.userAnalyticsData}
              options={char2Options}
            />
          )}
          {chart == 3 && (
            <Chart
              height={'60vh'}
              width={'100%'}
              chartType="LineChart"
              data={observationCardData}
              options={char3Options}
            />
          )}
        </DialogContent>
      </Dialog>
      {copySuccess && <SnackBarBox message="Link copied!" type={'success'} />}{' '}
    </>
  );
};

export default TeacherObservationAnalytics;
