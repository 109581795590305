import {
  Autocomplete,
  Avatar,
  Button,
  Grid,
  MenuItem,
  Paper,
  Rating,
  TextField,
  Typography,
  Skeleton,
  styled,
  Select,
  alpha,
  FormControl,
  InputLabel,
  ListItem,
  ListItemText,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  Icon,
  Tooltip
} from '@mui/material';
import {
  sceneroy_icon,
  MIC_ICON,
  PDF_ICON,
  VIDEO_ICON
} from '../utils/imageUrls';
import CustomDataTable from '../components/CustomDataTable';
import { useNavigate } from 'react-router-dom';
import {
  createTitle,
  generateImageSrc,
  handleError,
  isArrayNotEmpty
} from '../utils/utils';
import {
  Add,
  Clear,
  ClearAllRounded,
  Highlight,
  HighlightOff
} from '@mui/icons-material';
import {
  colorLightYellow,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../config/theme';
import { useEffect, useRef, useState } from 'react';
import { setActiveModule } from '../redux/reducers/layoutSlice';
import { useDispatch } from 'react-redux';
import { getUserList } from '../redux/reducers/usersSlice';
import { getUserGroupsList } from '../redux/reducers/userGroupsSlice';
import { useSelector } from 'react-redux';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  deleteObservation,
  getObservations,
  revertObservationFormData
} from '../redux/reducers/observationSlice';
import SnackBarBox from '../components/SnackBarBox';
import moment from 'moment';
import ConfirmBox from '../components/ConfirmBox';
import { Calendar01Icon, FilterLinesIcon, TrashIcon } from '../utils/iconSvg';

export const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    display: 'flex',
    alignItems: 'center'
  },
  '& .MuiMenuItem-root': {
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1.5)
    },
    '&:active': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity
      )
    }
  }
}));

export const styledMenuItem = {
  border: '1px solid #E4E7EB',
  background: 'inherit',
  marginX: 1,
  borderRadius: 3,
  fontWeight: 700,
  marginY: 1,
  justifyContent: 'center'
};

// const menuProps = {
//   PaperProps: {
//     onMouseDown: event => {
//       event.stopPropagation();
//     }
//   }
// };

function ObservationReports() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const selectRef = useRef(null);
  // const modalRef = useRef(null)
  const [ratingDropDown, setRatingDropdown] = useState(false);
  const [loading, setLoading] = useState(true);
  const ratingsData = Array.from({ length: 5 }, (_, index) => ({
    id: index + 1,
    label: index + 1
  }));
  const [filterOptions, setFilterOptions] = useState({
    type: '',
    id: null,
    dateType: 'all',
    ratingFrom: null,
    startDate: null, //dayjs(new Date()),
    endDate: null //dayjs(new Date())
  });
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [tempDateFilter, setTempDateFilter] = useState({
    dateType: 'all',
    startDate: null,
    endDate: null
  });
  const [initialValues, setInitialValues] = useState({
    size: 10,
    page: 0,
    type: 'all'
  });
  const [selectedList, setSelectedList] = useState([]);
  const [confirmation, setConfimation] = useState(null);
  const [options, setOptions] = useState([]);
  const [openCalenderModal, setOpenCalendarModal] = useState({
    startDate: false,
    endDate: false
  });

  const { usersData } = useSelector(state => state.users);
  const { userGroupsData } = useSelector(state => state.userGroups);
  const {
    isCreateUpdateObservationLoading,
    isCreateUpdateObservationSuccess,
    createUpdateObservationResponse,
    createUpdateObservationErrorContainer,
    isObservationsFailed,
    observationData,
    isObservationsSuccess,
    isObservationsLoading,
    deleteObservationLoading,
    deleteObservationFailed,
    deleteObservationErrorContainer,
    deleteObservationSuccess
  } = useSelector(state => state.observation);

  const handleIconClick = () => {
    setRatingDropdown(prev => !prev); // Toggle open state
  };

  const handleClose = () => {
    setRatingDropdown(false); // Close dropdown when selecting an item
  };

  useEffect(() => {
    setLoading(true);
    let mastersPayload = {
      payload: {
        type: 'all',
        page: 0,
        size: 0
      },
      search: ''
    };
    document.title = createTitle('Classroom Observations');
    dispatch(setActiveModule('Classroom Observations'));
    dispatch(getUserList(mastersPayload));
    dispatch(getUserGroupsList(mastersPayload));

    return () => {
      dispatch(revertObservationFormData());
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    handleApplyFilter('apply');
  }, [
    initialValues.page,
    initialValues.size,
    deleteObservationLoading,
    filterOptions.id,
    filterOptions.ratingFrom,
    filterOptions.dateType,
    filterOptions.startDate,
    filterOptions.endDate
  ]);

  // const handleClickOutside = event => {
  //   if (selectRef.current && !selectRef.current.contains(event.target)) {
  //     setOpen(false);
  //   }
  // };
  // const handleClickOutside = (event) => {
  //   // Check if click is outside the modal
  //   if (modalRef.current && !modalRef.current.contains(event.target)){
  //     setOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   if (open) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //   } else {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [open]);

  const handleOpenCalendar = type => {
    setOpenCalendarModal({ ...openCalenderModal, [type]: true });
  };

  const handleCloseCalendar = type => {
    setOpenCalendarModal({ ...openCalenderModal, [type]: false });
  };

  const columns = [
    {
      uinqueKey: 'observationId',
      id: 'userAssessed',
      label: 'User Assessed',
      width: '200px',
      sortable: true,
      render: row => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '16px',
            width: '16px'
          }}
        >
          {row.userImage ? (
            <Avatar
              src={row.userImage}
              alt={row.name}
              sx={{ width: 32, height: 32, marginRight: 1 }}
            />
          ) : (
            <Avatar
              sx={{
                width: 32,
                height: 32,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: '#c9c9c9',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              {row
                ? row.userAssessed?.split(' ')[0]?.charAt(0).toUpperCase()
                : ''}
            </Avatar>
          )}

          <Typography variant="body2" fontWeight={'600'}>
            {row.userAssessed}
          </Typography>
        </div>
      )
    },
    {
      id: 'reportedBy',
      label: 'Reported By',
      width: '200px',

      render: row => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '16px',
            width: '16px'
          }}
        >
          {row.reportedByImage ? (
            <Avatar
              src={row.reportedByImage}
              alt={row.reportedByImage}
              sx={{ width: 32, height: 32, marginRight: 1 }}
            />
          ) : (
            <Avatar
              sx={{
                width: 32,
                height: 32,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: '#c9c9c9',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              {row
                ? row.reportedBy?.split(' ')[0]?.charAt(0).toUpperCase()
                : ''}
            </Avatar>
          )}

          <Typography variant="body2" fontWeight={'600'}>
            {row.reportedBy}
          </Typography>
        </div>
      )
    },
    {
      id: 'ratings',
      label: 'Ratings',
      render: row => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography
            sx={{
              marginLeft: '3px',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '20px',
              color: '#4E565F'
            }}
          >
            {row?.ratings?.toFixed(1) || '0.0'}
          </Typography>{' '}
          <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
          <Rating
            size="small"
            name="simple-controlled"
            precision={0.5}
            readOnly
            value={row?.ratings}
          />
        </Box>
      )
    },
    {
      id: 'status',
      label: 'Status',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              fontSize: '12px',
              width: row.observationStatus == 'Completed' ? '80px' : '71px',
              backgroundColor:
                row.observationStatus == 'Completed' ? '#EBF9D9' : '#FDF0E3',
              padding: '4px 8px 4px 8px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // minWidth: 90,
              color:
                row.observationStatus == 'Completed' ? '#749E35' : '#D62828'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor:
                  row.observationStatus == 'Completed' ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            <Typography
              sx={{
                fontSize: '12px',
                lineHeight: '14px',
                fontWeight: '600',
                color:
                  row.observationStatus == 'Completed' ? '#749E35' : '#D62828'
              }}
            >
              {row.observationStatus}
            </Typography>
          </div>
        </>
      )
    },
    {
      id: 'attachments',
      label: 'Attachments',
      render: row => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px'
            }}
          >
            <Icon
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '16px',
                width: '16px'
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8 14H4.62091C4.21704 14 4.0151 14 3.92159 13.9201C3.84045 13.8508 3.79739 13.7469 3.80577 13.6405C3.81541 13.5179 3.95821 13.3751 4.24379 13.0895L9.91242 7.42091C10.1764 7.15691 10.3084 7.0249 10.4607 6.97544C10.5946 6.93193 10.7388 6.93193 10.8727 6.97544C11.0249 7.0249 11.1569 7.1569 11.4209 7.42091L14 10V10.8M10.8 14C11.9201 14 12.4802 14 12.908 13.782C13.2843 13.5903 13.5903 13.2843 13.782 12.908C14 12.4802 14 11.9201 14 10.8M10.8 14H5.2C4.0799 14 3.51984 14 3.09202 13.782C2.71569 13.5903 2.40973 13.2843 2.21799 12.908C2 12.4802 2 11.9201 2 10.8V5.2C2 4.0799 2 3.51984 2.21799 3.09202C2.40973 2.71569 2.71569 2.40973 3.09202 2.21799C3.51984 2 4.0799 2 5.2 2H10.8C11.9201 2 12.4802 2 12.908 2.21799C13.2843 2.40973 13.5903 2.71569 13.782 3.09202C14 3.51984 14 4.0799 14 5.2V10.8M7 5.66667C7 6.40305 6.40305 7 5.66667 7C4.93029 7 4.33333 6.40305 4.33333 5.66667C4.33333 4.93029 4.93029 4.33333 5.66667 4.33333C6.40305 4.33333 7 4.93029 7 5.66667Z"
                  stroke={colorTextBlack}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Icon>
            <Typography sx={{ marginLeft: '3px', fontSize: '14px' }}>
              {row.imageCount || 0}
            </Typography>
            <Divider
              orientation="vertical"
              sx={{
                marginX: 1,
                borderColor: '#E4E7EB',
                height: '16px'
              }}
            />
            <Icon
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '16px',
                width: '16px'
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6673 6.66659V7.99992C12.6673 10.5772 10.578 12.6666 8.00065 12.6666M3.33398 6.66659V7.99992C3.33398 10.5772 5.42332 12.6666 8.00065 12.6666M8.00065 12.6666V14.6666M5.33398 14.6666H10.6673M8.00065 9.99992C6.89608 9.99992 6.00065 9.10449 6.00065 7.99992V3.33325C6.00065 2.22868 6.89608 1.33325 8.00065 1.33325C9.10522 1.33325 10.0007 2.22868 10.0007 3.33325V7.99992C10.0007 9.10449 9.10522 9.99992 8.00065 9.99992Z"
                  stroke={colorTextBlack}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Icon>
            <Typography sx={{ marginLeft: '3px', fontSize: '14px' }}>
              {row.audioCount || 0}
            </Typography>
            <Divider
              orientation="vertical"
              sx={{
                marginX: 1,
                borderColor: '#E4E7EB',
                height: '16px'
              }}
            />
            <Icon
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '16px',
                width: '16px'
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.6673 5.95417C14.6673 5.55029 14.6673 5.34835 14.5875 5.25484C14.5182 5.1737 14.4142 5.13065 14.3078 5.13902C14.1852 5.14867 14.0424 5.29146 13.7569 5.57704L11.334 7.99992L13.7569 10.4228C14.0424 10.7084 14.1852 10.8512 14.3078 10.8608C14.4142 10.8692 14.5182 10.8261 14.5875 10.745C14.6673 10.6515 14.6673 10.4495 14.6673 10.0457V5.95417Z"
                  stroke={colorTextBlack}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.33398 6.53325C1.33398 5.41315 1.33398 4.85309 1.55197 4.42527C1.74372 4.04895 2.04968 3.74299 2.426 3.55124C2.85383 3.33325 3.41388 3.33325 4.53398 3.33325H8.13398C9.25409 3.33325 9.81414 3.33325 10.242 3.55124C10.6183 3.74299 10.9243 4.04895 11.116 4.42527C11.334 4.85309 11.334 5.41315 11.334 6.53325V9.46659C11.334 10.5867 11.334 11.1467 11.116 11.5746C10.9243 11.9509 10.6183 12.2569 10.242 12.4486C9.81414 12.6666 9.25409 12.6666 8.13398 12.6666H4.53398C3.41388 12.6666 2.85383 12.6666 2.426 12.4486C2.04968 12.2569 1.74372 11.9509 1.55197 11.5746C1.33398 11.1467 1.33398 10.5867 1.33398 9.46659V6.53325Z"
                  stroke={colorTextBlack}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Icon>
            <Typography sx={{ marginLeft: '3px', fontSize: '14px' }}>
              {row.videoCount || 0}
            </Typography>
            <Divider
              orientation="vertical"
              sx={{
                marginX: 1,
                borderColor: '#E4E7EB',
                height: '16px'
              }}
            />
            <Icon
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '16px',
                width: '16px'
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.33333 1.33325H10.1333C11.2534 1.33325 11.8135 1.33325 12.2413 1.55124C12.6176 1.74299 12.9236 2.04895 13.1153 2.42527C13.3333 2.85309 13.3333 3.41315 13.3333 4.53325V11.4666C13.3333 12.5867 13.3333 13.1467 13.1153 13.5746C12.9236 13.9509 12.6176 14.2569 12.2413 14.4486C11.8135 14.6666 11.2534 14.6666 10.1333 14.6666H5.86667C4.74656 14.6666 4.18651 14.6666 3.75869 14.4486C3.38236 14.2569 3.0764 13.9509 2.88465 13.5746C2.66667 13.1467 2.66667 12.5867 2.66667 11.4666V10.9999M10.6667 8.66659H7.66667M10.6667 5.99992H8.33333M10.6667 11.3333H5.33333M4 6.66659V2.99992C4 2.44763 4.44772 1.99992 5 1.99992C5.55228 1.99992 6 2.44763 6 2.99992V6.66659C6 7.77115 5.10457 8.66659 4 8.66659C2.89543 8.66659 2 7.77116 2 6.66659V3.99992"
                  stroke={colorTextBlack}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Icon>
            <Typography sx={{ marginLeft: '3px', fontSize: '14px' }}>
              {row.documentCount || 0}
            </Typography>
          </Box>
        );
      }
    },
    {
      id: 'timeline',
      label: 'Timeline',
      render: row => {
        return (
          <>
            {row.createdDate
              ? moment(row.createdDate).format('DD-MM-YYYY, hh:mm A')
              : ''}
          </>
        );
      }
    },
    {
      render: row => {
        return (
          <>
            <Tooltip title="Delete">
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  setConfimation(row.observationId);
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5M8.33333 8.75V12.9167M11.6667 8.75V12.9167"
                    stroke={colorTextBlack}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </Tooltip>
          </>
        );
      }
    }
  ];

  useEffect(() => {
    if (
      !isObservationsLoading &&
      (isObservationsSuccess || isObservationsFailed)
    ) {
      setLoading(false);
      return;
    }

    if (deleteObservationSuccess) {
      setConfimation(null);
    }
  }, [isObservationsLoading, deleteObservationLoading]);

  const handleApplyFilter = type => {
    let payload = {
      userId: filterOptions.id?.userId,
      userGroupId: filterOptions.id?.userGroupId,
      ratings: filterOptions.ratingFrom,
      dateType: filterOptions.dateType != 'all' ? filterOptions.dateType : null,
      startDate: dayjs(filterOptions.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(filterOptions.endDate).format('YYYY-MM-DD'),
      paginationRequest: {
        page: initialValues.page + 1,
        size: initialValues.size,
        type: initialValues.type
      }
    };

    if (filterOptions.dateType !== 'selected date') {
      delete payload.startDate;
      delete payload.endDate;
    }

    if (filterOptions.type !== 'user') {
      delete payload.userId;
    }

    if (filterOptions.type !== 'usergroup') {
      delete payload.userGroupId;
    }

    Object.keys(payload).forEach(key => {
      if (!payload[key]) {
        delete payload[key];
      }
    });
    if (type === 'apply') {
      dispatch(getObservations({ payload: payload }));
    }
  };

  const filterOptionsOnInput = input => {
    const filtered = [
      ...usersData.userList,
      ...userGroupsData.userGroupsList
    ].filter(
      option =>
        option.name?.toLowerCase().includes(input.toLowerCase()) ||
        option.groupName?.toLowerCase().includes(input.toLowerCase())
    );
    setOptions(filtered);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    filterOptionsOnInput(newInputValue);
  };
  function onCalendarOpen() {
    setOpen(true);
    setTempDateFilter({
      dateType: filterOptions.dateType,
      startDate: filterOptions.startDate,
      endDate: filterOptions.endDate
    });
  }

  const handleOnRowClick = row => {
    dispatch(revertObservationFormData());
    navigate(
      `${row.observationStatus !== 'Completed' ? 'edit-observation' : 'observation-report'}`,
      {
        state: { observationId: row.observationId }
      }
    );
  };

  return (
    <>
      <Grid
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={1}
      >
        <Grid display={'flex'}>
          <Typography fontSize={'32px'} fontWeight="700">
            Observations
          </Typography>
          <Typography
            sx={{ margin: '7px 0 0 10px' }}
            fontSize={20}
            fontWeight="700"
            alignSelf={'center'}
          >
            ({observationData.totalCount})
          </Typography>
        </Grid>

        {/*  */}
        <Button
          sx={{
            borderColor: colorPrimary,
            borderRadius: '10px',
            color: colorTextBlack,
            borderWidth: '2px',
            fontWeight: '700',
            fontSize: '14px',
            boxShadow: 'none',
            '&:hover': {
              borderColor: colorPrimary,
              backgroundColor: colorPrimary,
              borderRadius: '10px',
              borderWidth: '2px'
            }
          }}
          variant="contained"
          onClick={() => {
            dispatch(revertObservationFormData());
            navigate('new-observation');
          }}
        >
          <SvgIcon
            fontSize="small"
            sx={{ marginRight: 0.6, height: '16px', width: '16px' }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.49996 2.66669V13.3334M3.16663 8.00002H13.8333"
                stroke={colorTextBlack}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </SvgIcon>{' '}
          New observation
        </Button>
      </Grid>
      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        component={Paper}
        borderRadius={'8px'}
        sx={{ paddingX: '14px', pt: 1 }}
      >
        <Grid container pt={1} sx={{ justifyContent: 'space-between' }} mb={2}>
          <Grid item lg={3} md={12} sm={12}>
            <InputLabel
              sx={{
                fontWeight: '700',
                fontSize: '16px',
                color: colorTextBlack,
                mb: 0.2
              }}
            >
              Select user or users group
            </InputLabel>
            <FormControl variant="outlined" fullWidth size="small">
              <Autocomplete
                sx={{
                  width: '308px',
                  height: '42px',
                  '& .MuiAutocomplete-endAdornment': {
                    right: '15px !important'
                  }
                }}
                options={
                  options.length > 0
                    ? options
                    : [...usersData.userList, ...userGroupsData.userGroupsList]
                }
                getOptionLabel={option => {
                  return `${option.name ? option.name : option.groupName}`;
                }}
                size="small"
                inputValue={inputValue}
                onInputChange={handleInputChange}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      placeholder={'Search'}
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                          paddingLeft: '12px'
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14 14L11.6667 11.6667M13.3333 7.66667C13.3333 10.7963 10.7963 13.3333 7.66667 13.3333C4.53705 13.3333 2 10.7963 2 7.66667C2 4.53705 4.53705 2 7.66667 2C10.7963 2 13.3333 4.53705 13.3333 7.66667Z"
                                stroke="#ABB4BD"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            {params.InputProps.startAdornment}
                          </>
                        )
                      }}
                    />
                  );
                }}
                onChange={(event, newValue) => {
                  let type = '';
                  if (newValue && 'userId' in newValue) {
                    type = 'user';
                  }

                  if (newValue && 'userGroupId' in newValue) {
                    type = 'usergroup';
                  }

                  setFilterOptions({
                    ...filterOptions,
                    id: newValue ? newValue : null,
                    type: type
                  });
                }}
                ListboxProps={{
                  sx: {
                    '& .MuiAutocomplete-option:hover': {
                      backgroundColor: colorSecondary
                    }
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            lg={6}
            md={12}
            sm={12}
            sx={{ display: { md: 'flex', sm: 'block' }, justifyContent: 'end' }}
          >
            <Grid mr={1} sx={{ alignSelf: 'end', width: '179px' }}>
              <FormControl variant="outlined" fullWidth size="small">
                <StyledSelect
                  sx={{ height: '38px', width: '180px' }}
                  placeholder="Sort By Rating"
                  open={ratingDropDown}
                  onClose={handleClose}
                  onOpen={handleIconClick}
                  value={filterOptions.ratingFrom}
                  onChange={e => {
                    setFilterOptions({
                      ...filterOptions,
                      ratingFrom: e.target.value
                    });
                    handleClose();
                  }}
                  displayEmpty
                  renderValue={selected => {
                    if (!selected) {
                      return (
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#ABB4BD'
                          }}
                        >
                          <SvgIcon
                            fontSize="small"
                            sx={{
                              marginRight: 1,
                              width: '16px',
                              height: '16px'
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.3333 2.66675V13.3334M11.3333 13.3334L8.66667 10.6667M11.3333 13.3334L14 10.6667M4.66667 13.3334V2.66675M4.66667 2.66675L2 5.33341M4.66667 2.66675L7.33333 5.33341"
                                stroke="#ABB4BD"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </SvgIcon>
                          Sort by rating
                        </Typography>
                      );
                    }
                    return (
                      <Rating
                        size="small"
                        value={ratingsData.find(cc => cc.id === selected)?.id}
                        readOnly
                      />
                    );
                  }}
                  IconComponent={() => {
                    return (
                      <>
                        {filterOptions.ratingFrom ? null : (
                          <IconButton
                            onClick={() => {
                              handleIconClick();
                            }}
                            size="small"
                          >
                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4 6L8 10L12 6"
                                  stroke="#1F2933"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </SvgIcon>
                          </IconButton>
                        )}
                      </>
                    );
                  }}
                  endAdornment={
                    filterOptions.ratingFrom ? (
                      <IconButton
                        sx={{ marginRight: '10px' }}
                        aria-label="clear"
                        size="small"
                        onClick={() =>
                          setFilterOptions({
                            ...filterOptions,
                            ratingFrom: ''
                          })
                        }
                        edge="end"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_12463_19341)">
                            <path
                              d="M10.0002 5.99992L6.00016 9.99992M6.00016 5.99992L10.0002 9.99992M14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992Z"
                              stroke="#1F2933"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_12463_19341">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </IconButton>
                    ) : null
                  }
                >
                  {ratingsData.map((cc, key) => (
                    <MenuItem
                      sx={styledMenuItem}
                      key={key}
                      value={cc.id}
                      disableRipple
                    >
                      <Rating value={cc.id} readOnly size="small" />
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </Grid>

            <Grid
              sx={{ mt: { sm: 1, lg: 0 }, alignSelf: 'end', width: '220px' }}
            >
              <FormControl variant="outlined" fullWidth size="small">
                <StyledSelect
                  sx={{ height: '38px' }}
                  size="small"
                  placeholder="Date Filter"
                  open={open}
                  value={
                    open ? tempDateFilter.dateType : filterOptions.dateType
                  }
                  onOpen={(e, va) => {
                    onCalendarOpen();
                  }}
                  onChange={e => {
                    // e.stopPropagation();
                    setTempDateFilter({
                      ...tempDateFilter,
                      dateType: e.target.value,
                      startDate: null,
                      endDate: null
                    });
                  }}
                  renderValue={ev => {
                    let data = open ? tempDateFilter : filterOptions;
                    if (data.dateType == 'all') {
                      return (
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#ABB4BD',
                            // minWidth:"20px"
                            minWidth: '150px'
                          }}
                        >
                          <SvgIcon
                            fontSize="small"
                            sx={{
                              marginRight: 1,
                              width: '16px',
                              height: '16px'
                            }}
                          >
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4 8H12M2 4H14M6 12H10"
                                stroke="#ABB4BD"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>

                            {/* <FilterLinesIcon color="#ABB4BD" /> */}
                          </SvgIcon>
                          Select date
                        </Typography>
                      );
                    }
                    switch (data.dateType) {
                      case 'selected date':
                        return `${data.startDate ? dayjs(data.startDate).format('DD/MM/YY') : ''} - ${data.endDate ? dayjs(data.endDate).format('DD/MM/YY') : ''}`;
                      case 'last week':
                        return 'Last Week';
                      case 'this month':
                        return 'This Month';
                      case 'past 3 months':
                        return 'Past 3 months';
                      case 'past 1 year':
                        return 'Past 1 year';
                      default:
                        return;
                    }
                  }}
                  endAdornment={
                    filterOptions.dateType !== 'all' ? (
                      <IconButton
                        sx={{ marginRight: '10px' }}
                        aria-label="clear"
                        size="small"
                        onClick={() => {
                          setFilterOptions({
                            ...filterOptions,
                            dateType: 'all',
                            startDate: null,
                            endDate: null
                          });
                          setTempDateFilter({
                            startDate: null,
                            dateType: 'all',
                            endDate: null
                          });
                        }}
                        edge="end"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_12463_19341)">
                            <path
                              d="M10.0002 5.99992L6.00016 9.99992M6.00016 5.99992L10.0002 9.99992M14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992Z"
                              stroke="#1F2933"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_12463_19341">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </IconButton>
                    ) : null
                  }
                  IconComponent={() => {
                    return (
                      <>
                        {filterOptions.dateType !== 'all' ? null : (
                          <IconButton
                            size="small"
                            onClick={() => {
                              onCalendarOpen();
                            }}
                          >
                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4 6L8 10L12 6"
                                  stroke="#1F2933"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </SvgIcon>
                          </IconButton>
                        )}
                      </>
                    );
                  }}
                  // sx={{ minWidth: '200px' }}
                >
                  <MenuItem
                    sx={{ ...styledMenuItem, paddingX: 0, border: 'none' }}
                    value="selected date"
                    disableRipple
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box display={'flex'} margin={'0px 6px'}>
                        <FormControl
                          variant="outlined"
                          // fullWidth

                          size="small"
                        >
                          <Typography
                            sx={{ fontSize: '14px', fontWeight: 600, mb: 0.5 }}
                          >
                            Start Date
                          </Typography>
                          <DatePicker
                            // label="Start Date"
                            // name="Start-Date"
                            sx={{ marginRight: 1 }}
                            value={dayjs(tempDateFilter.startDate)}
                            onOpen={() => handleOpenCalendar('startDate')}
                            onClose={() => handleCloseCalendar('startDate')}
                            open={openCalenderModal.startDate}
                            format="DD/MM/YYYY"
                            slotProps={{
                              textField: {
                                size: 'small',
                                error: false,
                                InputProps: {
                                  sx: {
                                    height: '34px',
                                    width: '145px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    lineHeight: '18px',
                                    color: colorTextBlack
                                  },
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      sx={{ marginX: '4px' }}
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          handleOpenCalendar('startDate')
                                        }
                                      >
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M14 6.66659H2M10.6667 1.33325V3.99992M5.33333 1.33325V3.99992M5.2 14.6666H10.8C11.9201 14.6666 12.4802 14.6666 12.908 14.4486C13.2843 14.2569 13.5903 13.9509 13.782 13.5746C14 13.1467 14 12.5867 14 11.4666V5.86659C14 4.74648 14 4.18643 13.782 3.7586C13.5903 3.38228 13.2843 3.07632 12.908 2.88457C12.4802 2.66659 11.9201 2.66659 10.8 2.66659H5.2C4.0799 2.66659 3.51984 2.66659 3.09202 2.88457C2.71569 3.07632 2.40973 3.38228 2.21799 3.7586C2 4.18643 2 4.74648 2 5.86659V11.4666C2 12.5867 2 13.1467 2.21799 13.5746C2.40973 13.9509 2.71569 14.2569 3.09202 14.4486C3.51984 14.6666 4.0799 14.6666 5.2 14.6666Z"
                                            stroke="#1F2933"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  endAdornment: null
                                }
                              }
                            }}
                            onChange={date =>
                              setTempDateFilter({
                                ...tempDateFilter,
                                dateType: 'selected date',
                                startDate: date,
                                endDate: date
                              })
                            }
                            components={{ OpenPickerIcon: () => null }}
                            // components={{
                            //   OpenPickerIcon: props => (
                            //     <IconButton size="small" {...props}>
                            //       <Calendar01Icon />
                            //     </IconButton>
                            //   )
                            // }}
                          />
                        </FormControl>
                        <FormControl
                          variant="outlined"
                          // fullWidth
                          size="small"
                        >
                          <Typography
                            sx={{ fontSize: '14px', fontWeight: 600, mb: 0.5 }}
                          >
                            End Date
                          </Typography>
                          <DatePicker
                            minDate={dayjs(tempDateFilter.startDate)}
                            // label="End Date"
                            value={dayjs(tempDateFilter.endDate)}
                            open={openCalenderModal.endDate}
                            onOpen={() => handleOpenCalendar('endDate')}
                            onClose={() => handleCloseCalendar('endDate')}
                            format="DD/MM/YYYY"
                            slotProps={{
                              textField: {
                                size: 'small',
                                error: false,
                                InputProps: {
                                  sx: {
                                    height: '34px',
                                    width: '145px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    lineHeight: '18px',
                                    color: colorTextBlack
                                  },
                                  startAdornment: (
                                    <InputAdornment
                                      sx={{ marginX: '4px' }}
                                      position="start"
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() =>
                                          handleOpenCalendar('endDate')
                                        }
                                      >
                                        <svg
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M14 6.66659H2M10.6667 1.33325V3.99992M5.33333 1.33325V3.99992M5.2 14.6666H10.8C11.9201 14.6666 12.4802 14.6666 12.908 14.4486C13.2843 14.2569 13.5903 13.9509 13.782 13.5746C14 13.1467 14 12.5867 14 11.4666V5.86659C14 4.74648 14 4.18643 13.782 3.7586C13.5903 3.38228 13.2843 3.07632 12.908 2.88457C12.4802 2.66659 11.9201 2.66659 10.8 2.66659H5.2C4.0799 2.66659 3.51984 2.66659 3.09202 2.88457C2.71569 3.07632 2.40973 3.38228 2.21799 3.7586C2 4.18643 2 4.74648 2 5.86659V11.4666C2 12.5867 2 13.1467 2.21799 13.5746C2.40973 13.9509 2.71569 14.2569 3.09202 14.4486C3.51984 14.6666 4.0799 14.6666 5.2 14.6666Z"
                                            stroke="#1F2933"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                  endAdornment: null
                                }
                              }
                            }}
                            onChange={date => {
                              setTempDateFilter({
                                ...tempDateFilter,
                                dateType: 'selected date',
                                endDate: date
                              });
                            }}
                            components={{ OpenPickerIcon: () => null }}
                            // components={{
                            //   OpenPickerIcon: props => (
                            //     <IconButton size="small" {...props}>
                            //       <Calendar01Icon />
                            //     </IconButton>
                            //   )
                            // }}
                          />
                        </FormControl>
                      </Box>
                    </LocalizationProvider>
                  </MenuItem>

                  <MenuItem sx={styledMenuItem} value="last week" disableRipple>
                    Last Week
                  </MenuItem>
                  <MenuItem
                    sx={styledMenuItem}
                    value="this month"
                    disableRipple
                  >
                    This Month
                  </MenuItem>
                  <MenuItem
                    sx={styledMenuItem}
                    value="past 3 months"
                    disableRipple
                  >
                    Past 3 month
                  </MenuItem>
                  <MenuItem
                    sx={styledMenuItem}
                    value="past 1 year"
                    disableRipple
                  >
                    Past 1 year
                  </MenuItem>
                  <MenuItem
                    sx={{
                      border: '1px solid #E4E7EB',
                      backgroundColor: colorPrimary,
                      marginX: 1,
                      borderRadius: 3,
                      fontWeight: 700,
                      justifyContent: 'center',
                      marginY: 1,
                      '&:hover': {
                        background: colorPrimary
                      }
                    }}
                    onClick={() => {
                      if (tempDateFilter.dateType) {
                        setFilterOptions({
                          ...filterOptions,
                          dateType: tempDateFilter.dateType,
                          startDate: tempDateFilter.startDate,
                          endDate: tempDateFilter.endDate
                        });
                      }
                      setTempDateFilter({
                        dateType: 'all',
                        endDate: '',
                        startDate: ''
                      });
                      setOpen(false);
                    }}
                  >
                    Apply
                  </MenuItem>
                  <MenuItem
                    sx={{
                      border: '1px solid #E4E7EB',
                      backgroundColor: colorPrimary,
                      marginX: 1,
                      borderRadius: 3,
                      fontWeight: 700,
                      justifyContent: 'center',
                      marginY: 1,
                      '&:hover': {
                        background: colorPrimary
                      }
                    }}
                    onClick={() => {
                      setFilterOptions({
                        ...filterOptions,
                        dateType: 'all',
                        startDate: null,
                        endDate: null
                      });
                      setTempDateFilter({
                        startDate: null,
                        dateType: 'all',
                        endDate: null
                      });
                      setOpen(false);
                    }}
                  >
                    Close
                  </MenuItem>
                </StyledSelect>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid container p={1} sx={{}} spacing={1}>
          <Grid item lg={3} md={12}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="select-type">Type </InputLabel>
              <Select
                labelId="select-type"
                id="select-type"
                label="Type"
                value={filterOptions.type}
                onChange={e => {
                  setFilterOptions({
                    ...filterOptions,
                    type: e.target.value,
                    id: null
                  });
                }}
              >
                {[
                  { id: 'user', label: 'User' },
                  { id: 'usergroup', label: 'User Group' }
                ].map((row, key) => {
                  return (
                    <MenuItem key={key} value={row?.id}>
                      {row.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {filterOptions.type && (
            <Grid item lg={3} md={12}>
              <FormControl variant="outlined" fullWidth size="small">
                {filterOptions.type === 'user' && (
                  <Autocomplete
                    options={
                      isArrayNotEmpty(usersData.userList)
                        ? usersData.userList
                        : []
                    }
                    getOptionLabel={option => `${option.name}`}
                    size="small"
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          label={'Select User'}
                          variant="outlined"
                        />
                      );
                    }}
                    renderOption={(props, option) => {
                      return (
                        <ListItem key={option.userId} {...props}>
                          <ListItemText>
                            <Typography>{option.name}</Typography>
                          </ListItemText>
                        </ListItem>
                      );
                    }}
                    onChange={(event, newValue) => {
                      setFilterOptions({
                        ...filterOptions,
                        id: newValue ? newValue : null
                      });
                    }}
                  />
                )}
                {filterOptions.type === 'usergroup' && (
                  <Autocomplete
                    options={
                      isArrayNotEmpty(userGroupsData.userGroupsList)
                        ? userGroupsData.userGroupsList
                        : []
                    }
                    getOptionLabel={option => `${option.groupName}`}
                    size="small"
                    renderInput={params => {
                      return (
                        <TextField
                          {...params}
                          label={'Select User Group'}
                          variant="outlined"
                        />
                      );
                    }}
                    renderOption={(props, option) => {
                      return (
                        <ListItem key={option.userGroupId} {...props}>
                          <ListItemText>
                            <Typography>{option.groupName}</Typography>
                          </ListItemText>
                        </ListItem>
                      );
                    }}
                    value={filterOptions.id}
                    onChange={(event, newValue) => {
                      setFilterOptions({
                        ...filterOptions,
                        id: newValue ? newValue : null
                      });
                    }}
                  />
                )}
              </FormControl>
            </Grid>
          )}
          <Grid item lg={3} md={12}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="select-rate">Sort By Rating</InputLabel>
              <StyledSelect
                labelId="select-rate"
                id="select-rate"
                size="small"
                label="Sort By Rating"
                value={filterOptions.ratingFrom}
                onChange={e =>
                  setFilterOptions({
                    ...filterOptions,
                    ratingFrom: e.target.value
                  })
                }
              >
                {ratingsData.map((cc, key) => (
                  <MenuItem
                    sx={styledMenuItem}
                    key={key}
                    value={cc.id}
                    disableRipple
                  >
                    <Rating value={cc.id} readOnly />
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </Grid>
          <Grid item lg={3} md={12}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="select-date">Date Filter</InputLabel>
              <StyledSelect
                labelId="select-date"
                id="select-date"
                size="small"
                label="Date Filter"
                value={filterOptions.dateType}
                onChange={e =>
                  setFilterOptions({
                    ...filterOptions,
                    dateType: e.target.value,
                    startDate: null,
                    endDate: null
                  })
                }
              >
                <MenuItem sx={styledMenuItem} value="custom" disableRipple>
                  Custom Range
                </MenuItem>

                <MenuItem sx={styledMenuItem} value="last week" disableRipple>
                  Last Week
                </MenuItem>
                <MenuItem sx={styledMenuItem} value="this month" disableRipple>
                  This Month
                </MenuItem>
                <MenuItem
                  sx={styledMenuItem}
                  value="past 3 months"
                  disableRipple
                >
                  Past 3 month
                </MenuItem>
                <MenuItem sx={styledMenuItem} value="past 1 year" disableRipple>
                  Past 1 year
                </MenuItem>
              </StyledSelect>
            </FormControl>
          </Grid>
          {filterOptions.dateType === 'custom' && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item lg={3} md={12}>
                <FormControl variant="outlined" fullWidth size="small">
                  <DatePicker
                    label="Start Date"
                    value={filterOptions.startDate}
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={date =>
                      setFilterOptions({
                        ...filterOptions,
                        startDate: date
                      })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item lg={3} md={12}>
                <FormControl variant="outlined" fullWidth size="small">
                  <DatePicker
                    label="End Date"
                    value={filterOptions.endDate}
                    slotProps={{ textField: { size: 'small' } }}
                    onChange={date =>
                      setFilterOptions({
                        ...filterOptions,
                        endDate: date
                      })
                    }
                  />
                </FormControl>
              </Grid>
            </LocalizationProvider>
          )}
          <Grid item lg={3} md={12}>
            <Button
              variant="contained"
              onClick={() => {
                handleApplyFilter('apply');
              }}
            >
              Apply Filters
            </Button>
          </Grid>
        </Grid> */}
        <Grid sx={{ padding: '10px 0px' }} width={'100%'}>
          {loading && (
            <Skeleton
              variant="rounded"
              width={'100%'}
              height={400}
              animation="wave"
            />
          )}
          {!loading && (
            <CustomDataTable
              columns={columns}
              allowSelection={false}
              data={
                isArrayNotEmpty(observationData.observation)
                  ? observationData.observation
                  : []
              }
              onPageChange={val => {
                setInitialValues({ ...initialValues, page: val });
              }}
              onRowsPerPageChange={val => {
                setInitialValues({ ...initialValues, size: val, page: 0 });
              }}
              selectedRows={[...selectedList]}
              onSelectionChange={val => {
                setSelectedList(val);
              }}
              defaultOrderBy={'createdDate'}
              defaultPage={initialValues.page}
              defaultRowsPerPage={initialValues.size}
              totalCount={observationData.totalCount}
              onRowClick={row => handleOnRowClick(row)}
            />
          )}
        </Grid>
      </Grid>

      <ConfirmBox
        isOpen={confirmation ? true : false}
        title="Delete Observation!"
        subtitle={`Are you sure you want to delete. `}
        handleClose={() => setConfimation(null)}
        handleSubmit={() => {
          setLoading(true);
          dispatch(deleteObservation(confirmation));
        }}
      />

      {!isCreateUpdateObservationLoading &&
        isCreateUpdateObservationSuccess && (
          <SnackBarBox
            type="success"
            message={createUpdateObservationResponse}
          />
        )}

      {!deleteObservationLoading && deleteObservationFailed && (
        <SnackBarBox
          type="error"
          message={handleError(deleteObservationErrorContainer.errorMessage)}
        />
      )}

      {!deleteObservationLoading && deleteObservationSuccess && (
        <SnackBarBox
          type="success"
          message={'Observation deleted successfully.'}
        />
      )}
    </>
  );
}

export default ObservationReports;
