import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  Checkbox,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Chip,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Table,
  Skeleton,
  MenuItem,
  Breadcrumbs,
  SvgIcon,
  Link,
  Tooltip
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomBreadcrumb from '../../components/Breadcrumb';
import {
  colorLightYellow,
  colorLinkGrey,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import {
  Add,
  Close,
  DeleteOutlineOutlined,
  EditRounded,
  HowToRegOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp
} from '@mui/icons-material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { createTitle, handleError, isArrayNotEmpty } from '../../utils/utils';
import {
  revertRubricFormData,
  rubricsDataById,
  assignRubric,
  revertRubricDetailData,
  getRubrics,
  reAssignRubric,
  createUpdateRubric
} from '../../redux/reducers/rubricSlice';
import moment from 'moment';
import { getUserGroupsList } from '../../redux/reducers/userGroupsSlice';
import SnackBarBox from '../../components/SnackBarBox';
import ConfirmBox from '../../components/ConfirmBox';
import { ChevronRightIcon } from '../../utils/iconSvg';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function RubricDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [reassignOpen, setReassignOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [selectedNewRubric, setSelectedNewRubric] = useState();
  const [groupSelected, setGroupSelected] = useState(false);
  const [isRubricSelected, setIsRubricSelected] = useState(false);
  const [expandedRowId, setExpandedRowId] = useState(null);
  const [confirmation, setConfimation] = useState(null);
  const {
    rubricsByIdData,
    isAssignRubricLoading,
    isAssignRubricSuccess,
    isAssignRubricFailed,
    assignRubricResponse,
    rubricsData,
    isReAssignRubricLoading,
    isReAssignRubricFailed,
    isReAssignRubricSuccess,
    reAssignRubricResponse,
    reAssignRubricErrorContainer,
    isCreateUpdateRubricSuccess,
    isCreateUpdateRubricFail,
    isCreateUpdateRubricLoading,
    createUpdateRubricErrorContainer,
    createUpdateRubricResponse,
    rubricsByIdSuccess
  } = useSelector(state => state.rubric);
  const { userGroupsData } = useSelector(state => state.userGroups);
  const [loading, setLoading] = useState(false);
  const { authData } = useSelector(state => state.auth);

  useEffect(() => {
    document.title = createTitle(`Rubric Details`);

    if (location.state && location.state?.rubricId) {
      setLoading(true);
      dispatch(rubricsDataById(location.state?.rubricId));
    }

    return () => dispatch(revertRubricFormData());
  }, []);

  useEffect(() => {
    if (isCreateUpdateRubricSuccess || isAssignRubricSuccess) {
      setTimeout(() => {
        dispatch(revertRubricFormData());
        dispatch(rubricsDataById(location.state?.rubricId));
      }, 500);
    }

    if (isCreateUpdateRubricFail || isAssignRubricFailed) {
      setLoading(false);
    }
  }, [
    isCreateUpdateRubricSuccess,
    isCreateUpdateRubricFail,
    isAssignRubricSuccess,
    isAssignRubricFailed
  ]);

  useEffect(() => {
    if (rubricsByIdData) {
      // console.log(rubricsByIdData, 'rubricsByIdData');
      setLoading(false);
    }
  }, [rubricsByIdSuccess]);

  useEffect(() => {
    if (reassignOpen) {
      dispatch(
        getRubrics({
          type: 'all',
          page: '0',
          size: '0'
        })
      );
    }

    if (open) {
      dispatch(
        getUserGroupsList({
          payload: {
            type: true,
            page: 0,
            size: 0
          }
        })
      );
    }

    return () => {
      dispatch(revertRubricDetailData());
    };
  }, [reassignOpen, open]);

  useEffect(() => {
    if (userGroupsData && userGroupsData.userGroupsList) {
      setAutocompleteOptions(userGroupsData.userGroupsList);
    } else {
      setAutocompleteOptions([]);
    }
  }, [userGroupsData && userGroupsData.userGroupsList]);

  const handleOpen = () => {
    setSelectedValues(rubricsByIdData?.userGroups);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setGroupSelected(false);
  };

  const handleReassignOpen = () => {
    setReassignOpen(true);
  };

  const handleReassignClose = () => {
    setReassignOpen(false);
    setIsRubricSelected(false);
  };

  const handleSave = () => {
    setLoading(true);
    // Handle the save action with selectedValues
    if (selectedValues.length !== 0) {
      const userGroupIds = selectedValues.map(ids => ids.userGroupId);
      setOpen(false);
      let payload = {
        ids: [...userGroupIds],
        id: rubricsByIdData?.rubricId,
        loggedInUserName: authData?.userName
      };

      dispatch(assignRubric(payload));
      setGroupSelected(false);
      setSelectedValues([]);
    } else {
      setGroupSelected(true);
    }
  };

  const handleReassign = () => {
    if (rubricsByIdData?.rubricId && selectedNewRubric?.rubricId) {
      let payload = {
        oldId: rubricsByIdData.rubricId,
        newId: selectedNewRubric.rubricId,
        loggedInUserName: authData?.userName
      };
      dispatch(reAssignRubric(payload));
      setReassignOpen(false);
      setIsRubricSelected(false);
      setSelectedNewRubric(null);
    } else {
      setIsRubricSelected(true);
    }
  };

  function Row(props) {
    // const { item } = props;
    const { item, expandedRowId, setExpandedRowId } = props;
    const isRowExpanded = item.indicatorId === expandedRowId;

    // const [open, setOpen] = React.useState(false);

    const handleRowClick = () => {
      if (isRowExpanded) {
        setExpandedRowId(null);
      } else {
        setExpandedRowId(item.indicatorId);
      }
    };

    return (
      <React.Fragment>
        <TableRow
          onClick={handleRowClick}
          hover
          sx={{
            '& > *': { borderBottom: 'unset' },
            backgroundColor: isRowExpanded ? '#FCEBC5' : 'inherit',
            border: `2px solid ${isRowExpanded ? '#FCEBC5' : 'inherit'}!important`,
            cursor: 'pointer',
            '&.Mui-selected': {
              backgroundColor: 'rgba(0, 0, 0, 0.08)'
            },
            '.MuiTableRow-hover&:hover': {
              backgroundColor: colorSecondary
            }
          }}
        >
          <TableCell sx={{ fontSize: '14px', fontWeight: '600' }}>
            {item.indicatorName}
          </TableCell>
          <TableCell sx={{ fontSize: '14px', fontWeight: '600' }}>
            {item.createdBy}
          </TableCell>
          <TableCell sx={{ fontSize: '14px', fontWeight: '600' }}>
            {item.createdDate
              ? moment(item.createdDate).format('DD-MM-YYYY hh:mm A')
              : 'NA'}
          </TableCell>

          <TableCell sx={{ fontSize: '14px', fontWeight: '600' }}>
            <Tooltip title="Edit">
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  navigate('edit-rubric', {
                    state: {
                      ...location.state,
                      indicatorId: item.indicatorId
                    }
                  });
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.89686 15.0963C2.93515 14.7517 2.95429 14.5794 3.00642 14.4184C3.05268 14.2755 3.11802 14.1396 3.20069 14.0142C3.29388 13.8729 3.41645 13.7503 3.6616 13.5052L14.6669 2.49992C15.5873 1.57945 17.0797 1.57945 18.0002 2.49993C18.9207 3.4204 18.9207 4.91279 18.0002 5.83326L6.99493 16.8385C6.74978 17.0836 6.62721 17.2062 6.4859 17.2994C6.36054 17.3821 6.22457 17.4474 6.0817 17.4937C5.92067 17.5458 5.74838 17.5649 5.4038 17.6032L2.5835 17.9166L2.89686 15.0963Z"
                    stroke={colorTextBlack}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  setConfimation(item.indicatorId);
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 2.5H13M3 5H18M16.3333 5L15.7489 13.7661C15.6612 15.0813 15.6174 15.7389 15.3333 16.2375C15.0833 16.6765 14.706 17.0294 14.2514 17.2497C13.735 17.5 13.0759 17.5 11.7578 17.5H9.24221C7.92409 17.5 7.26503 17.5 6.74861 17.2497C6.29396 17.0294 5.91674 16.6765 5.66665 16.2375C5.38259 15.7389 5.33875 15.0813 5.25107 13.7661L4.66667 5M8.83333 8.75V12.9167M12.1667 8.75V12.9167"
                    stroke={colorTextBlack}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleRowClick}
            >
              {isRowExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0
              // border: `1px solid black!important`
            }}
            sx={{
              border: `2px solid ${isRowExpanded ? '#FCEBC5' : 'inherit'}!important`
            }}
            colSpan={6}
          >
            <Collapse in={isRowExpanded} timeout="auto" unmountOnExit>
              <Grid
                my={2}
                container
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
              >
                <Grid item lg={4} xl={4}>
                  <Typography
                    color={colorTextBlack}
                    fontWeight={'700'}
                    fontSize="16px"
                    mb={1}
                  >
                    Domain
                  </Typography>
                  <Typography
                    color={colorTextBlack}
                    fontWeight={'600'}
                    fontSize={'14px'}
                  >
                    {item.domainName}
                  </Typography>
                </Grid>
                <Grid item lg={4} xl={4}>
                  <Typography
                    color={colorTextBlack}
                    fontWeight={'700'}
                    fontSize="16px"
                  >
                    Selected tags
                  </Typography>
                  <Box>
                    {item?.tags.map((tag, idx) => (
                      <Chip
                        key={idx}
                        label={tag.tagName}
                        variant="outlined"
                        style={{
                          height: '22px',
                          margin: '4px',
                          padding: '4px, 6px, 4px, 6px',
                          fontSize: '12px',
                          backgroundColor: colorLightYellow,
                          borderColor: colorPrimary,
                          borderRadius: '6px'
                        }}
                      />
                    ))}
                  </Box>
                </Grid>
                <Grid item lg={4} xl={4}>
                  <Typography fontWeight={'700'} fontSize="16px">
                    Description
                  </Typography>
                  <Typography
                    color={colorLinkGrey}
                    fontWeight={'600'}
                    fontSize={'14px'}
                  >
                    {item.indicatorDescription}
                  </Typography>
                </Grid>
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const handleDeleteIndicator = indicatorId => {
    setLoading(true);
    if (isArrayNotEmpty(rubricsByIdData && rubricsByIdData.indicators)) {
      let filteredData = rubricsByIdData.indicators.filter(
        xx => xx.indicatorId !== indicatorId
      );
      let tempListOfIndicators = [];

      // setLoading(true);
      let arr = filteredData;
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        let tmpTags = isArrayNotEmpty(element.tags)
          ? element.tags.map(cc => cc.tagName)
          : [];

        tempListOfIndicators.push({
          indicatorId: element.indicatorId,
          domainId: element.domainId,
          tagNames: tmpTags,
          indicatorName: element.indicatorName,
          indicatorDescription: element.indicatorDescription,
          loggedInUserName: authData.userName
        });
      }

      let submitPayload = {
        rubricName: rubricsByIdData.rubricName,
        indicatorRequestList: tempListOfIndicators,
        loggedInUserName: authData.userName
      };

      // console.log(submitPayload,"sad")
      dispatch(
        createUpdateRubric({
          rubricId: location.state ? location.state.rubricId : null,
          payload: submitPayload
        })
      );
      setConfimation(null);
    }
  };

  return (
    <>
      {loading && (
        <Skeleton
          variant="rounded"
          width={'100%'}
          height={400}
          animation="wave"
        />
      )}
      {!loading && (
        <Box>
          <Box display={'flex'} justifyContent={'space-between'} mb={1}>
            <Grid>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={
                  <SvgIcon sx={{ height: 24, width: 24 }}>
                    <ChevronRightIcon color={colorTextGrey} />
                  </SvgIcon>
                }
              >
                <Link
                  color="inherit"
                  href={'area'}
                  onClick={event => {
                    event.preventDefault();
                    navigate('/evaluation-rubrics');
                  }}
                  sx={{ textDecoration: 'none' }}
                >
                  <Typography
                    fontWeight={'700'}
                    color={colorLinkGrey}
                    fontSize={'24px'}
                  >
                    Evaluation Rubrics
                  </Typography>
                </Link>
                <Typography
                  sx={{
                    fontSize: '32px',
                    fontWeight: '700',
                    color: colorTextBlack
                  }}
                >
                  {rubricsByIdData?.rubricName}
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Box>
              <Button
                sx={{
                  borderColor: colorPrimary,
                  borderRadius: '10px',
                  color: 'black',
                  borderWidth: '2px',
                  marginRight: 2,
                  '&:hover': {
                    borderColor: colorPrimary,
                    backgroundColor: 'transparent',
                    borderRadius: '10px',
                    borderWidth: '2px'
                  }
                }}
                variant="outlined"
                disabled={!(rubricsByIdData && rubricsByIdData.status)}
                onClick={handleOpen}
              >
                <SvgIcon
                  fontSize="small"
                  sx={{ marginRight: 0.8, height: '16px', width: '16px' }}
                >
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.1668 12L12.5002 13.3333L15.1668 10.6667M8.50016 10H5.8335C4.59099 10 3.96973 10 3.47967 10.203C2.82627 10.4736 2.30713 10.9928 2.03648 11.6462C1.8335 12.1362 1.8335 12.7575 1.8335 14M10.8335 2.19384C11.8108 2.58943 12.5002 3.54754 12.5002 4.66667C12.5002 5.78579 11.8108 6.7439 10.8335 7.13949M9.50016 4.66667C9.50016 6.13943 8.30626 7.33333 6.8335 7.33333C5.36074 7.33333 4.16683 6.13943 4.16683 4.66667C4.16683 3.19391 5.36074 2 6.8335 2C8.30626 2 9.50016 3.19391 9.50016 4.66667Z"
                      stroke={colorTextBlack}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>
                Assign rubric
              </Button>
              <Button
                disabled={!(rubricsByIdData && rubricsByIdData.status)}
                sx={{
                  borderColor: colorPrimary,
                  borderRadius: '10px',
                  color: 'black',
                  borderWidth: '2px',
                  marginRight: 2,
                  '&:hover': {
                    borderColor: colorPrimary,
                    backgroundColor: 'transparent',
                    borderRadius: '10px',
                    borderWidth: '2px'
                  }
                }}
                variant="outlined"
                onClick={handleReassignOpen}
              >
                Re-assign rubric
              </Button>
              <Button
                sx={{
                  marginRight: '16px',
                  boxShadow: 'none'
                }}
                variant="contained"
                onClick={() => {
                  navigate('edit-rubric', {
                    state: { ...location.state, indicatorId: null }
                  });
                }}
              >
                <SvgIcon
                  fontSize="small"
                  sx={{ marginRight: 0.8, height: '16px', width: '16px' }}
                >
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.49996 2.66669V13.3334M3.16663 8.00002H13.8333"
                      stroke={colorTextBlack}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>{' '}
                Add indicator
              </Button>
            </Box>
          </Box>
          <Box bgcolor={'white'} paddingX={2} paddingY={1} borderRadius={'8px'}>
            <Typography
              fontSize={20}
              my={1.6}
              fontWeight="700"
              color={colorTextBlack}
            >
              Indicator list
            </Typography>

            <Table aria-label="collapsible table" size="small">
              <TableHead
                sx={{
                  backgroundColor: '#F5F7FA',
                  borderTopRightRadius: '8px',
                  borderTopLeftRadius: '8px',
                  '&:first-of-type th:first-of-type': {
                    borderTopLeftRadius: '8px'
                  },
                  '&:first-of-type th:last-of-type': {
                    borderTopRightRadius: '8px'
                  }
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      color: colorTextGrey,
                      fontWeight: '700',
                      fontSize: '14px'
                    }}
                  >
                    Indicator
                  </TableCell>
                  <TableCell
                    sx={{
                      color: colorTextGrey,
                      fontWeight: '700',
                      fontSize: '14px'
                    }}
                  >
                    Created By
                  </TableCell>
                  <TableCell
                    sx={{
                      color: colorTextGrey,
                      fontWeight: '700',
                      fontSize: '14px'
                    }}
                  >
                    Creation Date
                  </TableCell>
                  <TableCell
                    sx={{
                      color: colorTextGrey,
                      fontWeight: '700',
                      fontSize: '14px'
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      color: colorTextGrey,
                      fontWeight: '700',
                      fontSize: '14px'
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rubricsByIdData && rubricsByIdData.indicators
                  ? isArrayNotEmpty(rubricsByIdData.indicators) &&
                    rubricsByIdData.indicators.map((item, key) => (
                      <Row
                        item={item}
                        key={key}
                        expandedRowId={expandedRowId}
                        setExpandedRowId={setExpandedRowId}
                      />
                    ))
                  : []}
              </TableBody>
            </Table>
          </Box>
        </Box>
      )}

      <Dialog
        open={reassignOpen}
        onClose={handleReassignClose}
        sx={{
          '& .MuiDialog-paper': {
            // minWidth: '520px',
            // maxWidth: '520px',
            padding: '24px',
            borderRadius: '8px'
          }
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <Typography fontSize={'20px'} mb={2} fontWeight={'700'}>
            {`Re-assign user groups from Rubric `}
            <span style={{ fontWeight: 'bold' }}>
              {rubricsByIdData?.rubricName}
            </span>
            {` to `}
          </Typography>

          <Autocomplete
            options={
              rubricsData?.rubrics.filter(
                cc => cc.rubricId !== rubricsByIdData?.rubricId
              ) || []
            }
            getOptionLabel={option => option.rubricName}
            style={{ width: '100%' }}
            size="small"
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select rubric"
                  error={isRubricSelected}
                  helperText={isRubricSelected ? 'Rubric is required' : ''}
                  required
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.5 14L12.1667 11.6667M13.8333 7.66667C13.8333 10.7963 11.2963 13.3333 8.16667 13.3333C5.03705 13.3333 2.5 10.7963 2.5 7.66667C2.5 4.53705 5.03705 2 8.16667 2C11.2963 2 13.8333 4.53705 13.8333 7.66667Z"
                            stroke={colorTextGrey}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        {params.InputProps.startAdornment}
                      </>
                    )
                  }}
                />
              );
            }}
            onChange={(event, newValue) => {
              setSelectedNewRubric(newValue);
              setIsRubricSelected(false);
            }}
            ListboxProps={{
              sx: {
                '& .MuiAutocomplete-option:hover': {
                  backgroundColor: colorSecondary
                }
              }
            }}
          />
        </DialogContent>

        <DialogActions sx={{ padding: 0, mt: 2, display: 'block' }}>
          <Grid display={'flex'} gap={2}>
            <Button
              variant="outlined"
              onClick={handleReassignClose}
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: colorTextBlack,
                borderWidth: '2px',
                height: '42px',
                width: '106px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              sx={{ width: '94px', height: '42px' }}
              onClick={handleReassign}
              disabled={!selectedNewRubric}
            >
              Save
            </LoadingButton>
          </Grid>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            // minWidth: '520px',
            // maxWidth: '520px',
            // padding: '40px',
            borderRadius: '8px',
            padding: '24px'
          }
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <Typography fontSize={'20px'} fontWeight={'700'}>
            Assign Rubric ({rubricsByIdData?.rubricName} )
          </Typography>

          <Typography sx={{ mt: 2, fontWeight: '700', fontSize: '16px' }}>
            Select user groups
          </Typography>
          <Autocomplete
            size="small"
            multiple
            options={autocompleteOptions}
            disableCloseOnSelect
            getOptionLabel={option => option.groupName}
            isOptionEqualToValue={(option, value) =>
              option.groupName === (value ? value.groupName : null)
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.groupName}
              </li>
            )}
            limitTags={2}
            ChipProps={{ style: { backgroundColor: colorPrimary } }}
            style={{ width: '100%', height: '100%' }}
            sx={{
              '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                paddingLeft: '16px',
                width: '440px',
                height: '42px'
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <svg
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.5 14L12.1667 11.6667M13.8333 7.66667C13.8333 10.7963 11.2963 13.3333 8.16667 13.3333C5.03705 13.3333 2.5 10.7963 2.5 7.66667C2.5 4.53705 5.03705 2 8.16667 2C11.2963 2 13.8333 4.53705 13.8333 7.66667Z"
                          stroke={colorTextGrey}
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      {params.InputProps.startAdornment}
                    </>
                  )
                }}
                error={groupSelected}
                helperText={groupSelected ? 'User group is required' : ''}
                required
              />
            )}
            value={selectedValues}
            onChange={(event, newValue) => {
              setSelectedValues(newValue);
              setGroupSelected(false);
            }}
            ListboxProps={{
              sx: {
                '& .MuiAutocomplete-option:hover': {
                  backgroundColor: colorSecondary
                }
              }
            }}
          />
          <Box
            display="flex"
            flexWrap="wrap"
            overflowY="auto"
            maxHeight="200px"
            mt={1}
          >
            {selectedValues &&
              selectedValues.map((cc, indiIdx) => {
                return (
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 1,
                      mr: 1,
                      fontWeight: '600',
                      padding: '2px 6px',
                      background: '#FCEBC5',
                      border: `1px solid ${colorPrimary}`,
                      borderRadius: '6px'
                    }}
                    key={indiIdx}
                    variant="caption"
                  >
                    {cc?.groupName}
                    <IconButton
                      onClick={() => {
                        setSelectedValues([
                          ...selectedValues.filter((_, i) => i !== indiIdx)
                        ]);
                      }}
                      sx={{
                        padding: 0,
                        ml: 2
                      }}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </Typography>
                );
              })}
          </Box>
        </DialogContent>

        <DialogActions sx={{ display: 'block', padding: 0, mt: 2 }}>
          <Grid display={'flex'} gap={2}>
            <Button
              variant="outlined"
              onClick={handleClose}
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: colorTextBlack,
                borderWidth: '2px',
                height: '42px',
                width: '106px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              // loading={loading}
              onClick={handleSave}
              variant="contained"
              // style={{ marginRight: 16, borderWidth: 2 }}
              sx={{ width: '94px', height: '42px' }}
            >
              Save
            </LoadingButton>
          </Grid>
        </DialogActions>
      </Dialog>

      <ConfirmBox
        isOpen={confirmation}
        title="Delete Indicator!"
        subtitle={`Are you sure you want to delete this indicator. `}
        handleClose={() => setConfimation(null)}
        handleSubmit={() => {
          handleDeleteIndicator(confirmation);
        }}
      />

      {!isAssignRubricLoading && isAssignRubricSuccess && (
        <SnackBarBox type="success" message={assignRubricResponse} />
      )}

      {!isReAssignRubricLoading && isReAssignRubricSuccess && (
        <SnackBarBox type="success" message={reAssignRubricResponse} />
      )}

      {!isReAssignRubricLoading && isReAssignRubricFailed && (
        <SnackBarBox
          type="error"
          message={handleError(reAssignRubricErrorContainer?.errorMessage)}
        />
      )}

      {!isCreateUpdateRubricLoading && isCreateUpdateRubricFail && (
        <SnackBarBox
          type="error"
          message={handleError(createUpdateRubricErrorContainer?.errorMessage)}
        />
      )}

      {!isCreateUpdateRubricLoading && isCreateUpdateRubricSuccess && (
        <SnackBarBox
          type="success"
          message={
            createUpdateRubricResponse || 'Action performed successfully'
          }
        />
      )}
    </>
  );
}

export default RubricDetails;
