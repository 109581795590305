import {
  Autocomplete,
  Box,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Paper,
  Rating,
  Skeleton,
  SvgIcon,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { handleError } from '../../../utils/utils';

//import { colorMidGray, colorPrimary } from '../../../config/terrorMessageheme';
import {
  colorLinkGrey,
  colorMidGray,
  colorPrimary,
  colorSecondary,
  colorTextBlack,
  colorTextGrey
} from '../../../config/theme';

import { IOSSwitch } from '../../../utils/styledLayout';
import { CUSTOM_PRINT_ICON } from '../../../utils/imageUrls';
import { useDispatch } from 'react-redux';
import {
  acceptResponse,
  downloadIndividualPDF,
  downloadQuestionWisePDF,
  fetchFormResponseCountById,
  formResponseById,
  revertFormAcceptanceResponse
} from '../../../redux/reducers/flowsSlice';
import CustomDataTable from '../../../components/CustomDataTable';
import { useSelector } from 'react-redux';
import { isArrayNotEmpty } from '../../../utils/utils';
import IndividualResponsesCard from './IndividualResponsesCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllIndicators } from '../../../redux/reducers/rubricSlice';
import QuestionWiseResponseCard from './QuestionWiseResponseCard';
import _ from 'lodash';
import SnackBarBox from '../../../components/SnackBarBox';
import SendReminder from './SendReminder';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import RubricWiseResponseCard from './RubricWiseResponseCard';
import CustomTable from '../../../components/CustomTable';
import { East } from '@mui/icons-material';

const FormResponses = ({ formId, flowId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSearch] = React.useState(null);
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [accptResponse, setAccptResponse] = React.useState(false);
  const [isHover, setIsHover] = React.useState(false);
  const [responseTableData, setResponseTableData] = React.useState([]);
  const [responseFilterOption, setResponseFilterOption] = React.useState([]);
  const [responseTablePagination, setResponseTablePagination] = React.useState({
    page: 0,
    size: 10
  });
  const [responseList, setResponseList] = React.useState({
    title: '',
    totalQuestionCount: 0,
    data: [],
    rating: 0
  });
  const [selectedIds, setSelectedIds] = useState([]);
  const [filterDate, setFilterDate] = useState(null);
  const [userSelectionResError, setUserSelectionResError] = useState({
    error: false,
    errorMessage: ''
  });
  const [loading, setIsLoading] = useState(true);
  const [questionCount, setQuestionCount] = useState(0);
  const { authData } = useSelector(state => state.auth);
  const [openReminderModal, setOpenReminderModal] = useState(false);
  const [totalResponse, setTotalResponse] = useState({ responses: 0 });
  const { indicatorsList } = useSelector(state => state.rubric);
  const {
    isFormResponseFailed,
    isFormResponseLoading,
    isFormResponseSuccess,
    formResponse,
    isAcceptFormSuccess,
    isAcceptFormLoading,
    isDownloadIndividualPDFLoading,
    acceptFormResponse,
    isAcceptFormFail
  } = useSelector(state => state.flow);
  // console.log(formResponse?.individualAvgRating, 'formResponse');

  React.useEffect(() => {
    dispatch(getAllIndicators());
    if (formId) {
      dispatch(formResponseById({ formId: formId, flowId: flowId }));
    }
    const fetchData = async () => {
      const data = await fetchFormResponseCountById({
        formId: location.state?.formId,
        flowId: location.state?.flowId
      });

      if (data) {
        setTotalResponse({
          ...totalResponse,
          responses: data
        });
      }
    };
    fetchData();

    return () => {
      //   second;
    };
  }, [formId]);

  useEffect(() => {
    if (userSelectionResError.error) {
      const timeoutId = setTimeout(() => {
        setUserSelectionResError({
          error: false,
          errorMessage: ''
        });
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [userSelectionResError]);

  const handleOpenReminderModal = () => {
    setOpenReminderModal(true);
  };

  React.useEffect(() => {
    if (!isFormResponseLoading && formResponse) {
      setSearch(null);

      let tempTableData = formResponse?.questionList || [];
      setAccptResponse(formResponse?.acceptingResponse);
      if (selectedTab === 0) {
        let tempOption =
          formResponse.individualResponses &&
          isArrayNotEmpty(formResponse.individualResponses)
            ? formResponse.individualResponses.map(cc => {
                return { id: cc.userId, label: cc.name };
              })
            : [];
        let tempIndRes = [];
        if (isArrayNotEmpty(formResponse.individualResponses)) {
          formResponse.individualResponses.map(cc => {
            return tempIndRes.push({
              ...cc,
              responseDate: isArrayNotEmpty(cc.responses)
                ? cc.responses[0]?.responseDate
                : null
            });
          });
        }

        // setResponseTableData(formResponse.individualResponses);
        setResponseTableData(tempIndRes);
        setResponseFilterOption(tempOption || []);
      }

      if (selectedTab === 1) {
        setResponseTableData(tempTableData);
        setResponseFilterOption(
          tempTableData.map(cc => {
            return { id: cc.questionId, label: cc.questionText };
          })
        );
      }

      if (selectedTab === 2) {
        let tempRubRes = [];
        if (isArrayNotEmpty(formResponse.rubricWiseResponse)) {
          formResponse.rubricWiseResponse.map(cc => {
            return tempRubRes.push({
              ...cc
            });
          });
        }
        setResponseTableData(tempRubRes);
        setResponseFilterOption(
          [...indicatorsList].map(cc => {
            return { id: cc.indicatorId, label: cc.indicatorName };
          })
        );
      }
      setIsLoading(false);
    }

    if (!isFormResponseLoading && isFormResponseFailed) {
      setIsLoading(false);
    }
    return () => {};
  }, [isFormResponseSuccess, selectedTab, isFormResponseFailed]);

  useEffect(() => {
    if (!isAcceptFormLoading && isAcceptFormSuccess) {
      setTimeout(() => {
        if (formId) {
          dispatch(formResponseById({ formId: formId, flowId: flowId }));
        }
        dispatch(revertFormAcceptanceResponse());
      }, 3000);

      return () => clearTimeout();
    }
    if (!isAcceptFormLoading && isAcceptFormFail) {
      setIsLoading(false);
    }
  }, [isAcceptFormSuccess, isAcceptFormFail]);

  const individualWiseResponseColums = [
    {
      uinqueKey: 'userId',
      id: 'name',
      width: '35%',
      label: 'Name',
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.name}
          </Typography>
        );
      }
    },
    {
      label: 'Response date',
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.responseDate
              ? moment(row.responseDate).format('DD/MM/YYYY')
              : '-'}
          </Typography>
        );
      }
    },
    {
      label: 'Average rating',
      render: row => {
        return (
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{}} variant="body2" fontWeight={'600'}>
              {row.questionAvgRating
                ? parseFloat(row.questionAvgRating).toFixed(1)
                : '0.0'}
            </Typography>
            <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />

            <Rating
              size="small"
              precision={0.5}
              readOnly
              value={row.questionAvgRating}
            />
          </Box>
        );
      }
    },
    {
      label: 'Questions answered',
      render: row => {
        let tempResponsedata =
          row?.responses && isArrayNotEmpty(row.responses)
            ? [...row.responses]
            : [];

        return (
          <Typography variant="body2" fontWeight={'600'}>
            {tempResponsedata.length} / {formResponse?.totalQuestions}
          </Typography>
        );
      }
    }
  ];

  const questionWiseResponseColums = [
    {
      uinqueKey: 'questionId',
      id: 'questionText',
      width: '95%',
      label: 'Questions',
      render: (row, idx) => {
        let index = responseTableData.findIndex(
          cc => cc?.questionId === row.questionId
        );
        return (
          <Typography variant="body2" fontWeight={'600'}>
            <span
              style={{
                padding: '0.5rem 3px 0.5rem 0.0rem',
                display: 'inline-flex',
                alignItems: 'center'
              }}
            >
              {index + 1}{' '}
              <East
                fontSize="small"
                sx={{
                  marginLeft: '8px'
                }}
              />
            </span>{' '}
            {row.questionText}
          </Typography>
        );
      }
    },
    {
      label: 'Average rating',
      render: row => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography
              sx={{ flex: 1, textAlign: 'center' }}
              variant="body2"
              fontWeight={'600'}
            >
              {row.questionAvgRating
                ? parseFloat(row.questionAvgRating).toFixed(1)
                : '0.0'}
            </Typography>
            <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
            <Box sx={{ flex: 2 }}>
              <Rating
                size="small"
                precision={0.5}
                readOnly
                value={row.questionAvgRating}
              />
            </Box>
          </Box>
        );
      }
    }
  ];

  const rubricWiseResponseColums = [
    {
      uinqueKey: 'indicatorId',
      id: 'questionText',
      width: '70%',
      label: 'Indicator Name',
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.indicatorName}
          </Typography>
        );
      }
    },
    {
      label: '',
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {row.responseDate
              ? moment(row.responseDate).format('DD/MM/YYYY')
              : '-'}
          </Typography>
        );
      }
    },
    {
      label: 'Average rating',
      render: row => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography
              sx={{ flex: 1, textAlign: 'center' }}
              variant="body2"
              fontWeight={'600'}
            >
              {row.avgRating ? parseFloat(row.avgRating).toFixed(1) : '0.0'}
            </Typography>
            <Divider sx={{ mx: 1 }} orientation="vertical" flexItem />
            <Box sx={{ flex: 2 }}>
              <Rating
                size="small"
                precision={0.5}
                readOnly
                value={row.avgRating}
              />
            </Box>
          </Box>
        );
      }
    },
    {
      label: '',
      render: row => {
        const answersWithIndicator = formResponse
          ? Object.values(formResponse.questionWiseResponses)
              .flat()
              .filter(
                response =>
                  response.indicators &&
                  response.indicators.some(
                    indicator => indicator.indicatorId === row.indicatorId
                  )
              )
          : 0;
        // console.log(
        //   formResponse &&
        //     Object.values(formResponse.questionWiseResponses).flat(),
        //   'answersWithIndicator'
        // );
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Typography
              sx={{ flex: 1, textAlign: 'center' }}
              variant="body2"
              fontWeight={'600'}
            >
              {answersWithIndicator}
            </Typography>
          </Box>
        );
      }
    }
  ];

  const handleSelectionChange = selectedRows => {
    // console.log('first', selectedRows);
    setSelectedIds(selectedRows);
  };

  const downloadIndividualResponses = () => {
    if (selectedIds.length === 0) {
      setUserSelectionResError({
        error: true,
        errorMessage: `Please select at least One Individual User`
      });
      return;
    }
    setUserSelectionResError({
      error: false,
      errorMessage: ''
    });
    dispatch(
      downloadIndividualPDF({
        fileName: 'Individual_response',
        body: { formId: formId, flowId: flowId, ids: [...selectedIds] }
      })
    );
  };

  const downloadQuestionWiseResponses = () => {
    if (selectedIds.length === 0) {
      setUserSelectionResError({
        error: true,
        errorMessage: `Please select at least One Question`
      });
      return;
    }
    setUserSelectionResError({
      error: false,
      errorMessage: ''
    });
    dispatch(
      downloadQuestionWisePDF({
        fileName: 'Question_wise',
        body: { formId: formId, flowId: flowId, ids: [...selectedIds] }
      })
    );
  };

  const handleRowClick = (row, idx) => {
    if (selectedTab == 0) {
      let tempResponsedata =
        row?.responses && isArrayNotEmpty(row.responses)
          ? [...row.responses]
          : [];

      setResponseList({
        title: row.name,
        data: tempResponsedata,
        totalQuestionCount: formResponse?.totalQuestions,
        rating: row.questionAvgRating
      });
    }

    if (selectedTab == 1) {
      let tempResponsedata =
        formResponse?.questionWiseResponses[row.questionId] &&
        isArrayNotEmpty(formResponse?.questionWiseResponses[row.questionId])
          ? formResponse?.questionWiseResponses[row.questionId]
          : [];

      setResponseList({
        title: `${idx + 1}. ${row.questionText}`,
        data: tempResponsedata,
        totalQuestionCount: 0,
        rating: row.questionAvgRating
      });
    }

    if (selectedTab == 2) {
      setResponseList({
        title: row.indicatorName,
        data: row,
        totalQuestionCount: 0,
        rating: row.avgRating
      });
    }
  };

  return (
    <>
      {loading && (
        <Skeleton
          variant="rounded"
          width={'100%'}
          height={400}
          animation="wave"
        />
      )}
      {!loading && (
        <>
          {' '}
          <Grid
            mt={2}
            container
            display={'flex'}
            flexDirection={'column'}
            elevation={0}
            component={Paper}
            p={2}
          >
            {_.includes(['', null, undefined], responseList.title) && (
              <Box display={'flex'} justifyContent={'space-between'} mb={2}>
                <Box>
                  <Typography fontWeight={'700'} fontSize={'20px'}>
                    {totalResponse.responses} Responses
                  </Typography>
                  <Typography
                    fontWeight={'500'}
                    fontSize={'12px'}
                    color={colorLinkGrey}
                    display={'flex'}
                    mt={1}
                  >
                    <SvgIcon sx={{ mr: 0.5, height: 16, width: 16 }}>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_5511_26833)">
                          <path
                            d="M8.00004 5.33398V8.00065M8.00004 10.6673H8.00671M14.6667 8.00065C14.6667 11.6826 11.6819 14.6673 8.00004 14.6673C4.31814 14.6673 1.33337 11.6826 1.33337 8.00065C1.33337 4.31875 4.31814 1.33398 8.00004 1.33398C11.6819 1.33398 14.6667 4.31875 14.6667 8.00065Z"
                            stroke="#4E565F"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5511_26833">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </SvgIcon>
                    Responses cannot be edited
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                  {selectedTab != 2 && (
                    <Tooltip title="Print">
                      <Button
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                        disabled={isDownloadIndividualPDFLoading}
                        onClick={() => {
                          selectedTab === 0
                            ? downloadIndividualResponses()
                            : downloadQuestionWiseResponses();
                        }}
                        sx={{ ':hover': { backgroundColor: 'transparent' } }}
                      >
                        <CUSTOM_PRINT_ICON
                          color={isHover ? colorPrimary : colorTextBlack}
                        />
                      </Button>
                    </Tooltip>
                  )}

                  <FormControlLabel
                    sx={{
                      marginLeft: 0,
                      marginRight: 2,
                      color: colorLinkGrey,
                      '& .MuiFormControlLabel-label': {
                        color: colorLinkGrey,
                        fontSize: '12px',
                        lineHeight: '14px',
                        fontWeight: '500'
                      }
                    }}
                    control={<IOSSwitch />}
                    checked={accptResponse}
                    onChange={e => {
                      setIsLoading(true);
                      let payload = {
                        formId: formId,
                        isActive: !accptResponse,
                        loggedInUserName: authData.userName
                      };
                      dispatch(acceptResponse(payload));

                      // setAccptResponse(!accptResponse);
                    }}
                    label="Accepting Responses"
                    labelPlacement="start"
                  />
                </Box>
              </Box>
            )}
            <Tabs
              TabIndicatorProps={{
                sx: {
                  height: '4px',
                  borderRadius: '4px'
                }
              }}
              value={selectedTab}
              onChange={(val, newValue) => {
                setSelectedTab(newValue);
                setSelectedIds([]);
                setResponseList({
                  title: '',
                  data: [],
                  totalQuestionCount: 0
                });
                setFilterDate(null);
              }}
            >
              <Tab
                color={selectedTab === 0 ? colorPrimary : colorTextGrey}
                sx={{
                  fontSize: '16px',
                  minHeight: 44,
                  height: 44,
                  color: colorTextGrey,
                  fontWeight: selectedTab === 0 ? '700' : '600',
                  '&.Mui-selected': {
                    color: selectedTab === 0 ? colorTextBlack : colorTextGrey
                  }
                }}
                label="Individual Responses"
              />
              <Tab
                color={selectedTab === 1 ? colorPrimary : colorTextGrey}
                sx={{
                  fontSize: '16px',
                  minHeight: 44,
                  height: 44,
                  color: colorTextGrey,
                  fontWeight: selectedTab === 1 ? '700' : '600',
                  '&.Mui-selected': {
                    color: selectedTab === 1 ? colorTextBlack : colorTextGrey
                  }
                }}
                label="Question Wise Responses"
              />
              <Tab
                color={selectedTab === 2 ? colorPrimary : colorTextGrey}
                sx={{
                  fontSize: '16px',
                  minHeight: 44,
                  height: 44,
                  color: colorTextGrey,
                  fontWeight: selectedTab === 2 ? '700' : '600',
                  '&.Mui-selected': {
                    color: selectedTab === 2 ? colorTextBlack : colorTextGrey
                  }
                }}
                label="Rubric Wise Responses"
              />
            </Tabs>
            <Divider variant="fullWidth" orientation="horizontal" />
            <Box>
              {_.includes(['', null, undefined], responseList.title) && (
                <>
                  <Box display={'flex'}>
                    {selectedTab !== 2 && (
                      <Box width={{ lg: 330, xl: 330 }} marginY={2} mr={2}>
                        <InputLabel
                          sx={{
                            fontWeight: '700',
                            color: colorTextBlack,
                            mb: 0.2
                          }}
                        >
                          {` ${
                            (selectedTab === 0 && 'Search by user name') ||
                            (selectedTab === 1 && 'Select question') ||
                            (selectedTab === 2 && 'Search by indicator')
                          }`}
                        </InputLabel>
                        <Autocomplete
                          options={responseFilterOption}
                          getOptionLabel={option => option.label}
                          style={{ width: '100%' }}
                          size="small"
                          renderInput={params => {
                            return (
                              <TextField
                                size="small"
                                {...params}
                                variant="outlined"
                                placeholder={`  ${
                                  (selectedTab === 0 && 'search') ||
                                  (selectedTab === 1 && 'search') ||
                                  (selectedTab === 2 && 'indicator')
                                }`}
                                sx={{
                                  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall':
                                    {
                                      paddingLeft: '12px'
                                    }
                                }}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <>
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M14.5 14L12.1667 11.6667M13.8333 7.66667C13.8333 10.7963 11.2963 13.3333 8.16667 13.3333C5.03705 13.3333 2.5 10.7963 2.5 7.66667C2.5 4.53705 5.03705 2 8.16667 2C11.2963 2 13.8333 4.53705 13.8333 7.66667Z"
                                          stroke={colorTextGrey}
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>

                                      {params.InputProps.startAdornment}
                                    </>
                                  )
                                }}
                              />
                            );
                          }}
                          value={search}
                          onChange={(event, newValue) => {
                            let resp =
                              selectedTab === 0
                                ? [...formResponse.individualResponses]
                                : selectedTab === 1
                                  ? [...formResponse.questionList]
                                  : selectedTab === 2
                                    ? [...formResponse.rubricWiseResponse]
                                    : [];

                            if (newValue) {
                              setResponseTableData(
                                [...resp].filter(cc => {
                                  const tabConditionMatches =
                                    selectedTab === 0
                                      ? cc.userId === newValue.id
                                      : selectedTab === 1
                                        ? cc.questionId === newValue.id
                                        : selectedTab === 2
                                          ? cc.indicatorId === newValue.id
                                          : false;

                                  const indicatorsMatch =
                                    cc.indicators &&
                                    cc.indicators.some(
                                      indicator =>
                                        indicator.indicatorId === newValue.id
                                    );

                                  return tabConditionMatches || indicatorsMatch;
                                })
                              );

                              if (selectedTab === 2) {
                                const questionsWithIndicator =
                                  formResponse.questionList.filter(question =>
                                    question.indicators.some(
                                      indicator =>
                                        indicator.indicatorId === newValue.id
                                    )
                                  );
                                setQuestionCount(questionsWithIndicator.length);
                              } else {
                                setQuestionCount(0);
                              }
                            } else {
                              setResponseTableData([...resp]);
                            }

                            setSearch(newValue);
                          }}
                        />
                      </Box>
                    )}
                    {selectedTab === 0 && (
                      <Box width={{ lg: 330, xl: 330 }} marginY={2}>
                        <InputLabel
                          sx={{
                            fontWeight: '700',
                            color: colorTextBlack,
                            mb: 0.2
                          }}
                        >
                          Filter by date
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            disableFuture={dayjs(new Date())}
                            value={dayjs(filterDate)}
                            format="DD-MM-YYYY"
                            slotProps={{
                              textField: {
                                size: 'small',
                                error: false,
                                InputProps: {
                                  sx: { color: colorTextBlack }
                                }
                              }
                            }}
                            onChange={date => {
                              let tempIndRes = [];
                              if (
                                isArrayNotEmpty(
                                  formResponse.individualResponses
                                )
                              ) {
                                formResponse.individualResponses.map(cc => {
                                  return tempIndRes.push({
                                    ...cc,
                                    responseDate: isArrayNotEmpty(cc.responses)
                                      ? cc.responses[0]?.responseDate
                                      : null
                                  });
                                });

                                setResponseTableData(
                                  date
                                    ? tempIndRes.filter(cc => {
                                        return (
                                          cc.responseDate ===
                                          dayjs(date).format('YYYY-MM-DD')
                                        );
                                      })
                                    : tempIndRes
                                );
                              }
                              setFilterDate(date);
                            }}
                            renderInput={params => <TextField {...params} />}
                            components={{
                              OpenPickerIcon: props => (
                                <IconButton size="small" {...props}>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14 6.66683H2M10.6667 1.3335V4.00016M5.33333 1.3335V4.00016M5.2 14.6668H10.8C11.9201 14.6668 12.4802 14.6668 12.908 14.4488C13.2843 14.2571 13.5903 13.9511 13.782 13.5748C14 13.147 14 12.5869 14 11.4668V5.86683C14 4.74672 14 4.18667 13.782 3.75885C13.5903 3.38252 13.2843 3.07656 12.908 2.88482C12.4802 2.66683 11.9201 2.66683 10.8 2.66683H5.2C4.0799 2.66683 3.51984 2.66683 3.09202 2.88482C2.71569 3.07656 2.40973 3.38252 2.21799 3.75885C2 4.18667 2 4.74672 2 5.86683V11.4668C2 12.5869 2 13.147 2.21799 13.5748C2.40973 13.9511 2.71569 14.2571 3.09202 14.4488C3.51984 14.6668 4.0799 14.6668 5.2 14.6668Z"
                                      stroke={colorTextBlack}
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </IconButton>
                              )
                            }}
                          />
                        </LocalizationProvider>
                      </Box>
                    )}
                    {selectedTab === 2 && (
                      <Box width={{ lg: 330, xl: 330 }} marginY={2}>
                        <InputLabel
                          sx={{
                            fontWeight: '700',
                            color: colorTextBlack,
                            mb: 0.2
                          }}
                        >
                          Filter by date
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            disableFuture={dayjs(new Date())}
                            value={dayjs(filterDate)}
                            format="DD-MM-YYYY"
                            components={{
                              OpenPickerIcon: props => (
                                <IconButton size="small" {...props}>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M14 6.66683H2M10.6667 1.3335V4.00016M5.33333 1.3335V4.00016M5.2 14.6668H10.8C11.9201 14.6668 12.4802 14.6668 12.908 14.4488C13.2843 14.2571 13.5903 13.9511 13.782 13.5748C14 13.147 14 12.5869 14 11.4668V5.86683C14 4.74672 14 4.18667 13.782 3.75885C13.5903 3.38252 13.2843 3.07656 12.908 2.88482C12.4802 2.66683 11.9201 2.66683 10.8 2.66683H5.2C4.0799 2.66683 3.51984 2.66683 3.09202 2.88482C2.71569 3.07656 2.40973 3.38252 2.21799 3.75885C2 4.18667 2 4.74672 2 5.86683V11.4668C2 12.5869 2 13.147 2.21799 13.5748C2.40973 13.9511 2.71569 14.2571 3.09202 14.4488C3.51984 14.6668 4.0799 14.6668 5.2 14.6668Z"
                                      stroke={colorTextBlack}
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </IconButton>
                              )
                            }}
                            slotProps={{
                              textField: {
                                size: 'small',
                                error: false,
                                InputProps: {
                                  sx: { color: colorTextBlack }
                                }
                              }
                            }}
                            onChange={date => {
                              if (
                                isArrayNotEmpty(formResponse.rubricWiseResponse)
                              ) {
                                setResponseTableData(
                                  date
                                    ? formResponse.rubricWiseResponse.filter(
                                        cc => {
                                          return (
                                            cc.responseDate ===
                                            dayjs(date).format('YYYY-MM-DD')
                                          );
                                        }
                                      )
                                    : formResponse.rubricWiseResponse
                                );
                              }
                              setFilterDate(date);
                            }}
                            renderInput={params => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </Box>
                    )}
                  </Box>

                  {/* <CustomDataTable
                    allowSelection={true}
                    hideEmptyRows={true}
                    onSelectionChange={val => handleSelectionChange(val)}
                    columns={
                      selectedTab === 0
                        ? individualWiseResponseColums
                        : selectedTab === 1
                          ? questionWiseResponseColums
                          : selectedTab === 2
                            ? rubricWiseResponseColums
                            : individualWiseResponseColums
                    }
                    data={responseTableData.slice(
                      responseTablePagination.page *
                        responseTablePagination.size,
                      (responseTablePagination.page + 1) *
                        responseTablePagination.size
                    )}
                    defaultPage={responseTablePagination.page}
                    defaultRowsPerPage={responseTablePagination.size}
                    selectedRows={[...selectedIds]}
                    onPageChange={val => {
                      setResponseTablePagination({
                        ...responseTablePagination,
                        page: val
                      });
                    }}
                    onRowsPerPageChange={val => {
                      setResponseTablePagination({
                        ...responseTablePagination,
                        size: val,
                        page: 0
                      });
                    }}
                    individualAvgRating={formResponse?.individualAvgRating}
                    totalCount={responseTableData.length}
                    onRowClick={(row, idx) => handleRowClick(row, idx)}
                  /> */}
                  <CustomTable
                    columns={
                      selectedTab === 0
                        ? individualWiseResponseColums
                        : selectedTab === 1
                          ? questionWiseResponseColums
                          : selectedTab === 2
                            ? rubricWiseResponseColums
                            : individualWiseResponseColums
                    }
                    data={responseTableData.slice(
                      responseTablePagination.page *
                        responseTablePagination.size,
                      (responseTablePagination.page + 1) *
                        responseTablePagination.size
                    )}
                    allowSelection={true}
                    onSelectionChange={val => handleSelectionChange(val)}
                    selectedRows={[...selectedIds]}
                    defaultPage={responseTablePagination.page}
                    defaultRowsPerPage={responseTablePagination.size}
                    individualAvgRating={formResponse?.individualAvgRating}
                    totalCount={responseTableData.length}
                    onPageChange={val => {
                      setResponseTablePagination(prev => ({
                        ...prev,
                        page: val
                      }));
                    }}
                    onRowsPerPageChange={val =>
                      setResponseTablePagination(prev => ({
                        ...prev,
                        size: val,
                        page: 0
                      }))
                    }
                    onRowClick={(row, idx) => handleRowClick(row, idx)}
                    allowPagination={true}
                    hideEmptyRows={true}
                  />
                </>
              )}
              {!_.includes(['', null, undefined], responseList.title) && (
                <>
                  {selectedTab === 0 && (
                    <IndividualResponsesCard
                      reponse={responseList.data}
                      title={responseList.title}
                      count={responseList.totalQuestionCount}
                      rating={responseList.rating}
                      onClose={() => {
                        setResponseList({
                          title: '',
                          data: [],
                          totalQuestionCount: 0,
                          rating: 0
                        });
                      }}
                    />
                  )}
                  {selectedTab === 1 && (
                    <QuestionWiseResponseCard
                      reponse={responseList.data}
                      title={responseList.title}
                      count={responseList.totalQuestionCount}
                      rating={responseList.rating}
                      onClose={() => {
                        setResponseList({
                          title: '',
                          data: [],
                          totalQuestionCount: 0,
                          rating: 0
                        });
                      }}
                    />
                  )}{' '}
                  {selectedTab === 2 && (
                    <RubricWiseResponseCard
                      reponse={responseList.data}
                      title={responseList.title}
                      count={responseList.totalQuestionCount}
                      rating={responseList.rating}
                      formId={formId}
                      flowId={flowId}
                      onClose={() => {
                        setResponseList({
                          title: '',
                          data: [],
                          totalQuestionCount: 0,
                          rating: 0
                        });
                      }}
                    />
                  )}
                </>
              )}
            </Box>
          </Grid>
          {/* <Box>
            <Typography>Send reminder to pending user</Typography>
          </Box> */}
          {responseList.title === '' && (
            <Box display={'flex'} justifyContent={'space-between'} my={1}>
              <Typography
                variant="caption"
                display={'flex'}
                mt={1}
                alignItems={'center'}
                fontWeight={'500'}
                fontSize={'12px'}
                color={colorLinkGrey}
              >
                <SvgIcon sx={{ mr: 0.5, height: 16, width: 16 }}>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_5511_26833)">
                      <path
                        d="M8.00004 5.33398V8.00065M8.00004 10.6673H8.00671M14.6667 8.00065C14.6667 11.6826 11.6819 14.6673 8.00004 14.6673C4.31814 14.6673 1.33337 11.6826 1.33337 8.00065C1.33337 4.31875 4.31814 1.33398 8.00004 1.33398C11.6819 1.33398 14.6667 4.31875 14.6667 8.00065Z"
                        stroke="#4E565F"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_5511_26833">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </SvgIcon>
                Send reminder to pending users.
              </Typography>
            </Box>
          )}
          <Box
            mt={2}
            container
            display={'flex'}
            elevation={0}
            gap={2}
            component={Paper}
            p={2}
          >
            {!_.includes(['', null, undefined], responseList.title) && (
              <Button
                sx={{ marginRight: 2, width: '100px' }}
                variant="contained"
                onClick={() =>
                  setResponseList({
                    title: '',
                    data: [],
                    totalQuestionCount: 0
                  })
                }
              >
                Back
              </Button>
            )}
            <Button
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: colorTextBlack,
                borderWidth: '2px',
                height: '42px',
                width: '106px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
              onClick={() => navigate('/flows')}
              variant="outlined"
            >
              Cancel
            </Button>
            {_.includes(['', null, undefined], responseList.title) && (
              <Button
                sx={{ marginRight: 2, width: '155px', height: '42px' }}
                variant="contained"
                onClick={() => {
                  handleOpenReminderModal();
                }}
              >
                Send reminder
              </Button>
            )}
          </Box>
        </>
      )}

      {!isAcceptFormLoading && isAcceptFormSuccess && (
        <SnackBarBox
          type="success"
          message={acceptFormResponse || 'Action performed successfully'}
        />
      )}
      {openReminderModal && (
        <SendReminder
          open={openReminderModal}
          onClose={() => setOpenReminderModal(false)}
          formId={formId}
          flowId={flowId}
        />
      )}
      {userSelectionResError.error && (
        <SnackBarBox
          type="error"
          message={handleError(userSelectionResError.errorMessage)}
        />
      )}
    </>
  );
};

export default FormResponses;
