import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  Avatar,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  styled,
  SvgIcon,
  Typography,
  alpha
} from '@mui/material';
import momentTimeZone from 'moment-timezone';
import moment from 'moment';
import axios from 'axios';
import { loadAuth2, loadGapiInsideDOM } from 'gapi-script';
import dayjs from 'dayjs';
import {
  Close,
  KeyboardArrowDown,
  Logout,
  VideoCall
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  colorBorderGrey,
  colorDisableButton,
  colorDisableGrey,
  colorLinkGrey,
  colorPrimary,
  colorPureWhite,
  colorSecondary,
  colorTextBlack,
  colorTextBorder,
  colorTextGrey
} from '../../config/theme';
import { SCOPES, TOKEN_KEY, GOOGLE_ACCOUNT_INFO } from '../../utils/constant';
import SnackBarBox from '../../components/SnackBarBox';
import { useNavigate } from 'react-router-dom';
import { BootstrapDialog } from '../../utils/styledLayout';
import AddEditEvents from '../../containers/schedules/AddEditEvents';
import { convertUTCtoTimezone, createTitle } from '../../utils/utils';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  Copy05Icon,
  ListViewIcon,
  PlusIcon,
  ShareThreeDotsIcon,
  Text01Icon,
  UserGroupIcon,
  VideoCallIcon
} from '../../utils/iconSvg';
import CustomDataTable from '../../components/CustomDataTable';
import useDateFormatter from '../../hooks/useDateFormatter';
import { CustomTextLink } from '../../containers/login/LoginForm';
import AddEditNewEvents from '../../containers/schedules/AddEditNewEvents';
import { setActiveModule } from '../../redux/reducers/layoutSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const StyledMenu = styled(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '8px',
    marginTop: theme.spacing(1),
    minWidth: 150,
    boxShadow: '0px 4px 14px 0px #1F29331F',
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.primary[300],
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      },
      '&:hover': {
        backgroundColor: colorSecondary
      }
    }
  }
}));
// Constants for views and actions
const VIEW_TYPES = {
  MONTH: 'dayGridMonth',
  WEEK: 'timeGridWeek',
  DAY: 'timeGridDay'
};

// const VIEW_TYPES = {
//   MONTH: 'dayGridMonth',
//   WEEK: 'timeGridWeek',
//   DAY: 'timeGridDay'
// };

const ACTIONS = {
  TODAY: 'today',
  PREV: 'prev',
  NEXT: 'next'
};

const Schedules = () => {
  const calendarRef = useRef(null);
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const { formatDateZone, formatZoneDateAndTime, formatTimeZone } =
    useDateFormatter();
  const [events, setEvents] = useState([]);
  const [gapi, setGapi] = useState(null);
  const [lastSync, setLastSync] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState({ error: false, message: '' });
  const [showEventCreationModal, setShowEventCreationModal] = useState(null);
  const [showEventModal, setShowEventModal] = useState(null);
  const [showMoreEventsModal, setShowMoreEventsModal] = useState(null);
  const [showNewEventCreationModal, setShowNewEventCreationModal] =
    useState(null);
  const [refresh, setRefresh] = useState(false);
  const [view, setView] = useState(0);
  const [copySuccess, setCopySuccess] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedView, setSelectedView] = useState('Month');
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 10,
    totalEvents: 0
  });
  const [userProfile, setUserProfile] = useState({
    name: null,
    imageUrl: null
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { sidebarOpen } = useSelector(state => state.layout);
  const monthYear = `${currentDate.toLocaleString('default', { month: 'long' })} ${currentDate.getFullYear()}`;

  const calendarData = [
    { id: 0, title: 'Month' },
    { id: 1, title: 'Week' },
    { id: 2, title: 'Day' }
  ];
  const getCalendarApi = () => calendarRef.current.getApi();

  const handleViewChange = viewType => {
    const calendarApi = getCalendarApi();
    const view = VIEW_TYPES[viewType.toUpperCase()] || VIEW_TYPES.MONTH;
    calendarApi.changeView(view);
    setSelectedView(viewType);
  };

  const handleActionClick = action => {
    const calendarApi = getCalendarApi();
    const today = new Date();

    const updateDate = increment => {
      setCurrentDate(prevDate => {
        const newDate = new Date(prevDate);
        const currentView = calendarApi.view.type;

        if (currentView === VIEW_TYPES.MONTH) {
          newDate.setMonth(prevDate.getMonth() + increment);
        } else if (currentView === VIEW_TYPES.WEEK) {
          newDate.setDate(prevDate.getDate() + increment * 7);
        } else if (currentView === VIEW_TYPES.DAY) {
          newDate.setDate(prevDate.getDate() + increment);
        }

        return newDate;
      });
    };

    switch (action) {
      case ACTIONS.TODAY:
        setCurrentDate(today);
        calendarApi.today();
        break;
      case ACTIONS.PREV:
        calendarApi.prev();
        updateDate(-1);
        break;
      case ACTIONS.NEXT:
        calendarApi.next();
        updateDate(1);
        break;
      default:
        console.error('Invalid action:', action);
    }
  };

  useEffect(() => {
    document.title = createTitle('Schedules');

    let googleAccountInfo = JSON.parse(
      localStorage.getItem(GOOGLE_ACCOUNT_INFO)
    );

    if (googleAccountInfo) {
      setUserProfile({
        name: googleAccountInfo.name,
        imageUrl: googleAccountInfo.imageUrl
      });
    }
    const loadGapi = async () => {
      const newGapi = await loadGapiInsideDOM();
      setGapi(newGapi);
    };
    loadGapi();
    dispatch(setActiveModule('Schedules'));
  }, []);

  useEffect(() => {
    if (!gapi) return;

    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      fetchEvents();
    }

    const initializeAuth = async () => {
      await loadAuth2(
        gapi,
        process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID,
        SCOPES
      );
    };

    initializeAuth();
  }, [gapi]);

  useEffect(() => {
    let calendarApi = getCalendarApi();
    if (calendarApi) {
      const colHeader =
        calendarApi?.view?.calendar?.el.querySelector('.fc-col-header');
      const tableHeader =
        calendarApi?.view?.calendar?.el.querySelector('.fc-daygrid-body');
      const tableContent = calendarApi?.view?.calendar?.el.querySelector(
        '.fc-scrollgrid-sync-table'
      );

      if (colHeader) {
        colHeader.style.width = '100%';
      }
      if (tableHeader) {
        tableHeader.style.width = '100%';
      }
      if (tableContent) {
        tableContent.style.width = '100%';
      }
    }
  }, [sidebarOpen]);

  const attachSignin = async element => {
    const auth2 = await loadAuth2(
      gapi,
      process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID,
      SCOPES
    );

    // buttonRef.current.click();
    await auth2.attachClickHandler(
      element,
      { prompt: 'consent', access_type: 'offline' },
      googleUser => {
        const accessToken = googleUser.getAuthResponse().access_token;
        const expiresIn = googleUser.getAuthResponse().expires_in;

        if (accessToken) {
          const profile = googleUser.getBasicProfile();
          const name = profile.getName();
          const imageUrl = profile.getImageUrl();

          localStorage.setItem(
            TOKEN_KEY,
            googleUser.getAuthResponse().access_token
          );
          localStorage.setItem(
            GOOGLE_ACCOUNT_INFO,
            JSON.stringify({ name: name, imageUrl: imageUrl })
          );

          saveTokenData(accessToken, expiresIn);

          setLoading(true);
          fetchEvents();
          setUserProfile({
            name: name,
            imageUrl: imageUrl
          });
        } else {
          // setShowError({ error: true, message: 'Please sign-in to Google' });
          setLoading(false);
        }
      },
      error => {
        console.log(JSON.stringify(error));
      }
    );
  };

  const signOut = () => {
    if (gapi) {
      const auth2 = gapi.auth2.getAuthInstance();
      auth2.signOut().then(() => {
        setLastSync(null);
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(GOOGLE_ACCOUNT_INFO);
        localStorage.removeItem(`${TOKEN_KEY}_tokenExpiryTime`);
        setEvents([]);
        setLoading(false);
      });
    }
  };

  const handleTokenExpiration = async () => {
    const tokenExpiryTime = localStorage.getItem(
      `${TOKEN_KEY}_tokenExpiryTime`
    );
    const currentTime = new Date().getTime();

    if (currentTime > tokenExpiryTime) {
      // setShowError({
      //   error: true,
      //   message: 'Token is expired please re-login in google account'
      // });
      localStorage.removeItem('google_calendar_access_token_tokenExpiryTime');
      localStorage.removeItem(TOKEN_KEY);
    } else {
      const accessToken = localStorage.getItem(TOKEN_KEY);
      fetchEvents();
    }
  };

  const saveTokenData = (accessToken, expiresIn) => {
    const expiryTime = new Date().getTime() + expiresIn * 1000;
    localStorage.setItem(TOKEN_KEY, accessToken);
    localStorage.setItem(`${TOKEN_KEY}_tokenExpiryTime`, expiryTime);
  };

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      fetchEvents();
    } else {
      // setShowError({
      //   error: true,
      //   message: 'Please login to google account from share calendar. '
      // });
      setLoading(false);

      setTimeout(() => {
        setShowError({ error: false, message: '' });
      }, 3000);
    }

    return () => {};
  }, [refresh]);

  const fetchEvents = async (start, end) => {
    try {
      const timeMin = start
        ? moment(start).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
        : '2024-01-01T00:00:00Z';
      const timeMax = end
        ? moment(end).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
        : moment('9999-12-31T23:59:59Z').utc().format('YYYY-MM-DDTHH:mm:ss[Z]');

      // let minDate = '2024-01-01T00:00:00Z';
      // const primaryCalendarUrl = `https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=${minDate}&singleEvents=true&orderBy=startTime`;
      // const holidayCalendarId =
      //   'en.indian%23holiday%40group.v.calendar.google.com';
      // const holidayCalendarUrl = `https://www.googleapis.com/calendar/v3/calendars/${holidayCalendarId}/events?timeMin=${minDate}&singleEvents=true&orderBy=startTime`;
      const primaryCalendarUrl = `https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=${timeMin}&timeMax=${timeMax}&singleEvents=true&orderBy=startTime`;
      const holidayCalendarId =
        'en.indian%23holiday%40group.v.calendar.google.com';
      const holidayCalendarUrl = `https://www.googleapis.com/calendar/v3/calendars/${holidayCalendarId}/events?timeMin=${timeMin}&singleEvents=true&orderBy=startTime`;

      const headers = {
        Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`
      };

      const [primaryEventsRes, holidayEventsRes] = await Promise.all([
        axios.get(primaryCalendarUrl, { headers }),
        axios.get(holidayCalendarUrl, { headers }).catch(error => {
          console.error('Error fetching holiday events:', error.message);
          return { data: { items: [] } };
        })
      ]);

      const events = primaryEventsRes.data.items.map(event => ({
        ...event,
        id: event.id,
        title: event.summary,
        start: event.start.dateTime || event.start.date,
        end: event.end.dateTime || event.end.date,
        createdDate: event.created,
        isHoliday: false,
        createdBy: event.creator ? event.creator.self : 'N/A'
      }));

      const holidays = holidayEventsRes.data.items.map(event => ({
        ...event,
        id: event.id,
        title: event.summary,
        start: event.start.dateTime || event.start.date,
        end: event.end.dateTime || event.end.date,
        createdDate: event.created,
        createdBy: event.creator ? event.creator.self : 'N/A',
        isHoliday: true // Mark as holiday
      }));

      const allEvents = [...events, ...holidays].sort(
        (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
      );

      setEvents(allEvents);
      setPagination({
        ...pagination,
        totalEvents: allEvents.length
      });
      setLastSync(dayjs().format('DD/MM/YYYY HH:mm:ss'));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error.message);
      // setShowError({
      //   error: true,
      //   message: 'Please login to google account from share calendar. '
      // });
      setLoading(false);
    }
    // try {
    //   let minDate = '2024-06-01T00:00:00Z';
    //   await axios
    //     .get(
    //       `https://www.googleapis.com/calendar/v3/calendars/primary/events?timeMin=${minDate}&singleEvents=true&orderBy=startTime`,
    //       {
    //         headers: {
    //           Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`
    //         }
    //       }
    //     )
    //     .then(res => {
    //       if (res && res.data) {
    //         setEvents(
    //           res.data.items
    //             .map(event => ({
    //               ...event,
    //               id: event.id,
    //               title: event.summary,
    //               start: event.start.dateTime || event.start.date,
    //               end: event.end.dateTime || event.end.date,
    //               createdDate: event.created,
    //               createdBy: event.creator ? event.creator.self : 'N/A'
    //             }))
    //             .sort(
    //               (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
    //             )
    //         );
    //         setPagination({
    //           ...pagination,
    //           totalEvents: res.data.items?.length || 0
    //         });
    //         setLastSync(dayjs().format('DD/MM/YYYY HH:mm:ss'));
    //         setLoading(false);
    //       }
    //     });
    // } catch (error) {
    //   console.error('Error fetching events:', error.message);
    //   setLoading(false);
    // }
  };

  const handleShareClick = () => {
    if (showEventModal.meetLink) {
      navigator.clipboard.writeText(showEventModal.meetLink);
      setCopySuccess(true);
    }
  };

  useEffect(() => {
    setCopySuccess(false);
  }, [showEventModal]);

  const columns = [
    { uinqueKey: 'id', label: 'Title', id: 'title' },
    {
      label: 'Start Date',
      id: 'start',
      render: val =>
        momentTimeZone(val.start)
          .tz('Asia/Kolkata')
          .format('DD/MM/YYYY, hh:mm:ss a')
    },
    {
      label: 'End Date',
      id: 'end',
      render: val =>
        momentTimeZone(val.end)
          .tz('Asia/Kolkata')
          .format('DD/MM/YYYY, hh:mm:ss a')
    },
    {
      label: 'Created By',
      id: 'createdBy',
      render: val => (val ? 'Self' : 'Admin')
    },
    {
      label: 'Created Date',
      id: 'createdDate',
      render: (val, val12) => {
        return momentTimeZone(val.createdDate)
          .tz('Asia/Kolkata')
          .format('DD/MM/YYYY, hh:mm:ss a');
      }
    }
  ];

  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid display={'flex'} justifyContent={'space-between'} mb={1}>
        <Typography
          mb={1}
          sx={{
            fontSize: '32px',
            fontWeight: '700',
            display: 'flex'
          }}
        >
          Schedules
        </Typography>
        <Box>
          {!lastSync && (
            <LoadingButton
              variant="outlined"
              id="loginBtn"
              disabled={localStorage.getItem(TOKEN_KEY) && lastSync}
              sx={{
                width: '216px',
                boxShadow: 'none',
                fontWeight: '700',
                fontSize: '14px',
                color: colorTextBlack,
                height: '48px',
                borderColor: colorPrimary,
                borderRadius: '10px',
                borderWidth: '2px',
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
              onClick={async () => {
                if (buttonRef.current) {
                  await attachSignin(buttonRef.current);
                }
              }}
              ref={buttonRef}
            >
              <Icon sx={{ display: 'flex', mr: 1 }}>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                    fill="#FFC107"
                  />
                  <path
                    d="M3.15234 7.3455L6.43784 9.755C7.32684 7.554 9.47984 6 11.9993 6C13.5288 6 14.9203 6.577 15.9798 7.5195L18.8083 4.691C17.0223 3.0265 14.6333 2 11.9993 2C8.15834 2 4.82734 4.1685 3.15234 7.3455Z"
                    fill="#FF3D00"
                  />
                  <path
                    d="M12.0002 22.0003C14.5832 22.0003 16.9302 21.0118 18.7047 19.4043L15.6097 16.7853C14.5721 17.5749 13.3039 18.0017 12.0002 18.0003C9.39916 18.0003 7.19066 16.3418 6.35866 14.0273L3.09766 16.5398C4.75266 19.7783 8.11366 22.0003 12.0002 22.0003Z"
                    fill="#4CAF50"
                  />
                  <path
                    d="M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z"
                    fill="#1976D2"
                  />
                </svg>
              </Icon>{' '}
              Sign in with Google
            </LoadingButton>
          )}
          {lastSync && (
            <>
              <Box
                // onClick={() => signOut()}
                sx={{
                  height: '48px',
                  minWidth: '114px',
                  border: `1px solid ${colorTextGrey}`,
                  borderRadius: '8px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Button
                  disableRipple
                  disableTouchRipple
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="text"
                  disableElevation
                  sx={{
                    minWidth: '150px',
                    fontSize: '20px',
                    color: colorTextBlack
                  }}
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDown />}
                >
                  <Typography
                    fontSize={'14px'}
                    lineHeight={'18px'}
                    fontWeight={'700'}
                    color={colorTextBlack}
                    mr={1}
                  >
                    {userProfile.name?.split(' ')[0]}
                  </Typography>
                  <Avatar
                    src={userProfile?.imageUrl}
                    sx={{
                      height: '40px',
                      width: '40px',
                      borderRadius: '30px'
                    }}
                  />
                </Button>
                <StyledMenu
                  MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                    sx: {}
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => signOut()} disableRipple>
                    Sign out
                  </MenuItem>
                </StyledMenu>
              </Box>
              {/* <LoadingButton
                startIcon={<Logout />}
                variant="outlined"
                sx={{
                  px: 4,
                  ml: 2,
                  borderColor: colorPrimary,
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  color: 'black',
                  borderWidth: '2px',

                  '&:hover': {
                    borderColor: colorPrimary,
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    borderWidth: '2px'
                  }
                }}
                onClick={() => {
                  setLoading(true);
                  signOut();
                }}
              >
                Sign Out
              </LoadingButton> */}
            </>
          )}
        </Box>
      </Grid>
      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        padding={2}
        component={Paper}
        // style={calendarStyles}
      >
        <Grid item display={'flex'} justifyContent={'space-between'} mb={2}>
          {lastSync && (
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              sx={{ width: '100%' }}
            >
              {/* <Box>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setLoading(true);
                    fetchEvents();
                  }}
                  sx={{
                    px: 4,
                    borderColor: colorPrimary,
                    borderRadius: '10px',
                    color: 'black',
                    borderWidth: '2px',
                    width: 130,
                    '&:hover': {
                      borderColor: colorPrimary,
                      backgroundColor: 'transparent',
                      borderRadius: '10px',
                      borderWidth: '2px'
                    }
                  }}
                >
                  Refresh
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setShowEventCreationModal({ id: -999 });
                  }}
                  sx={{
                    px: 4,
                    ml: 2,
                    borderColor: colorPrimary,
                    borderRadius: '10px',
                    color: 'black',
                    borderWidth: '2px',
                    // width: 130,
                    '&:hover': {
                      borderColor: colorPrimary,
                      backgroundColor: 'transparent',
                      borderRadius: '10px',
                      borderWidth: '2px'
                    }
                  }}
                >
                  Create Event
                </Button>
              </Box> */}
              {/* <ButtonGroup
                variant="outlined"
                disableElevation
                aria-label="Basic button group"
              >
                <Button
                  variant={view == 0 ? 'contained' : 'outlined'}
                  sx={{
                    borderColor: colorPrimary,
                    color: 'black',
                    borderWidth: '2px',
                    '&:hover': {
                      borderColor: colorPrimary,
                      backgroundColor: view == 0 ? 'primary' : 'transparent',
                      borderWidth: '2px'
                    }
                  }}
                  onClick={() => setView(0)}
                  startIcon={<CalendarCheck />}
                >
                  Calendar view
                </Button>
                <Button
                  variant={view == 1 ? 'contained' : 'outlined'}
                  startIcon={<ListViewIcon />}
                  sx={{
                    borderColor: colorPrimary,
                    color: 'black',
                    borderWidth: '2px',
                    width: 195,
                    '&:hover': {
                      borderColor: colorPrimary,
                      backgroundColor: view == 1 ? 'primary' : 'transparent',
                      borderWidth: '2px'
                    }
                  }}
                  onClick={() => setView(1)}
                >
                  List view
                </Button>
              </ButtonGroup> */}
            </Box>
          )}
        </Grid>
        {loading && (
          <Skeleton
            variant="rounded"
            width={'100%'}
            height={400}
            animation="wave"
          />
        )}
        {!loading && (
          <>
            <Grid sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                sx={{
                  boxShadow: 'none',
                  fontWeight: '700',
                  fontSize: '14px'
                }}
                variant="contained"
                disabled={!lastSync}
                onClick={() => {
                  setShowEventCreationModal({ id: -999 });
                }}
              >
                <SvgIcon
                  fontSize="small"
                  sx={{ marginRight: 0.6, height: '16px', width: '16px' }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.49996 2.66669V13.3334M3.16663 8.00002H13.8333"
                      stroke={!lastSync ? colorTextGrey : colorTextBlack}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>
                Create
              </Button>

              <Grid
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                <IconButton
                  color="primary"
                  sx={{
                    height: '28px',
                    width: '28px',
                    borderRadius: '8px !important',
                    padding: '2px',

                    ':hover': {
                      backgroundColor: colorSecondary
                    }
                  }}
                  onClick={() => handleActionClick('prev')}
                >
                  <ChevronLeftIcon />
                </IconButton>

                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: '700'
                  }}
                >
                  {monthYear}
                </Typography>

                <IconButton
                  color="primary"
                  sx={{
                    height: '28px',
                    width: '28px',
                    borderRadius: '8px !important',
                    padding: '2px',
                    mr: 1,
                    ':hover': {
                      backgroundColor: colorSecondary
                    }
                  }}
                  onClick={() => handleActionClick('next')}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Grid>

              <Grid sx={{ display: 'flex', gap: '16px' }}>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: colorDisableGrey,
                    borderRadius: '10px',
                    color: colorTextBlack,
                    borderWidth: '2px',
                    // marginRight: 2,
                    width: '69px',
                    '&:hover': {
                      backgroundColor: colorSecondary,
                      borderColor: colorSecondary
                    }
                  }}
                  // size=""
                  onClick={() => handleActionClick('today')}
                >
                  Today
                </Button>

                <Grid alignSelf={'center'}>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      sx={{ width: '128px' }}
                      name="role"
                      value={selectedView}
                      onChange={e => {
                        const newValue = e.target.value;
                        handleViewChange(newValue);
                      }}
                      // IconComponent={() => <ChevronDownIcon />}
                    >
                      {calendarData?.map(row => {
                        return (
                          <MenuItem
                            key={row.id}
                            value={row.title}
                            sx={{
                              ':hover': {
                                backgroundColor: colorSecondary
                              }
                            }}
                          >
                            {row.title}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {view === 0 ? (
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView={VIEW_TYPES[selectedView.toUpperCase()]}
                headerToolbar={{
                  left: '',
                  center: '',
                  right: ''
                }}
                customButtons={{
                  customPrev: {
                    text: ''
                    // click: handlePrevClick
                  },
                  customNext: {
                    text: ''
                    // click: handleNextClick,
                  },
                  customToday: {
                    text: ''
                  }
                }}
                weekends={true}
                dayHeaderContent={(val, cal, tal) => {
                  return (
                    <Box padding={1}>
                      <Typography color={colorPrimary}>
                        {val.view.type === 'dayGridMonth'
                          ? val.text
                          : val.view.type === 'timeGridWeek'
                            ? moment(val.date).format('dddd DD')
                            : val.view.type === 'timeGridDay'
                              ? moment(val.date).format('dddd DD')
                              : ''}
                      </Typography>
                    </Box>
                  );
                }}
                allDaySlot={false}
                events={events}
                datesSet={arg => {
                  fetchEvents(new Date(arg.start), new Date(arg.end));
                }}
                eventContent={eventInfo => {
                  return (
                    <Grid
                      sx={{ display: 'flex', gap: 1, cursor: 'pointer' }}
                      onClick={() => {
                        setShowEventModal({
                          title: eventInfo.event?.title,
                          startTime: eventInfo.event?.start,
                          endTime: eventInfo.event?.end,
                          meetLink: eventInfo.event?.extendedProps?.hangoutLink,

                          guest:
                            eventInfo.event?.extendedProps?.attendees?.map(
                              guest => guest?.email
                            ) || []
                        });
                      }}
                    >
                      <Typography sx={{ fontSize: '12px', fontWeight: '600' }}>
                        {eventInfo?.timeText}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: '600'
                        }}
                      >
                        {eventInfo?.event?.title}
                      </Typography>
                    </Grid>
                  );
                }}
                dayCellContent={info => {
                  const formattedDate = moment(info.date).format('D');
                  const dayEvents = events.filter(event =>
                    moment(event.start).isSame(info.date, 'day')
                  );
                  const holidayEvents = dayEvents.filter(
                    event => event.isHoliday
                  );
                  const regularEvents = dayEvents.filter(
                    event => !event.isHoliday
                  );
                  const maxEventsToShow = 3;

                  const combinedEvents = [...holidayEvents, ...regularEvents];

                  if (selectedView === 'Month') {
                    return (
                      <Grid>
                        <Box sx={{ textAlign: 'right' }}>{formattedDate}</Box>
                        {/* {holidayEvents.map((event, index) => (
                        <Box
                          key={index}
                          sx={{
                            backgroundColor: colorSecondary,
                            padding: '2px 4px',
                            marginBottom: '2px',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            style={{
                              width: '8px',
                              height: '8px',
                              backgroundColor: colorPrimary,
                              borderRadius: '50%',
                              marginRight: '8px'
                            }}
                          />
                          <Typography
                            sx={{ fontSize: '12px', fontWeight: 600 }}
                          >
                            {event.title}
                          </Typography>
                        </Box>
                      ))} */}
                        {combinedEvents
                          .slice(0, maxEventsToShow)
                          .map((event, index) => (
                            <Box
                              key={index}
                              sx={{
                                padding: '2px 4px',
                                marginBottom: '2px',
                                borderRadius: '10px',
                                backgroundColor: `${event.isHoliday === true ? colorSecondary : 'inherit'}`,
                                cursor: `${event.isHoliday === true ? '' : 'pointer'}`,
                                display: 'flex',
                                alignItems: 'center',
                                '&:hover': {
                                  borderColor: colorPrimary,
                                  backgroundColor: colorSecondary,
                                  borderRadius: '10px',
                                  borderWidth: '2px'
                                }
                              }}
                              onClick={() => {
                                if (event.isHoliday !== true) {
                                  setShowEventModal({
                                    title: event.summary,
                                    startTime: event.start,
                                    endTime: event.end,
                                    meetLink: event.hangoutLink,
                                    guest:
                                      event.attendees?.map(
                                        people => people.email
                                      ) || []
                                  });
                                }
                              }}
                            >
                              <Box
                                style={{
                                  width: '8px',
                                  height: '8px',
                                  backgroundColor: colorPrimary,
                                  borderRadius: '50%',
                                  marginRight: '8px'
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: 600,
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '80%'
                                }}
                              >
                                {event.title}
                              </Typography>
                            </Box>
                          ))}
                        {combinedEvents.length > maxEventsToShow && (
                          <Box sx={{ marginLeft: '8px' }}>
                            <CustomTextLink
                              title={`${combinedEvents.length - maxEventsToShow} More`}
                              fontWeight="700"
                              fontSize="12px"
                              onClick={() => {
                                const showAllEvents = combinedEvents
                                  // .filter(cc => !cc.isHoliday)
                                  .map(eventsData => ({
                                    eventTitle: eventsData.summary,
                                    startTime: eventsData.start,
                                    endTime: eventsData.end,
                                    hangoutLink: eventsData.hangoutLink,
                                    attendees:
                                      eventsData.attendees?.map(
                                        people => people.email
                                      ) || [],
                                    isHoliday: eventsData.isHoliday
                                  }));

                                setShowMoreEventsModal({
                                  title: showAllEvents,
                                  date: info.date
                                });
                              }}
                            />
                          </Box>
                        )}
                      </Grid>
                    );
                  } else {
                    return <></>;
                  }
                }}
              />
            ) : (
              // </div>
              <CustomDataTable
                columns={columns}
                onRowClick={row => {
                  const eventsData = events.find(event => event.id === row.id);
                  setShowEventModal({
                    title: eventsData.summary,
                    startTime: formatDateZone(eventsData.start),
                    endTime: formatDateZone(eventsData.end),
                    meetLink: eventsData.hangoutLink,
                    guest:
                      eventsData.attendees?.map(people => people.email) || []
                  });
                }}
                data={[...events].slice(
                  pagination.page * pagination.rowsPerPage,
                  (pagination.page + 1) * pagination.rowsPerPage
                )}
                onPageChange={val => {
                  setPagination({ ...pagination, page: val });
                }}
                onRowsPerPageChange={val => {
                  setPagination({ ...pagination, rowsPerPage: val });
                }}
                defaultOrderBy={'createdDate'}
                defaultPage={pagination.page}
                defaultRowsPerPage={pagination.rowsPerPage}
                totalCount={pagination.totalEvents}
              />
            )}
          </>
        )}
      </Grid>

      {/* new modal for calender  */}
      <BootstrapDialog
        maxWidth="sm"
        fullWidth
        open={!!showEventModal}
        onClose={() => {
          setShowEventModal(null);
        }}
        PaperProps={{
          component: 'form'
        }}
      >
        <DialogTitle
          sx={{
            p: 0.5,
            m: 0.5,
            backgroundColor: colorPureWhite,
            textAlign: 'right'
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowEventModal(null);
            }}
            sx={{
              color: theme => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {showEventModal && (
            <>
              <Grid
                sx={{
                  paddingBottom: '7px',
                  color: colorPrimary,
                  display: 'flex',
                  gap: 3
                }}
              >
                <Grid
                  sx={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: colorPrimary,
                    borderRadius: '5px',
                    padding: '4px'
                  }}
                />

                <Grid
                  container
                  sx={{
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '26px',
                      fontWeight: '600',
                      lineHeight: 1,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '70%',
                      flexShrink: 0
                    }}
                  >
                    {showEventModal.title}
                  </Typography>

                  <Grid display={'flex'}>
                    <Typography
                      sx={{
                        fontSize: '16px',
                        color: colorLinkGrey,
                        fontWeight: '400'
                      }}
                      mr={2}
                    >
                      {momentTimeZone(showEventModal.startTime)
                        .tz('Asia/Kolkata')
                        .format('dddd, MMMM D')}
                    </Typography>
                    <Typography
                      sx={{
                        color: colorLinkGrey,
                        fontWeight: '400',
                        fontSize: '16px'
                      }}
                    >
                      {formatTimeZone(showEventModal.startTime) || ''}
                      {showEventModal.endTime
                        ? ` - ${formatTimeZone(showEventModal.endTime || '')}`
                        : ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Grid py={2} mb={1} ml={5}>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: colorDisableGrey,
                    borderRadius: '10px',
                    color: colorPrimary,
                    borderWidth: '2px',
                    marginRight: 2,
                    // width: '69px',
                    '&:hover': {
                      // backgroundColor: colorSecondary,
                      borderColor: colorDisableGrey
                    }
                  }}
                  startIcon={<ShareThreeDotsIcon color={colorPrimary} />}
                >
                  Invite via link
                </Button>
              </Grid> */}

              <Grid sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                <VideoCallIcon />

                <Grid
                  mt={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'
                  }}
                >
                  <Grid>
                    <Button
                      disabled={!showEventModal.meetLink}
                      onClick={() => {
                        window.open(showEventModal.meetLink, '_blank');
                      }}
                      variant={'contained'}
                      sx={{
                        color: colorTextBlack,
                        borderWidth: '2px',
                        borderColor: colorPrimary
                      }}
                    >
                      Join with Meeting Link
                    </Button>
                    <Typography fontSize={'14px'} sx={{ color: colorLinkGrey }}>
                      {showEventModal.meetLink?.split('https://')}
                    </Typography>
                  </Grid>
                  <Grid>
                    <IconButton
                      onClick={handleShareClick}
                      disabled={!showEventModal.meetLink}
                    >
                      <Copy05Icon
                        color={
                          !showEventModal.meetLink
                            ? colorBorderGrey
                            : colorLinkGrey
                        }
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                sx={{
                  display: 'flex',
                  gap: 3,
                  alignItems: 'center',
                  paddingTop: '1rem',
                  paddingBottom: '1rem'
                }}
              >
                <SvgIcon>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 15.75V14.25C17 12.8521 16.0439 11.6775 14.75 11.3445M12.125 2.46807C13.2244 2.91311 14 3.99098 14 5.25C14 6.50902 13.2244 7.58689 12.125 8.03193M13.25 15.75C13.25 14.3522 13.25 13.6533 13.0216 13.1019C12.7172 12.3669 12.1331 11.7828 11.3981 11.4784C10.8467 11.25 10.1478 11.25 8.75 11.25H6.5C5.10218 11.25 4.40326 11.25 3.85195 11.4784C3.11687 11.7828 2.53284 12.3669 2.22836 13.1019C2 13.6533 2 14.3522 2 15.75M10.625 5.25C10.625 6.90685 9.28185 8.25 7.625 8.25C5.96815 8.25 4.625 6.90685 4.625 5.25C4.625 3.59315 5.96815 2.25 7.625 2.25C9.28185 2.25 10.625 3.59315 10.625 5.25Z"
                      stroke={colorTextBlack}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>
                <Grid>
                  {showEventModal?.guest ? (
                    <>
                      <Typography fontWeight={'600'} mb={2}>
                        {showEventModal.guest.length} participants
                      </Typography>
                      <Grid
                        sx={{
                          borderRadius: '8px',
                          height: '100px',
                          width: '500px',
                          border: `1px solid  ${colorBorderGrey}`,
                          overflowY: 'scroll',
                          msOverflowStyle: 'none',
                          scrollbarWidth: 'none',
                          padding: '8px'
                        }}
                      >
                        {showEventModal.guest.map((guest, id) => (
                          <Typography key={id}>{guest}</Typography>
                        ))}
                      </Grid>
                    </>
                  ) : (
                    <Typography fontWeight={'600'}>0 participants</Typography>
                  )}
                </Grid>
              </Grid>

              {copySuccess && (
                <SnackBarBox
                  message="Clipboard copied! successfully"
                  type={'success'}
                />
              )}
            </>
          )}
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        maxWidth="sm"
        fullWidth
        open={showEventCreationModal}
        onClose={() => {
          setShowEventCreationModal(null);
        }}
        PaperProps={{
          component: 'form'
        }}
      >
        <DialogTitle
          sx={{
            m: 1,
            pt: 3,
            backgroundColor: 'white'
          }}
        >
          <Typography
            sx={{ fontSize: '20px', fontWeight: '700', textAlign: 'left' }}
          >
            New Event
          </Typography>
        </DialogTitle>

        <DialogContent>
          <AddEditNewEvents
            eventId={showEventCreationModal?.id}
            eventDetails={showEventCreationModal}
            handleRefresh={() => setRefresh(!refresh)}
            onCancelClick={() => setShowEventCreationModal(null)}
          />
        </DialogContent>
      </BootstrapDialog>

      <BootstrapDialog
        maxWidth="xs"
        fullWidth
        open={!!showMoreEventsModal}
        onClose={() => {
          setShowMoreEventsModal(null);
        }}
        PaperProps={{
          component: 'form'
        }}
      >
        <DialogTitle
          sx={{
            m: 0,

            backgroundColor: 'white',
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'space-between',
            paddingTop: '10px'
          }}
        >
          <Typography variant="p" fontSize="20px" fontWeight="700">
            {showMoreEventsModal &&
              formatZoneDateAndTime(showMoreEventsModal?.date)}
          </Typography>

          <IconButton
            aria-label="close"
            onClick={() => {
              setShowMoreEventsModal(null);
            }}
            sx={{
              color: theme => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {showMoreEventsModal && (
            <Grid
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
              color={colorPrimary}
            >
              {showMoreEventsModal.title
                ?.sort((a, b) => moment(a.startTime) - moment(b.startTime))
                .map(item => (
                  <>
                    <Grid
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '2px',
                        px: '5px',
                        backgroundColor: `${item.isHoliday === true ? colorSecondary : 'inherit'}`,
                        cursor: `${item.isHoliday === true ? '' : 'pointer'}`,
                        borderRadius: '4px'
                      }}
                      onClick={() => {
                        if (!item.isHoliday) {
                          setShowEventModal({
                            title: item.eventTitle,
                            startTime: formatDateZone(item.startTime),
                            endTime: formatDateZone(item.endTime),
                            meetLink: item.hangoutLink,
                            guest: item.attendees?.map(people => people) || []
                          });
                        }
                      }}
                    >
                      <Box
                        sx={{
                          width: '8px',
                          height: '8px',
                          backgroundColor: colorPrimary,
                          borderRadius: '50%',
                          marginRight: '8px'
                        }}
                      />
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          maxWidth: '80%'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '12px',
                            fontWeight: '600',
                            color: colorLinkGrey
                          }}
                        >
                          {formatTimeZone(item.startTime)}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            fontWeight: '600',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '70%',
                            '&:hover': {
                              color: !item.isHoliday && colorPrimary
                            }
                          }}
                        >
                          {item.eventTitle}
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                ))}
            </Grid>
          )}
        </DialogContent>
      </BootstrapDialog>

      {/* old modal for calender */}
      {/* <BootstrapDialog
        maxWidth="sm"
        fullWidth
        open={!!showEventModal}
        onClose={() => {
          setShowEventModal(null);
        }}
        PaperProps={{
          component: 'form'
        }}
      >
        <DialogTitle
          sx={{
            p: 0.5,
            m: 0.5,
            backgroundColor: 'white',
            textAlign: 'right'
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowEventModal(null);
            }}
            sx={{
              color: theme => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {showEventModal && (
            <>
              <Grid
                sx={{
                  display: 'flex',
                  gap: 3,
                  alignItems: 'center',
                  paddingBottom: '7px'
                }}
                color={colorPrimary}
              >
                <Text01Icon />
            
                  <Typography
                    fontWeight={'600'}
                    fontSize={'18px'}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '80%'
                    }}
                  >
                    {showEventModal.title}
                  </Typography>
            
              </Grid>

              <Divider />
              <Grid
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  paddingTop: '13px',
                  paddingBottom: '13px'
                }}
              >
                <ClockIcon />
                <Grid display={'flex'}>
                  <Typography fontWeight={'600'} mr={2}>
                    {momentTimeZone(showEventModal.startTime)
                      .tz('Asia/Kolkata')
                      .format('dddd, MMMM D')}
                  </Typography>
                  <Typography fontWeight={'600'}>
                    {formatTimeZone(showEventModal.startTime) || ''} -{' '}
                    {formatTimeZone(showEventModal.endTime) || ''}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />

              <Grid
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  paddingTop: '1rem',
                  paddingBottom: '1rem'
                }}
              >
                <SvgIcon>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 15.75V14.25C17 12.8521 16.0439 11.6775 14.75 11.3445M12.125 2.46807C13.2244 2.91311 14 3.99098 14 5.25C14 6.50902 13.2244 7.58689 12.125 8.03193M13.25 15.75C13.25 14.3522 13.25 13.6533 13.0216 13.1019C12.7172 12.3669 12.1331 11.7828 11.3981 11.4784C10.8467 11.25 10.1478 11.25 8.75 11.25H6.5C5.10218 11.25 4.40326 11.25 3.85195 11.4784C3.11687 11.7828 2.53284 12.3669 2.22836 13.1019C2 13.6533 2 14.3522 2 15.75M10.625 5.25C10.625 6.90685 9.28185 8.25 7.625 8.25C5.96815 8.25 4.625 6.90685 4.625 5.25C4.625 3.59315 5.96815 2.25 7.625 2.25C9.28185 2.25 10.625 3.59315 10.625 5.25Z"
                      stroke={colorTextBlack}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </SvgIcon>
                <Grid>
                  {showEventModal?.guest ? (
                    <>
                      <Typography fontWeight={'600'} mb={2}>
                        {showEventModal.guest.length} participants
                      </Typography>
                      <Grid
                        sx={{
                          borderRadius: '8px',
                          height: '100px',
                          width: '500px',
                          border: `1px solid  ${colorBorderGrey}`,
                          overflowY: 'scroll',
                          msOverflowStyle: 'none',
                          scrollbarWidth: 'none',
                          padding: '8px'
                        }}
                      >
                        {showEventModal.guest.map((guest, id) => (
                          <Typography key={id}>{guest}</Typography>
                        ))}
                      </Grid>
                    </>
                  ) : (
                    <Typography fontWeight={'600'}>0 participants</Typography>
                  )}
                </Grid>
              </Grid>
              <Divider />
              <Grid
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                  paddingTop: '1rem'
                }}
              >
                <VideoCallIcon />

                <Grid>
                  <Typography>
                    <Button
                      disabled={!showEventModal.meetLink}
                      onClick={() => {
                        window.open(showEventModal.meetLink, '_blank');
                      }}
                      variant={'outlined'}
                      sx={{
                        color: colorTextBlack,
                        borderWidth: '2px',
                        borderColor: colorPrimary
                      }}
                    >
                      Join Meeting Link
                    </Button>
                  </Typography>
                </Grid>
                <Grid>
                  <Button
                    onClick={handleShareClick}
                    disabled={!showEventModal.meetLink}
                  >
                    <Copy05Icon
                      sx={{
                        color: !showEventModal.meetLink
                          ? colorBorderGrey
                          : colorTextBlack
                      }}
                    />
                  </Button>
                </Grid>

                {copySuccess && (
                  <SnackBarBox message="Link copied!" type={'success'} />
                )}
              </Grid>
            </>
          )}
        </DialogContent>
      </BootstrapDialog> */}

      {/* 
      <BootstrapDialog
        maxWidth="sm"
        fullWidth
        open={showEventCreationModal}
        onClose={() => {
          setShowEventCreationModal(null);
        }}
        PaperProps={{
          component: 'form'
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography fontWeight={'600'}>Add new event</Typography>{' '}
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowEventCreationModal(null);
            }}
            sx={{
              color: theme => theme.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <AddEditEvents
            eventId={showEventCreationModal?.id}
            eventDetails={showEventCreationModal}
            handleRefresh={() => setRefresh(!refresh)}
            onCancelClick={() => setShowEventCreationModal(null)}
          />
        </DialogContent>
      </BootstrapDialog> */}

      {showError.error && (
        <SnackBarBox type="warning" message={showError.message} />
      )}
    </>
  );
};

export default Schedules;
