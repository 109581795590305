import {
  Avatar,
  Button,
  Grid,
  IconButton,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  SvgIcon,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import {
  createTitle,
  handleError,
  isArrayNotEmpty,
  mapArrayToTypography
} from '../../utils/utils';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setActiveModule } from '../../redux/reducers/layoutSlice';
import { isEmptyArray } from 'formik';
import _ from 'lodash';
import {
  activateDeactivateDistrict,
  deleteDistrict,
  downloadDistrictCSV,
  getDistrict,
  getDistrictCount,
  getDistrictDetails,
  revertDistrictDetails,
  uploadDistrictCSV
} from '../../redux/reducers/mastersSlice';
import SnackBarBox from '../../components/SnackBarBox';
import CustomDataTable from '../../components/CustomDataTable';
import SearchIcon from '@mui/icons-material/Search';
import {
  colorLightYellow,
  colorMidGray,
  colorPrimary,
  colorTextBlack,
  colorTextGrey
} from '../../config/theme';
import {
  Add,
  CheckCircleOutline,
  Download,
  MoreVert,
  NotInterested
} from '@mui/icons-material';
import ConfirmBox from '../../components/ConfirmBox';
import { LoadingButton } from '@mui/lab';
import SearchInput from '../../components/SearchInput';
import moment from 'moment';
import {
  DownloadIcon,
  EditIcon,
  TrashIcon,
  UploadIcon
} from '../../utils/iconSvg';
import CustomTabLabel from '../../custom/CustomTabLabel';

const Container = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'block'
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const DistrictPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fileInputRef = useRef(null);
  const { modules, isModulesSuccess } = useSelector(state => state.layout);
  const {
    isDistrictLoading,
    isDistrictSuccess,
    districtData,
    isDistrictFailed,
    districtErrorContainer,
    isActivateDeactivateLoading,
    isActivateDeactivateSuccess,
    isActivateDeactivateFailed,
    activateDeactivateErrorContainer,
    activateDeactivateResponse,
    isDeleteDistrictSuccess,
    isDeleteDistrictFailed,
    isDeleteDistrictLoading,
    deleteDistrictErrorContainer,
    isDownloadDistrictLoading,
    isUploadDistrictLoading,
    isUploadDistrictSuccess,
    isUploadDistrictFailed,
    uploadDistrictErrorContainer,
    uploadDistrictMessage
  } = useSelector(state => state.masters);
  const [initialValues, setInitalValues] = useState({
    tabValue: 0,
    page: 0,
    size: 10,
    searchFilter: ''
  });
  const { authData } = useSelector(state => state.auth);
  const [selectedDistrict, setSelectedDistricts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [confirmation, setConfimation] = useState(null);
  const [showError, setShowError] = useState(null);
  const [imageUploadError, setImageUploadError] = useState({
    error: false,
    errorMessage: ''
  });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    document.title = createTitle('District');
    dispatch(setActiveModule('Districts'));
    dispatch(getDistrictCount());
    return () => {
      dispatch(revertDistrictDetails());
    };
  }, []);

  useEffect(() => {
    dispatch(
      getDistrict({
        type:
          initialValues.tabValue === 1
            ? true
            : initialValues.tabValue === 2
              ? false
              : 'all',
        page: initialValues.page,
        size: initialValues.size,
        search: initialValues.searchFilter
      })
    );
  }, [initialValues]);

  const debouncedSearch = _.debounce(
    term => setInitalValues({ ...initialValues, searchFilter: term }),
    300
  );

  const handleImageUpload = event => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = 'text/csv';
      const maxSizeMB = 5;

      if (file.type === allowedTypes) {
        if (file.size / 1024 / 1024 <= maxSizeMB) {
          const uploadFormData = new FormData();

          uploadFormData.append('file', file);
          uploadFormData.append('loggedInUser', authData.userName);
          dispatch(uploadDistrictCSV(uploadFormData));
          fileInputRef.current.value = null;
        } else {
          setImageUploadError({
            error: true,
            errorMessage: `File size exceeds the maximum allowed (${maxSizeMB} MB).`
          });
        }
      } else {
        setImageUploadError({
          error: true,
          errorMessage: 'Please upload an csv file format.'
        });
      }
    }
  };

  const handleSearchChange = e => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.length >= 3 || term.length === 0) {
      debouncedSearch(term);
    }
  };
  const handleDistrictEditNavigation = districtId => {
    navigate('district-details/edit-district', {
      state: {
        ...location.state,
        districtId: districtId
      }
    });
  };

  const columns = [
    {
      uinqueKey: 'districtId',
      id: 'districtCode',
      label: 'District Code',
      sortable: true,
      width: '100px'
    },
    {
      id: 'districtName',
      label: 'District Name',
      sortable: true
    },
    {
      id: 'stateName',
      label: 'State ',
      sortable: true,
      width: '150px',
      render: row => {
        return (
          <Typography variant="body2" fontWeight={'600'}>
            {/* {row.stateCode ? `(${row.stateCode})` : ''}  */}
            {row.stateName}
          </Typography>
        );
      }
    },
    {
      id: 'areas',
      label: 'Area'
    },
    {
      id: 'schools',
      label: 'Schools'
    },
    {
      id: 'users',
      label: 'Users'
    },

    {
      id: 'status',
      label: 'Status',
      width: '150px',
      render: row => (
        <>
          <div
            style={{
              fontWeight: '600',
              fontSize: 12,
              backgroundColor: row.status ? '#EBF9D9' : '#FDF0E3',
              padding: '4px 8px 4px 8px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: row.status ? '#749E35' : '#D62828',
              width: row.status ? '62px' : '70px',
              height: '22px'
            }}
          >
            <Avatar
              sx={{
                width: 8,
                height: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                backgroundColor: row.status ? '#749E35' : '#D62828',
                color: 'white',
                fontSize: '12px',
                marginRight: '5px'
              }}
            >
              <></>
            </Avatar>
            {row.status ? 'Active' : 'Inactive'}
          </div>
        </>
      )
    },
    {
      id: 'createdBy',
      label: 'Created By'
    },
    {
      id: 'creationDate',
      label: 'Created On',
      width: '150px',
      render: row => {
        return (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight={'600'}>
                {row.creationDate
                  ? moment(row.creationDate).format('DD-MM-YYYY hh:mm A')
                  : 'NA'}
              </Typography>
            </div>
          </>
        );
      }
    },
    {
      id: 'edit',
      label: '',
      width: '50px',
      render: row => {
        return (
          <Tooltip title="Edit">
            <IconButton
              size="small"
              onClick={e => {
                handleDistrictEditNavigation(row.districtId);
                e.stopPropagation();
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        );
      }
    }
  ];

  const handleMenuActions = action => {
    setShowError(null);
    if (action === 'Delete') {
      handleDeleteDistrict();
    } else if (action === 'De-Activate') {
      handleDeActivateDistrict();
    } else if (action === 'Activate') {
      handleActivateDistrict();
    }
  };

  const handleDeleteDistrict = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(districtData?.district)) {
      if (isArrayNotEmpty(selectedDistrict)) {
        setConfimation(true);
      } else {
        errorMessage = 'Please select district[s] to delete.';
      }
    }
    setShowError(errorMessage);
  };

  const handleActivateDistrict = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(districtData.district)) {
      if (!isArrayNotEmpty(selectedDistrict)) {
        errorMessage = 'Please select district[s] to activate.';
      } else {
        const selectedUsersData = districtData.district.filter(user =>
          selectedDistrict.includes(user.districtId)
        );
        const allUsersActive = selectedUsersData.every(user => !user.status);
        if (!allUsersActive) {
          errorMessage = 'Please select only inactive district[s] to activate.';
        } else {
          let payload = {
            isActivate: true,
            ids: [...selectedDistrict],
            loggedInUserName: authData?.userName
          };
          dispatch(activateDeactivateDistrict(payload));
        }
      }
    }
    setShowError(errorMessage);
  };

  const handleDeActivateDistrict = () => {
    let errorMessage = '';
    if (isArrayNotEmpty(districtData.district)) {
      if (!isArrayNotEmpty(selectedDistrict)) {
        errorMessage = 'Please select district[s] to in-activate.';
      } else {
        const selectedUsersData = districtData.district.filter(user =>
          selectedDistrict.includes(user.districtId)
        );
        const allUsersActive = selectedUsersData.every(user => user.status);
        if (!allUsersActive) {
          errorMessage =
            'Please select only active district[s] to in-activate.';
        } else {
          let payload = {
            isActivate: false,
            ids: [...selectedDistrict],
            loggedInUserName: authData?.userName
          };

          dispatch(activateDeactivateDistrict(payload));
        }
      }
    }
    setShowError(errorMessage);
  };

  const deleteUser = () => {
    let deletePayload = {
      ids: [...selectedDistrict],
      loggedInUserName: authData?.userName
    };
    dispatch(deleteDistrict(deletePayload));
  };

  const handleReset = () => {
    setSelectedDistricts([]);
    setShowError(null);
    setConfimation(null);
    setInitalValues({ tabValue: 0, page: 0, size: 10, searchFilter: '' });
    setAnchorEl(null);
    dispatch(getDistrictCount());
  };

  useEffect(() => {
    if (isActivateDeactivateSuccess || isDeleteDistrictSuccess) {
      return handleReset();
    }
  }, [isActivateDeactivateSuccess, isDeleteDistrictSuccess]);

  const handleOnRowClick = row => {
    return navigate(`district-details`, {
      state: {
        districtId: row.districtId
      }
    });
  };

  return (
    <>
      <Container mb={1}>
        <Grid>
          <Typography fontSize={32} fontWeight="700" display={'flex'}>
            Districts{' '}
            {/* <Typography
              ml={1}
              fontSize={20}
              fontWeight="700"
              alignSelf={'center'}
            >
              ({districtData.totalCount})
            </Typography>{' '} */}
          </Typography>
        </Grid>

        <Grid>
          <Tooltip title="Upload district">
            <LoadingButton
              // startIcon={<UploadIcon />}
              loading={isUploadDistrictLoading}
              onClick={() => fileInputRef.current.click()}
              variant="outlined"
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: 'black',
                borderWidth: '2px',
                marginRight: 2,
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
            >
              <SvgIcon
                fontSize="12px"
                sx={{ marginRight: 0.6, height: '16px', width: '16px' }}
              >
                <UploadIcon />
              </SvgIcon>
              {/* Bulk upload */}
              <input
                ref={fileInputRef}
                key={'uploadProfile'}
                type="file"
                style={{ display: 'none' }}
                accept={'.csv'}
                onChange={handleImageUpload}
              />
            </LoadingButton>
          </Tooltip>

          <Tooltip title="Download district">
            {' '}
            <LoadingButton
              // startIcon={<DownloadIcon />}
              loading={isDownloadDistrictLoading}
              variant="outlined"
              sx={{
                borderColor: colorPrimary,
                borderRadius: '10px',
                color: 'black',
                borderWidth: '2px',
                marginRight: 2,
                '&:hover': {
                  borderColor: colorPrimary,
                  backgroundColor: 'transparent',
                  borderRadius: '10px',
                  borderWidth: '2px'
                }
              }}
              onClick={() => {
                dispatch(
                  downloadDistrictCSV({
                    fileName: 'Districts.csv',
                    body: {
                      ids: [...selectedDistrict],
                      paginationRequest: {
                        type:
                          initialValues.tabValue === 1
                            ? true
                            : initialValues.tabValue === 2
                              ? false
                              : 'all',
                        page: 0,
                        size: 0
                      }
                    }
                  })
                );
              }}
            >
              <SvgIcon
                fontSize="12px"
                sx={{ marginRight: 0.6, height: '16px', width: '16px' }}
              >
                <DownloadIcon />
              </SvgIcon>
              {/* Download district */}
            </LoadingButton>
          </Tooltip>

          <Button
            sx={{
              boxShadow: 'none',
              marginRight: 2
            }}
            variant="contained"
            onClick={() => {
              navigate('new-district');
            }}
          >
            <SvgIcon
              fontSize="small"
              sx={{ marginRight: 0.6, height: '16px', width: '16px' }}
            >
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.49996 2.66669V13.3334M3.16663 8.00002H13.8333"
                  stroke="#1F2933"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>{' '}
            Add new
          </Button>
        </Grid>
      </Container>
      <Grid
        container
        display={'flex'}
        flexDirection={'column'}
        elevation={0}
        component={Paper}
        borderRadius={'8px'}
        sx={{ paddingX: '14px', pt: 1 }}
      >
        <Container
          sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}
        >
          <Tabs
            value={initialValues.tabValue}
            onChange={(val, newValue) => {
              setInitalValues({
                ...initialValues,
                tabValue: newValue,
                page: 0
              });
              setSelectedDistricts([]);
            }}
            TabIndicatorProps={{
              sx: {
                height: '4px',
                borderRadius: '4px'
              }
            }}
          >
            <Tab
              sx={{
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontSize: '16px',
                fontWeight: initialValues.tabValue === 0 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 0
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="All District"
                  count={
                    districtData?.allCount < 10
                      ? `0${districtData?.allCount}`
                      : districtData?.allCount
                  }
                  selected={initialValues.tabValue === 0}
                />
              }
            />
            <Tab
              sx={{
                fontSize: '16px',
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 1 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 1
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="Active District"
                  count={
                    districtData?.activeCount < 10
                      ? `0${districtData?.activeCount}`
                      : districtData?.activeCount
                  }
                  selected={initialValues.tabValue === 1}
                />
              }
            />
            <Tab
              sx={{
                fontSize: '16px',
                minHeight: 44,
                height: 44,
                color: colorTextGrey,
                fontWeight: initialValues.tabValue === 2 ? '700' : '600',
                '&.Mui-selected': {
                  color:
                    initialValues.tabValue === 2
                      ? colorTextBlack
                      : colorTextGrey
                }
              }}
              iconPosition="start"
              label={
                <CustomTabLabel
                  title="InActive District"
                  count={
                    districtData?.inActiveCount < 10
                      ? `0${districtData?.inActiveCount}`
                      : districtData?.inActiveCount
                  }
                  selected={initialValues.tabValue === 2}
                />
              }
            />
          </Tabs>
          <div style={{ display: 'flex', margin: 'auto 10px' }}>
            <SearchInput
              onSearchChange={handleSearchChange}
              searchTerm={searchTerm}
            />
            <Tooltip title="More options">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                <MoreVert />
              </IconButton>
            </Tooltip>

            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => {
                setAnchorEl(null);
                setShowError(null);
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  width: '20ch',
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {['Activate', 'De-Activate', 'Delete'].map(option => (
                <MenuItem
                  key={option}
                  disabled={
                    (initialValues.tabValue === 1 && option === 'Activate') ||
                    (initialValues.tabValue === 2 && option === 'De-Activate')
                  }
                  onClick={() => {
                    handleMenuActions(option);
                  }}
                >
                  <ListItemIcon>
                    {option === 'Delete' ? (
                      <>
                        <SvgIcon sx={{ height: 20, width: 20 }}>
                          <TrashIcon color="red" />
                        </SvgIcon>
                      </>
                    ) : option === 'De-Activate' ? (
                      <SvgIcon
                        sx={{ height: 20, width: 20, color: 'orangered' }}
                      >
                        <NotInterested />
                      </SvgIcon>
                    ) : option === 'Activate' ? (
                      <SvgIcon sx={{ height: 20, width: 20, color: 'green' }}>
                        <CheckCircleOutline />
                      </SvgIcon>
                    ) : (
                      ''
                    )}
                  </ListItemIcon>
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </Container>
        <Grid sx={{ padding: '18px 0px' }} width={'100%'}>
          {(isDistrictLoading || isActivateDeactivateLoading) && (
            <Skeleton
              variant="rounded"
              width={'100%'}
              height={400}
              animation="wave"
            />
          )}
          {((!isDistrictLoading && isDistrictSuccess) ||
            (!isActivateDeactivateLoading && isActivateDeactivateSuccess)) && (
            <CustomDataTable
              columns={columns}
              allowSelection={true}
              data={
                districtData?.district?.length > 0 ? districtData?.district : []
              }
              onPageChange={val => {
                setInitalValues({ ...initialValues, page: val });
              }}
              onRowsPerPageChange={val => {
                setInitalValues({ ...initialValues, size: val });
              }}
              selectedRows={[...selectedDistrict]}
              onSelectionChange={val => {
                setSelectedDistricts(val);
              }}
              defaultOrderBy={'creationDate'}
              defaultPage={initialValues.page}
              defaultRowsPerPage={initialValues.size}
              totalCount={districtData?.totalCount}
              onRowClick={row => handleOnRowClick(row)}
              allowPagination={true}
            />
          )}
        </Grid>

        <ConfirmBox
          isOpen={confirmation}
          title="Delete District!"
          subtitle={`${selectedDistrict.length} district[s] are selected, Are you sure you want to delete. `}
          handleClose={() => setConfimation(null)}
          handleSubmit={() => {
            deleteUser();
          }}
        />

        {showError && <SnackBarBox type={'error'} message={showError} />}

        {!isDistrictLoading && isDistrictFailed && (
          <SnackBarBox
            type="error"
            message={handleError(districtErrorContainer?.errorMessage)}
          />
        )}

        {!isDeleteDistrictLoading && isDeleteDistrictSuccess && (
          <SnackBarBox
            type="success"
            message={'District[s] deleted successfully. '}
          />
        )}

        {!isDeleteDistrictLoading && isDeleteDistrictFailed && (
          <SnackBarBox
            type="error"
            message={handleError(deleteDistrictErrorContainer?.errorMessage)}
          />
        )}

        {!isActivateDeactivateLoading && isActivateDeactivateSuccess && (
          <SnackBarBox type="success" message={activateDeactivateResponse} />
        )}

        {!isActivateDeactivateLoading && isActivateDeactivateFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              activateDeactivateErrorContainer?.errorMessage
            )}
          />
        )}

        {imageUploadError.error && (
          <SnackBarBox
            type="error"
            message={handleError(imageUploadError.errorMessage)}
          />
        )}

        {!isUploadDistrictLoading && isUploadDistrictFailed && (
          <SnackBarBox
            type="error"
            // message={handleError(uploadDistrictErrorContainer?.errorMessage)}
            message={mapArrayToTypography(
              uploadDistrictErrorContainer?.errorMessage
                ? uploadDistrictErrorContainer?.errorMessage.split('\n')
                : ''
            )}
          />
        )}

        {!isUploadDistrictLoading && isUploadDistrictSuccess && (
          <SnackBarBox
            type="success"
            message={handleError(uploadDistrictMessage)}
          />
        )}
      </Grid>
    </>
  );
};

export default DistrictPage;
